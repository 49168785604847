import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  return (
    <div className="App max-w-6xl 2xl:max-w-7xl mx-auto font-[inter]">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">{t("About Us")}</span>
      </p>
      <div className="bg-white dark:bg-[#121212] border border-gray-200 dark:border-[#121212] max-w-5xl 2xl:max-w-6xl rounded-xl p-3 sm:px-5 sm:py-[30px] mx-auto space-y-4 sm:space-y-6 2xl:space-y-8 text-justify">
        <h1 className="text-base sm:text-2xl 2xl:text-3xl font-semibold text-center">
          Welcome to vip ware pro.com
        </h1>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <p>
            <span>
              The creator of this Privacy Policy ensures a steady commitment to
              your privacy with regard to the protection of your invaluable
              information that you may share across this Website. This Privacy
              Policy contains information about the Website.
            </span>
          </p>
          <p>
            <span>
              All the definitions and interpretations are pari passu as per the
              Terms of Use, as uploaded on the Platform.
            </span>
          </p>
          <p>
            <span>
              Krystofer Lebkowski, a _sole propreitor__ is the operator of an
              online platform whose main objective is to connecting vip ware s
              worldwide through dating, community forums, and friendships. Our
              platform offers localized services, articles, and videos to guide
              you in your faith and learning journey. Upgrade to premium for
              enhanced features like expanded chat messaging limits, access to
              exclusive content, and priority support. Join vip ware Connect
              today and be part of a vibrant community that celebrates vip ware
              culture and connections.
            </span>
          </p>
          <p>
            <span>
              To provide you with our uninterrupted use of the Website, we may
              collect and, in some circumstances, disclose information about you
              with your permission. To ensure better protection of your privacy,
              we provide this notice explaining our information collection and
              disclosure policies and the choices you make about the way your
              information is collected and used.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <p>
            <span className="text-lg 2xl:text-2xl font-semibold">Index:</span>
            <br />
            <span>
              1. Personal Information <br /> 2. Information We Collect <br /> 3.
              Our Use Of Your Information <br /> 4. How Information Is Collected
              <br /> 5. External Links on The Platform <br /> 6. Cookies <br />
              7. Google Analytics <br /> 8. Google AdSense <br /> 9. Your Rights
              <br /> 10. Compliances <br /> 11. Confidentiality <br /> 12. Other
              Information Collectors <br /> 13. Our Disclosure of Your
              Information <br /> 14. Accessing, Reviewing and Changing Your
              Profile <br /> 15. Security <br /> 16. Severability <br /> 17.
              Amendment <br /> 18. Consent Withdrawal, Data Download & Data
              Removal Requests <br /> 19. Contact Us
              <br />
            </span>
          </p>
          <p>
            <span>
              This Privacy Policy shall be in compliance with the General Data
              Protection Regulation (GDPR) in effect from May 25, 2018, and any
              and all provisions that may read to the contrary shall be deemed
              to be void and unenforceable as of that date. If you do not agree
              with the terms and conditions of our Privacy Policy, including in
              relation to the manner of collection or use of your information,
              please do not use or access the Site. If you have any questions or
              concerns regarding this Privacy Policy, you should contact our
              Customer Support Desk at “____________vip ware
              .probusiness@gmail.com _____________”.
            </span>
          </p>
        </div>
        <p className="text-sm 2xl:text-base">
          ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED
          TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADINGS USED HEREIN ARE
          ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE
          AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS
          PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS
          CONTAINED WITHIN IT IN ANY MANNER.
        </p>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">INFORMATION WE COLLECT</h1>
          <p>
            <span>
              We are committed to respecting your online privacy. We further
              recognize your need for appropriate protection and management of
              any personal information (hereinafter referred to as “Personal
              Information”) you share with us. We may collect the following
              information: <br />
            </span>
          </p>
          <p>
            <span>
              a. Personal data including but not limited to Name, Mobile Number,
              Address, Email ID, Sex, DOB, Profession; <br />
              b. Information is collected through permission derived by the
              Website for Location access, storage permissions, contact details,
              camera access etc.;
              <br />
              c. Tracking information such as, but not limited to, the IP
              address of your device and Device ID when connected to the
              Internet. This information may include the URL that you just came
              from (whether this URL is on the Website or not), which URL you
              next go to (whether this URL is on the Website or not), your
              computer or device browser information, and other information
              associated with your interaction with the Website;
              <br />
              d. Details of Website usage for analytics.
            </span>
          </p>
          <p>
            <span>
              This Privacy Policy also applies to data we collect from Users who
              are not registered as members of this Website, including, but not
              limited to, browsing behavior, etc. We also collect and store
              personal information provided by you from time to time on the
              Website. We only collect and use such information from you that we
              consider necessary for achieving a seamless, efficient, and safe
              experience, and customized to your needs including: <br />
            </span>
            <span>
              a. To provide you with a seamless and personalized experience;
              <br />
              b. To communicate with you about your subscription and other new
              features from time to time;
              <br />
              c. To allow you to receive quality customer care services and data
              collection;
              <br />
              d. To comply with Applicable Laws, rules, and regulations. <br />
            </span>
            <span>
              Where any Service requested by you involves a Third Party, such
              information as is reasonably necessary by the Company to carry out
              your Service request may be shared with such Third Party. We also
              do use your contact information to send you offers based on your
              interests and prior activity and also to view the Content
              preferred by you. The Company may also use contact information
              internally to direct its efforts for Service improvement but shall
              immediately delete all such information upon withdrawal of your
              consent for the same through the ‘unsubscribe’ button or through
              an email to be sent to vip ware .probusiness@gmail.com
            </span>
          </p>
          <p>
            To the extent possible, we provide you with the option of not
            divulging any specific information you wish for us not to collect,
            store or use. You may also choose not to use a particular Service or
            feature on the Website and opt out of any non-essential
            communications from the Website. <br /> Further, transacting over
            the internet has inherent risks, which you can only avoid following
            security practices yourself, such as not revealing
            account/login-related information to any other person and informing
            our customer care teams about any suspicious activity or where your
            account has/may have been compromised.
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">OUR USE OF YOUR INFORMATION</h1>
          <p>
            <span>
              The information you provide shall be used to provide and improve
              the Products for you and all Users. <br />
            </span>
            <span>
              a. For providing you with seamless order fulfillment. <br />
              b. To communicate regarding promotions and product updates.
              <br />
              c. to allow you to receive quality customer care services. <br />
              d. For maintaining an internal record. <br />
              e. To enhance the Products provided by the Company. <br />
              f. For maintaining records under the legal and statutory
              provisions. <br />
            </span>
          </p>
          <p>
            <span>
              For more details about the nature of such communications, please
              refer to our Terms of Use. Further, your data and Sensitive
              Personal Data may be collected and stored by us for internal
              records. We use your tracking information, such as IP addresses
              and or Device ID, to help identify you and gather broad
              demographic information and make further services available to
              you. We will not sell, license, or trade your personal
              information. We will not share your personal information with
              others unless they act under our instructions or we are required
              to do so as per the Applicable Law. Information collected via our
              server logs includes Users' IP addresses and the pages visited;
              this will be used to manage the web system and troubleshoot
              problems. We also use Third-Party analytics, tracking,
              optimization, and targeting tools to understand how Users engage
              with our Website so that we can improve it and cater personalized
              Content/ads according to their preferences.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">HOW INFORMATION IS COLLECTED</h1>
          <p>
            <span>
              Before or at the time of collecting personal information, we will
              identify the purposes for which information is being collected. If
              the same is not identified to you, you have the right to request
              the Company to elucidate the purpose of the collection of said
              personal information, pending the fulfilment of which you shall
              not be mandated to disclose any information whatsoever. We will
              collect and use your personal information solely to fulfil those
              purposes specified by us, within the scope of the consent of the
              individual concerned or as required by Applicable Law. We will
              only retain personal information as long as necessary for the
              fulfilment of those purposes. We will collect personal information
              by lawful and fair means and with the knowledge and consent of the
              individual concerned. Personal data should be relevant to the
              purposes for which it is to be used and, to the extent necessary
              for those purposes, should be accurate, complete, and up-to-date.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">COOKIES</h1>
          <p>
            <span>
              A cookie is a small file of letters and numbers that we store on
              your browser or the hard drive of your computer, if you agree. By
              continuing to browse the Platform, you agree to our use of
              cookies. Cookies contain information that is transferred to your
              computer’s hard drive. You can set your browser to refuse all or
              some browser cookies or to alert you when Website and Websites set
              or access cookies. If you disable or refuse cookies, please note
              that some parts of this Website may become inaccessible or not
              function properly. A list of the type of cookies we use is as
              follows: <br />
            </span>
            <span>
              1. Strictly necessary cookies: These are cookies that are required
              for the operation of our Website. They include, for example,
              cookies that enable you to log into secure areas of our Website,
              use a shopping cart or make use of e-billing services.
              <br />
              2. Analytical/performance cookies: They allow us to recognize and
              count the number of visitors and to see how visitors move around
              our Website when they are using it. This helps us to improve the
              way our Website work, for example, by ensuring that users are
              finding what they are looking for easily.
              <br />
              3. Functionality cookies: These are used to recognize you when you
              return to our Website. This enables us to personalize our Content
              for you, greet you by name, and remember your preferences (for
              example, your choice of language or region).
              <br />
              4. Targeting cookies: These cookies record your visit to our
              Website, the pages you have visited and the links you have
              followed. We will use this information to make our Website and the
              advertising displayed more relevant to your interests. We may also
              share this information with Third-Parties for this purpose.
              <br />
            </span>
            <span>
              Please note that Third Parties (including, for example,
              advertising networks and providers of external services like web
              traffic analysis services) may also use cookies, over which we
              have no control. These cookies are likely to be
              analytical/performance cookies or targeting cookies. You can block
              cookies by activating the setting on your browser that allows you
              to refuse the setting of all or some cookies. However, if you use
              your browser settings to block all cookies (including essential
              cookies), you may not be able to access all or parts of our
              Website.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">GOOGLE ANALYTICS</h1>
          <p>
            <span>
              We use Google Analytics to help us to understand how you make use
              of our Content and work out how we can make things better. These
              cookies follow your progress through us, collecting anonymous data
              on where you have come from, which pages you visit, and how long
              you spend on the site. Google then stores this data to create
              reports. These cookies do not store your personal data. The
              information generated by the cookie about your use of the Website,
              including your IP address, may be transmitted to and stored by
              Google on servers in the United States. Google may use this
              information for the purpose of evaluating your use of the Website,
              compiling reports on Website activity for us and providing other
              services relating to Website activity and internet usage. Google
              may also transfer this information to Third Parties where required
              to do so by law or where such Third Parties process the
              information on Google's behalf. Google will not associate your IP
              address with any other data held by Google. By using this Website,
              you consent to the processing of data about you by Google in the
              manner and for the purposes set out above. The Google Website
              contains further information about Analytics and a copy of
              Google's privacy policy pages.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">GOOGLE ADSENSE</h1>
          <p>
            <span>
              Google AdSense is a tool that allows Website & Website publishers
              to deliver advertisements to site visitors in exchange for revenue
              calculated on a per-click or per-impression basis. To do this,
              Google uses cookies and tracking technology to deliver ads
              personalized to a Website User/visitor. In this regard, the
              following terms are specified to the Users:
              <br />
            </span>
            <span>
              a. Third-Party vendors, including Google, use cookies to serve ads
              based on your prior visits to our Website or other website.
              <br />
              b. Google's use of advertising cookies enables our partners and us
              to serve advertisements to you based on their visit to our
              Platform and/or other website and Websites on the Internet.
              <br />
              c. You may opt-out of personalized advertising by visiting Ads
              Settings.
              <br />
              d. All advertisements of third-Parties on our Website are for
              informative purposes only and neither the Website nor the Company
              guarantees or bears liability for the authenticity of the
              advertisements.
              <br />
              e. At no point will the Company permit its competitors to
              advertise on the Website.
              <br />
              f. You may visit the links in the advertisements at your own risk
              or choose not to accept the cookies permitting third parties to
              display their advertisements.
              <br />
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">YOUR RIGHTS</h1>
          <p>
            <span>
              Unless subject to an exemption, you have the following rights
              concerning your data:
              <br />
            </span>
            <span>
              a. the right to request a copy of the data which we hold about
              you; <br />
              b. the right to request any correction to any personal data if it
              is found to be inaccurate or out of date; <br />
              c. the right to withdraw your consent to the processing at any
              time; <br />
              d. the right to object to the processing of Personal Information;{" "}
              <br />
              e. the right to complain about a supervisory authority; <br />
              f. the right to obtain information on whether Personal Information
              are transferred to a third country or an international
              organization.
              <br />
            </span>
            <span>
              Where you hold an account with any of our Services, you are
              entitled to a copy of all Personal Information which we hold
              concerning you. You are also entitled to request that we restrict
              how we use the data in your account when you log in.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">COMPLIANCES</h1>
          <p>
            <span>
              a. GDPR Privacy Rights <br />
              The General Data Protection Regulation or GDPR gives certain
              rights to individuals in relation to their personal data.
              Accordingly, we are happy to offer transparency and access
              controls to help Users take advantage of those rights. As
              available, except as limited under applicable law, the rights
              afforded to individuals are:
              <br />
            </span>
            <span>
              i. Right of access- the right to be informed of, and request
              access to, the data we process about you;
              <br />
              ii. Right to rectification – the right to request that we amend or
              update your data where it is inaccurate or incomplete;
              <br />
              iii. Right to erasure- the right to request that we delete your
              data;
              <br />
              iv. Right to restrict – the right to request that we temporarily
              or permanently stop processing all or some of your Personal
              Information;
              <br />
              v. Right to object – the right to object to us processing your
              Personal Information on grounds relating to your particular
              situation. Along with the right to object to your Personal
              Information being processed for direct marketing purposes; <br />
              vi. Right to data portability – the right to request a copy of
              your Personal Information in electronic format and the right to
              transmit that Personal Information for use in another part’s
              Service; <br />
              vii. Right not to be subject to automated decision-making, the
              right to not be subject to a decision based solely on automated
              decision-making, including profiling, where the decision would
              have a legal effect on you or produce a similarly significant
              effect.
              <br />
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">CONFIDENTIALITY</h1>
          <p>
            <span>
              You further acknowledge that the Website may contain information
              that is designated confidential by us and that you shall not
              disclose such information without our prior written consent. Your
              information is regarded as confidential and, therefore, will not
              be divulged to any Third-Party unless, if legally, required to do
              so to the appropriate authorities. We will not sell, share, or
              rent your Personal Information to any Third-Party or use your
              e-mail address for unsolicited mail. Any emails sent by us will
              only be in connection with the provision of agreed Services, and
              you retain sole discretion to seek for discontinuation of such
              communications at any point in time.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">OTHER INFORMATION COLLECTORS</h1>
          <p>
            <span>
              Except as otherwise expressly included in this Privacy Policy,
              this document only addresses the use and disclosure of information
              we collect from you. To the extent that you disclose your
              information to other parties, whether they are on our Website or
              other sites throughout the Internet, different rules may apply to
              their use or disclosure of the information you disclose to them.
              To the extent that we use Third-Party advertisers, they adhere to
              their privacy policies. Since we do not control the privacy
              policies of Third-Parties, you are subject to ask questions before
              you disclose your personal information to others.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">
            OUR DISCLOSURE OF YOUR INFORMATION
          </h1>
          <p>
            <span>
              We may host surveys for survey creators for our Website who are
              the owners and Users of your survey responses. We do not own or
              sell your responses. Anything you expressly disclose in your
              responses will be disclosed to survey creators. Please contact the
              survey creator directly to understand better how they might share
              your survey responses. Information collected will not be
              considered as sensitive if it is freely available and accessible
              in the public domain or is furnished under the Right to
              Information Act, 2005, any rules made thereunder or any other law
              for the time being in force. Due to the existing regulatory
              environment, we cannot ensure that all of your private
              communications and other personally identifiable information will
              never be disclosed in ways not otherwise described in this Privacy
              Policy. By example (without limiting and foregoing), we may be
              forced to disclose information to the government, law enforcement
              agencies, or Third-Parties. Therefore, although we use
              industry-standard practices to protect your privacy, we do not
              promise, and you should not expect, that your personally
              identifiable information or private communications will always
              remain private. We do, however, assure you that any disclosure of
              your personally identifiable information shall be personally
              intimated to you through an email sent to your provided email
              address. As a matter of policy, we do not sell or rent any
              personally identifiable information about you to any Third-Party.
              However, the following describes some of the ways that your
              personally identifiable information may be disclosed: <br />
            </span>
            <span>
              b. External Service Providers: There may be several services
              offered by external service providers that help you use our
              Website. If you choose to use these optional services and in the
              course of doing so, disclose information to the external service
              providers and/or permit them to collect information about you,
              then their use of your information is governed by their privacy
              policy. <br /> c. Law and Order: We cooperate with law enforcement
              inquiries, as well as other third parties, to enforce laws, such
              as intellectual property rights, fraud and other rights. We can
              (and you authorize us to) disclose any information about you to
              law enforcement and other government officials as we, in our sole
              discretion, believe necessary or appropriate in connection with an
              investigation of fraud, intellectual property infringements, or
              other activity that is illegal or may expose you or us to legal
              liability.{" "}
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">
            ACCESSING, REVIEWING, AND CHANGING YOUR PROFILE
          </h1>
          <p>
            <span>
              You can review and change the information you submitted except the
              Email ID. An option for facilitating such change shall be present
              on the Website, User shall facilitate such change. If you change
              any information, we may or may not keep track of your old
              information. We will not retain in our files information you have
              requested to remove for certain circumstances, such as to resolve
              disputes, troubleshoot problems, and enforce our terms and
              conditions. Such prior information shall be removed entirely from
              our databases, including stored ‘backup’ systems. If you believe
              that any information we are holding on to you is incorrect or
              incomplete or to remove your profile so that others cannot view
              it, the User needs to remediate and promptly correct any such
              incorrect information.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">SECURITY</h1>
          <p>
            <span>
              We treat data as an asset that must be protected against loss and
              unauthorized access. We employ many different security techniques
              to protect such data from unauthorized access by members inside
              and outside the Company. We follow generally accepted industry
              standards to protect the Personal Information submitted to us and
              the information that we have accessed. However, as effective as
              encryption technology is, no security system is impenetrable. Our
              Company cannot guarantee the security of our database, nor can we
              guarantee that the information you provide won’t be intercepted
              while being transmitted to the Company over the Internet.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">SEVERABILITY</h1>
          <p>
            <span>
              Each paragraph of this Privacy Policy shall be and remain separate
              from and independent of and severable from all and any other
              paragraphs herein except where otherwise expressly indicated or
              indicated by the context of the agreement. The decision or
              declaration that one or more of the paragraphs are null and void
              shall not affect the remaining paragraphs of this Privacy Policy.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">AMENDMENT</h1>
          <p>
            <span>
              Our Privacy Policy may change from time to time. The most current
              version of the policy will govern our use of your information and
              will always be on the Website. Any amendments to this Policy shall
              be deemed as accepted by the User on their continued use of the
              Website.
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">
            CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS
          </h1>
          <p>
            <span>
              To withdraw your consent, or to request the download or delete
              your data with us for any or all our services at any time, please
              email to “ vip ware .probusiness@gmail.com
            </span>
          </p>
        </div>
        <div className="space-y-2 text-sm sm:text-base 2xl:text-xl">
          <h1 className="text-lg 2xl:text-2xl">CONTACT US</h1>
          <p>
            <span>
              If you have any questions or concerns regarding this privacy
              policy, you should contact us by sending an e-mail to “vip ware
              .probusiness@gmail.com ” or by calling us at _________ . <br />{" "}
              Krystofer Lebkowski ______________
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
