import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

const SearchedItems = ({ searchQuery }) => {
  const navigate = useNavigate();
  const coordinates = JSON.parse(localStorage.getItem("coordinates"));
  // const lat = coordinates?.lat || 48.7758459;
  // const lng = coordinates?.lng || 9.1829321;
  const searchQueryURL = `${process.env.REACT_APP_ENDPOINT}/feed/search/${searchQuery}?lat=${coordinates?.lat}&lng=${coordinates?.lng}`;
  const { data: searchedItems } = useQuery(
    [`searchedItems`, searchQuery],
    () =>
      axios.get(searchQueryURL, searchQuery).then((data) => {
        return data?.data;
      }),
    { enabled: searchQuery ? true : false }
  );
  return (
    <div className="bg-[#F6F7FA] dark:bg-black sm:w-[350px] 2xl:w-[500px] shadow-xl dark:shadow-lg dark:shadow-zinc-700">
      {searchedItems?.length > 0 ? (
        <div
          id="searched-item-div"
          className="flex flex-col gap-y-2 overflow-y-scroll h-56 sm:h-96 p-1"
        >
          {searchedItems?.map((item, index) => (
            <div
              onClick={() => {
                localStorage.getItem("user-id")
                  ? navigate(`/item/${item?._id}`)
                  : item?.planType === "free"
                  ? navigate(`/item/${item?._id}`)
                  : navigate(`/login`);
              }}
              key={index}
              className="flex items-center gap-2 bg-white dark:bg-[#1D1D1D] p-1 rounded cursor-pointer"
            >
              <img
                src={item?.imageGallery?.[0]}
                alt="items"
                className="h-10 w-10 sm:h-12 sm:w-12 object-cover rounded-lg"
              />
              <div className="text-[8px] xs:text-sm font-[poppins] text-black dark:text-white">
                <p className="font-semibold text-xs xs:text-sm">
                  {item?.itemName.length >= 25
                    ? item?.itemName.slice(0, 25) + "..."
                    : item?.itemName}
                </p>
                <p className="font-[inter] font-medium flex items-center gap-[2px] xs:gap-2">
                  <span className="text-[10px] xs:text-xs">
                    €{item?.discPrice}
                  </span>
                  <span className="text-[#9D9D9D] text-[6px] xs:text-[10px] line-through">
                    €{item?.price}
                  </span>
                </p>
              </div>
              {/* <div className="font-[poppins]">
                <p>
                  {item?.itemName.length >= 25
                    ? item?.itemName.slice(0, 25) + "..."
                    : item?.itemName}
                </p>
                <p className="font-[inter] font-medium flex items-center gap-[2px] xs:gap-2">
                  <span className="text-[8px] xs:text-xs">
                    ₹{item?.discPrice}
                  </span>
                  <span className="text-[#9D9D9D] text-[6px] xs:text-[11px] line-through">
                    ₹{item?.price}
                  </span>
                </p>
              </div> */}
              {/* <p>
                {item?.itemName.length >= 20 ? (
                  <>
                    <span>{item?.itemName.slice(0, 20)}</span>
                    <small className="text-[10px]">see more..</small>
                  </>
                ) : (
                  <span>item?.itemName</span>
                )}
              </p> */}
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full h-48 sm:h-52 flex justify-center items-center">
          <p className="text-center font-[inter] xs:text-lg w-full text-black dark:text-white">
            No result found for {searchQuery}
          </p>
        </div>
      )}
    </div>
  );
};

export default SearchedItems;
