import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import auth from "../../firebase.init";
import { RiSearchLine, RiArrowDropDownLine } from "react-icons/ri";
import { CgBell, CgProfile } from "react-icons/cg";
import { BiChat, BiPlus } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import ReactGA from "react-ga4";

import {
  MdOutlineLogin,
  MdOutlineFavoriteBorder,
  MdOutlineHelpOutline,
  MdOutlineLocationOn,
  MdOutlineBookmarkBorder,
} from "react-icons/md";

import Logo from "../../Assets/img/logo.png";
// import LocationModal from "../Modals/LocationModal";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SearchedItems from "../Items/SearchedItems";
import { AppContext } from "../../App";

const Navbar = () => {
  // const [user] = useAuthState(auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("user-id");
  const userImage = localStorage.getItem("user-image");
  const pathname = useLocation().pathname;
  // const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState(false);

  const {
    toggleTheme,
    openMenu,
    setOpenMenu,
    searchQuery,
    setSearchQuery,
    location,
    showLocationModal,
    setShowLocationModal,
  } = useContext(AppContext);

  showLocationModal
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const logout = () => {
    signOut(auth);
    localStorage.removeItem("token");
    localStorage.removeItem("user-image");
    localStorage.removeItem("user-id");
    localStorage.removeItem("user-planType");
    navigate("/");
    window.location.reload();
  };

  //get chatlist
  const chatListUrl = `${process.env.REACT_APP_ENDPOINT}/chats/${userId}/user`;
  const { data: chatList } = useQuery(
    [`chatList`, chatListUrl],
    () => axios.get(chatListUrl).then((data) => data.data),
    { enabled: userId ? true : false }
  );

  //get notifications
  const notificationListUrl = `${process.env.REACT_APP_ENDPOINT}/notification/${userId}`;
  const { data: notifications, refetch } = useQuery(
    [`notifications`, notificationListUrl],
    () =>
      axios.get(notificationListUrl).then((data) => {
        data?.data?.forEach((notification) => {
          if (notification.read === false) {
            setUnread(true);
            return;
          }
        });
        return data?.data;
      }),
    { enabled: userId ? true : false, retry: false }
  );

  //handle mark as read
  const handleMarkAsRead = (notificationId) => {
    const notificationListUrl = `${process.env.REACT_APP_ENDPOINT}/notification/${notificationId}`;
    axios.put(notificationListUrl).then((data) => {
      refetch();
    });
    ReactGA.event({
      category: "Notification",
      action: "Click",
      label: `Notification Id: ${notificationId} is clicked`,
    });
  };
  return (
    <div className="sticky top-0 z-20 text-white">
      <div className="navbar bg-secondary flex flex-col px-2 lg:pl-[100px] lg:pr-[40px] h-full lg:h-24">
        <div className="flex justify-between gap-3 w-full h-full">
          <div className="flex items-center gap-3">
            <div
              className={`md:hidden z-50 ${
                localStorage.getItem("user-id") ? "block" : "hidden"
              }`}
            >
              <div onClick={() => setOpenMenu(!openMenu)}>
                {openMenu ? (
                  <ImCross className="h-4 w-4 sm:h-5 sm:w-5 mx-auto" />
                ) : (
                  <GiHamburgerMenu className="h-4 w-4 sm:h-5 sm:w-5 mx-auto" />
                )}
              </div>
              <div
                className={`bg-secondary text-center px-2 py-4 rounded-b flex flex-col absolute duration-300 ease-in shadow-md space-y-1 w-[150px] h-screen ${
                  openMenu ? "left-0" : "left-[-250px]"
                }`}
              >
                {localStorage.getItem("user-id") && (
                  <div className="w-5/6 mx-auto">
                    <Link
                      to={`/inbox/${chatList?.[0]?.id}`}
                      className="flex justify-between items-center gap-3 cursor-pointer text-sm"
                    >
                      <BiChat className="w-6 h-6" />
                      <span className="w-full text-left">{t("Chat")}</span>
                    </Link>
                    <div className="divider m-0" />
                    <Link
                      to={`/orders`}
                      className="flex justify-between items-center gap-3 cursor-pointer text-sm"
                    >
                      <p className="flex justify-between items-center gap-3 cursor-pointer text-sm">
                        <MdOutlineBookmarkBorder className="w-6 h-6" />
                        <span className="w-full text-left">{t("Orders")}</span>
                      </p>
                    </Link>
                    <div className="divider m-0" />
                    <Link
                      to="/favourites"
                      className="flex justify-between items-center gap-3 cursor-pointer text-sm"
                    >
                      <MdOutlineFavoriteBorder className="w-6 h-6" />
                      <span className="w-full text-left">
                        {t("Favourites")}
                      </span>
                    </Link>
                    <div className="divider m-0" />
                    <Link
                      to="/settings"
                      className="flex justify-between items-center gap-3 cursor-pointer text-sm"
                    >
                      <FiSettings className="w-6 h-6" />
                      <span className="w-full text-left">{t("Settings")}</span>
                    </Link>
                    <div className="divider m-0" />
                    <Link
                      to="/help"
                      className="flex justify-between items-center gap-3 cursor-pointer text-sm"
                    >
                      <MdOutlineHelpOutline className="w-6 h-6" />
                      <span className="w-full text-left">
                        {t("Help & Feedback")}
                      </span>
                    </Link>
                    {pathname !== "/add-details" && (
                      <>
                        <div className="divider m-0" />
                        <p
                          onClick={() => {
                            navigate(`/cat/select`, {
                              state: "/add-item",
                            });
                          }}
                          to="/cat/select"
                          className="flex justify-between items-center gap-3 cursor-pointer text-sm"
                        >
                          <BiPlus className="w-6 h-6" />
                          <span className="w-full text-left">
                            {t("Sell Item")}
                          </span>
                        </p>
                      </>
                    )}
                    <div className="divider m-0" />
                    <p
                      onClick={() => {
                        logout();
                        setOpenMenu(!openMenu);
                      }}
                      className="flex justify-between items-center gap-3 cursor-pointer text-sm"
                    >
                      <MdOutlineLogin className="w-6 h-6" />
                      <span className="w-full text-left">{t("Logout")}</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <a href="/">
              <img src={Logo} alt="logo" className="w-20 xs:w-auto" />
            </a>
          </div>
          <button
            onClick={() => setShowLocationModal(true)}
            className="hidden sm:flex justify-center items-center space-x-1 w-[80px] xs:w-[150px]"
          >
            <MdOutlineLocationOn />
            <p className="font-semibold text-xs xs:text-base">
              {location?.city ||
                JSON.parse(sessionStorage.getItem("location"))?.city ||
                t("Choose Location")}
            </p>
          </button>
          <div className="form-control relative hidden sm:block">
            <input
              type="text"
              placeholder={t("Search")}
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
              className="input input-bordered lg:w-[350px] 2xl:w-[500px] text-secondary"
            />
            <span className="w-[1px] h-7 bg-gray-300 absolute right-12 top-[10px]" />
            <RiSearchLine className="absolute right-3 top-3 w-6 h-6 text-black" />

            <div className="absolute top-[42px]">
              {searchQuery && <SearchedItems searchQuery={searchQuery} />}
            </div>
          </div>
          <div className="flex items-center gap-3 md:gap-10">
            <div className="md:min-w-[345px]">
              {localStorage.getItem("user-id") ? (
                <div className="flex gap-3 md:gap-6 items-center">
                  <div className="hidden md:flex items-center">
                    <BiChat
                      onClick={() => navigate(`/inbox/${chatList?.[0]?.id}`)}
                      className="w-6 h-6 cursor-pointer "
                    />
                  </div>
                  <div className="dropdown dropdown-left sm:dropdown-right sm:dropdown-bottom">
                    <div
                      tabIndex={0}
                      role="button"
                      className="btn btn-ghost p-0"
                    >
                      <CgBell className="w-6 h-6 cursor-pointer" />
                      {unread && (
                        <div className="absolute top-3 right-0 bg-red-600 w-2 h-2 rounded-box" />
                      )}
                    </div>
                    <ul
                      tabIndex={0}
                      className={`dropdown-content z-[1] menu menu-compact p-2 shadow bg-secondary rounded-md w-52 sm:w-72 min-h-40 ${
                        notifications?.length <= 0 &&
                        "flex justify-center items-center"
                      }`}
                    >
                      {notifications?.length > 0 ? (
                        notifications.map((notification, index) => (
                          <li key={index}>
                            <div
                              onClick={() => handleMarkAsRead(notification._id)}
                              className={`text-white py-1 ${
                                notification.read && "opacity-75"
                              }`}
                            >
                              <div className="w-1/12">
                                <div
                                  className={`w-2 h-2 rounded-box ${
                                    notification.read
                                      ? "bg-gray-500"
                                      : "bg-red-600"
                                  }`}
                                />
                              </div>
                              {notification.message}
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>
                          <div className="text-white py-1">
                            {"No Notification for you "}
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                  <img
                    src={
                      userImage
                        ? userImage
                        : "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                    }
                    alt="user"
                    onClick={() =>
                      navigate(`/profile/${localStorage.getItem("user-id")}`)
                    }
                    className="md:hidden h-10 w-10 xs:h-12 xs:w-12 rounded-xl object-cover"
                  />
                  <div className="hidden md:block dropdown dropdown-end">
                    <label
                      tabIndex={0}
                      className="btn btn-ghost bg-transparent hover:bg-transparent p-0 flex items-center"
                    >
                      <img
                        src={
                          userImage
                            ? userImage
                            : "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                        }
                        alt="user"
                        className="h-10 w-10 xs:h-12 xs:w-12 rounded xs:rounded-xl object-cover"
                      />
                      <RiArrowDropDownLine className="h-7 w-7" />
                    </label>
                    <ul
                      tabIndex={0}
                      className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-secondary rounded-box w-52"
                    >
                      <li>
                        <Link
                          className="text-xs sm:text-base"
                          to={`/profile/${localStorage.getItem("user-id")}`}
                        >
                          <CgProfile /> {`${t("My")} ${t("Profile")}`}
                        </Link>
                      </li>

                      <li>
                        <Link className="text-xs sm:text-base" to={`/orders`}>
                          <MdOutlineBookmarkBorder /> {t("Orders")}
                        </Link>
                      </li>
                      <li>
                        <Link className="text-xs sm:text-base" to="/favourites">
                          <MdOutlineFavoriteBorder /> {t("Favourites")}
                        </Link>
                      </li>
                      <li>
                        <Link className="text-xs sm:text-base" to="/settings">
                          <FiSettings /> {t("Settings")}
                        </Link>
                      </li>
                      <li>
                        <Link className="text-xs sm:text-base" to="/help">
                          <MdOutlineHelpOutline /> {t("Help & Feedback")}
                        </Link>
                      </li>
                      <li>
                        <p
                          className="text-xs sm:text-base"
                          onClick={() => logout()}
                        >
                          <MdOutlineLogin /> {t("Logout")}
                        </p>
                      </li>
                    </ul>
                  </div>
                  {pathname !== "/add-details" && (
                    <button
                      onClick={() =>
                        navigate(`/cat/select`, { state: "/add-item" })
                      }
                      className="hidden md:flex btn bg-white text-black w-36 h-12 normal-case hover:bg-gray-100 duration-300 hover:border-white p-0"
                    >
                      <BiPlus className="w-6 h-6" />
                      <p className="">{t("Sell Item")}</p>
                    </button>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => navigate("/login")}
                  className="flex gap-3 items-center font-[inter] text-lg mx-auto"
                >
                  <MdOutlineLogin />
                  <p>{t("Log in")}</p>
                </button>
              )}
            </div>
            <input
              onChange={toggleTheme}
              type="checkbox"
              className="toggle toggle-xs xs:toggle-sm md:toggle-md toggle-primary"
              checked={localStorage.getItem("theme") === "light" ? false : true}
            />
          </div>
        </div>
        <div className="w-full mr-auto flex sm:hidden flex-col items-start gap-3">
          <button
            onClick={() => setShowLocationModal(true)}
            className="flex items-center space-x-1"
          >
            <MdOutlineLocationOn />
            <p className="font-semibold">
              {location?.city ||
                JSON.parse(sessionStorage.getItem("location"))?.city ||
                t("Choose Location")}
            </p>
          </button>
          <div className="form-control relative w-full z-1">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
              className="input input-bordered text-secondary h-[40px] placeholder:text-sm pb-1 w-full"
            />
            <span className="w-[1px] h-6 mx-auto right-12 top-2 bg-gray-300 absolute" />
            <RiSearchLine className="absolute right-3 top-[10px] w-5 h-5 text-black" />
            <div className="absolute top-11 w-full">
              {searchQuery && <SearchedItems searchQuery={searchQuery} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
