import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePause } from "react-icons/ai";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const HomeItems = ({ name, items, message }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = (item) => {
    localStorage.getItem("user-id")
      ? navigate(`/item/${item?._id}`)
      : item?.planType === "free"
      ? navigate(`/item/${item?._id}`)
      : navigate(`/login`);

    ReactGA.event({
      category: "Item",
      action: "Click",
      label: `Item: ${item?.itemName}, Id: ${item?._id} is clicked`,
    });
  };
  return (
    <div className="space-y-4 xs:space-y-7 text-[#444444] dark:text-white">
      <div className="flex items-center gap-2 lg:gap-4 font-[inter] text-xl">
        <AiOutlinePause className="rotate-90 w-6 h-6" />
        <p className="text-sm lg:text-xl font-semibold">{t(`${name}`)}</p>
        <span
          className="text-[#CCD1DC]
 dark:text-[#BABABA] text-sm lg:text-xl"
        >
          |
        </span>
        <Link
          to={`/items/${name.split(" ").join("-")}`}
          state={{ items, message }}
          className="flex items-center gap-1 lg:gap-3 text-xs lg:text-base dark:text-[#BABABA]"
        >
          {t("See All")}
          <HiOutlineArrowRight />
        </Link>
      </div>
      {items?.length > 0 ? (
        <>
          {/* Mobile View */}
          <div className="xs:hidden grid grid-cols-3 min-h-[160px] p-2 gap-2">
            {items?.slice(0, 3)?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleNavigate(item)}
                className="flex flex-col justify-center items-center space-y-2 cursor-pointer bg-white dark:bg-secondary rounded-xl"
              >
                <img
                  src={item?.imageGallery?.[0]}
                  alt={item?.itemName}
                  className="h-[70px] w-[70px] md:h-[100px] md:w-[100px] object-cover rounded-lg"
                />

                <div className="text-center text-sm font-[poppins]">
                  <p className="text-xs font-semibold">
                    {item?.itemName?.slice(0, 11) + "..."}
                  </p>
                  <p className="font-[inter] flex justify-center items-center gap-1">
                    <span className="text-[10px]">€{item?.discPrice}</span>
                    <span className="text-[#9D9D9D] text-[8px] line-through">
                      €{item.price}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Larger View */}
          <div className="hidden xs:justify-center xs:grid xs:grid-cols-4 xl:grid-cols-8 gap-5 bg-white dark:bg-secondary shadow-md rounded-[20px] min-h-[200px] p-2 xs:p-3 xl:px-9 xl:py-0">
            {items?.slice(0, 8)?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleNavigate(item)}
                className="card flex flex-col justify-center space-y-2 cursor-pointer"
              >
                <figure>
                  <img
                    src={item?.imageGallery[0]}
                    alt={item?.itemName}
                    className="h-[70px] w-[70px] md:h-[100px] md:w-[100px] object-cover rounded-lg"
                  />
                </figure>
                <div className="text-center text-sm font-[poppins]">
                  <p className="text-xs md:text-sm font-semibold">
                    {item?.itemName.slice(0, 11) + "..."}
                  </p>
                  <p className="font-[inter] flex justify-center items-center gap-1">
                    <span className="text-[10px] md:text-[14px]">
                      €{item?.discPrice}
                    </span>
                    <span className="text-[#9D9D9D] text-[8px] md:text-xs line-through">
                      €{item.price}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="min-h-[160px] xs:min-h-[200px] bg-white dark:bg-secondary shadow-md rounded-[20px] p-5 flex justify-center items-center">
          <p className="text-base xs:text-lg text-center">{t(`${message}`)}</p>
        </div>
      )}
    </div>
  );
};

export default HomeItems;
