import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Loading from "../../Shared/Loading";
import { useTranslation } from "react-i18next";

const SelectSubcategory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const catLocal = JSON.parse(localStorage.getItem("category"));
  const category = useLocation()?.state?.category;
  const pathName = useLocation().state?.pathName;
  const catId = category ? category?.collection?._id : catLocal._id;
  const url = `${process.env.REACT_APP_ENDPOINT}/collection/subcat/${catId}`;
  const { data: subcategoryData, isLoading } = useQuery(
    [`subcategoryData`],
    () =>
      axios.get(url).then((data) => {
        return data.data;
      })
  );
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App min-h-screen max-w-5xl mx-auto">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} / {subcategoryData?.parent?.name} /
        <span className="text-black dark:text-[#E2E2E2]">
          {" "}
          {t("Subcategories")}
        </span>
      </p>
      <div className="flex items-center justify-between bg-[#EDEFF4] dark:bg-[#3B3B3B] rounded-[10px] border-[#D3DAEB] dark:border-[#6E6E6E] border-[1px] mb-5 px-[18px] h-[70px]">
        <div className="flex gap-[18px] items-center">
          <img
            src={subcategoryData?.parent?.logo}
            className="w-[40px] md:w-auto"
            alt=" "
          />
          <h1 className="font-semibold">{subcategoryData?.parent?.name}</h1>
        </div>
        {/* <MdEdit className="h-6 w-6" /> */}
      </div>
      <div className="grid grid-cols-2 xs:grid-cols-3 gap-5">
        {subcategoryData?.subcategories?.map((subcategory, index) => (
          <button
            key={index}
            onClick={() => {
              navigate(`${pathName}`, { state: { category, subcategory } });
            }}
            // className={`bg-white dark:bg-secondary shadow-md rounded-[10px] dark:border-secondary border-[1px] h-[100px] w-28 sm:w-44 md:w-60 md:px-7 mx-auto`}
            className={`bg-white dark:bg-secondary shadow-md rounded-[10px] dark:border-secondary border-[1px] h-[100px] w-full sm:w-44 md:w-60 md:px-5 mx-auto`}
          >
            <div className="flex flex-col md:flex-row md:justify-center items-center gap-2 md:gap-2 w-full">
              <img
                src={subcategory?.logo}
                className="w-[50px] md:w-4/12"
                alt=" "
              />
              <div className="md:w-8/12">
                <h1 className="xs:text-left text-[10px] md:text-base font-[poppins] font-medium mx-[2px] xs:mx-0">
                  {subcategory?.name}
                </h1>
              </div>
            </div>
            {/* <div className="flex flex-col md:flex-row md:justify-between items-center gap-2 md:gap-4 w-full">
              <img
                src={subcategory?.logo}
                className="w-[40px] md:w-auto"
                alt=" "
              />
              <h1 className="xs:text-right text-[10px] md:text-base font-[poppins] font-medium mx-[2px] xs:mx-0">
                {subcategory?.name}
              </h1>
            </div> */}

            {/* {categoryName === category?.name && (
      <CheckIcon className="h-8 w-8" />
    )} */}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectSubcategory;
