import React from "react";
import { useTranslation } from "react-i18next";
import { RiCheckDoubleLine } from "react-icons/ri";

const Language = () => {
  const { i18n } = useTranslation();
  const handleLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };
  const languages = [
    { lan: "en", img: "/Assets/img/language/en.png" },
    { lan: "de", img: "/Assets/img/language/de.png" },
  ];
  return (
    <div className="flex justify-center h-full md:h-auto gap-3 mt-2 ml-2">
      {languages?.map((language, index) => (
        <button
          key={index}
          onClick={() => handleLanguage(language?.lan)}
          className={`btn btn-secondary h-6 md:h-14 flex gap-1 md:gap-2 p-[3px] md:p-2`}
        >
          <img src={language?.img} alt="" className={`h-5 md:h-8 w-auto`} />
          <p className={`text-sm md:text-lg`}>{language?.lan}</p>

          <RiCheckDoubleLine
            className={`h-4 w-4 md:h-7 md:w-7 text-green-600 ${
              localStorage.getItem("lang") === language?.lan
                ? "opacity-100"
                : "opacity-0"
            }`}
          />
        </button>
      ))}
    </div>
  );
};

export default Language;
