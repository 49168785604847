import React, { useState } from "react";
import ChatList from "./ChatList";
import ChatBox from "./ChatBox";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const Inbox = () => {
  const { t } = useTranslation();
  // if (window.location.reload) {
  //   window.scrollTo(0, 90);
  // }
  const { chatId } = useParams();
  const [openedChat, setOpenedChat] = useState("");
  const userId = localStorage.getItem("user-id");
  const chatListUrl = `${process.env.REACT_APP_ENDPOINT}/chats/${userId}/user`;
  const { data: chatList, refetch: chatListRefetch } = useQuery(
    [`chatList`, chatListUrl],
    () =>
      axios.get(chatListUrl).then((data) => {
        return data.data;
      })
  );
  return (
    <div className="App min-h-screen max-w-5xl 2xl:max-w-7xl mx-auto">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">{t("Inbox")}</span>
      </p>

      <div className="grid grid-cols-12 max-w-[780px] 2xl:max-w-6xl rounded-xl overflow-hidden font-[poppins]">
        <ChatList
          chatId={chatId}
          setOpenedChat={setOpenedChat}
          chatList={chatList}
        />
        <ChatBox
          chatId={chatId}
          openedChat={openedChat}
          chatListRefetch={chatListRefetch}
        />
      </div>
    </div>
  );
};

export default Inbox;
