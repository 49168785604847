import React from "react";
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";
import { RxCross1 } from "react-icons/rx";
import ReactGA from "react-ga4";

const LocationModal = ({
  showModal,
  setShowModal,
  handleLocationLocalStorage,
}) => {
  const { t } = useTranslation();
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const getLocation = (place) => {
    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();
    handleLocationLocalStorage(lat, lng);
  };

  return (
    <div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
            <div className="relative rounded-lg bg-white dark:bg-secondary w-72 xs:w-96 2xl:w-[500px] bottom-32 2xl:bottom-52 p-8 shadow-md">
              <div className="flex justify-between">
                <p className="text-black dark:text-white font-[inter] font-semibold 2xl:text-2xl">
                  {t("Choose Location")}
                </p>

                <button
                  className="bg-slate-800 dark:bg-white h-6 w-6 2xl:h-8 2xl:w-8 rounded-md flex justify-center items-center"
                  onClick={() => setShowModal(false)}
                >
                  <RxCross1 className="text-white dark:text-black" />
                </button>
              </div>
              <div className="form-control my-6">
                <Autocomplete
                  apiKey={googleMapsApiKey}
                  className="input input-bordered text-secondary 2xl:text-2xl"
                  placeholder={t("Enter Location")}
                  onPlaceSelected={(place) => {
                    ReactGA.event({
                      category: "Location",
                      action: "Click",
                      label: `Location changed to ${place?.formatted_address}`,
                    });
                    getLocation(place);
                    setShowModal(false);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default LocationModal;
