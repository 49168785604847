import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Shared/Loading";
import ItemCard from "./ItemCard";
import { useTranslation } from "react-i18next";

const CategoryItems = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  const { t } = useTranslation();
  const coordinates = JSON.parse(localStorage.getItem("coordinates"));
  const catLocal = JSON.parse(localStorage.getItem("category"));
  // const lat = coordinates?.lat || 48.7758459;
  // const lng = coordinates?.lng || 9.1829321;
  const category = useLocation()?.state?.category;
  const catId = category ? category?.collection?._id : catLocal?._id;
  const url = `${process.env.REACT_APP_ENDPOINT}/collection/${catId}?lat=${coordinates?.lat}&lng=${coordinates?.lng}`;

  const { data: items, isLoading } = useQuery([`items`, url], () =>
    axios.get(url).then((data) => {
      return data.data.items;
    })
  );
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App min-h-screen">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {category?.collection?.name}
        </span>
      </p>
      {items?.length > 0 ? (
        <ItemCard items={items} />
      ) : (
        <p className="text-center font-[inter] text-xl">
          {t("No Item is Available")}
        </p>
      )}
    </div>
  );
};

export default CategoryItems;
