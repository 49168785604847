import React, { useContext } from "react";
import HomeCategories from "./HomeCategories";
import HomeBanner from "./HomeBanner";
import HomeVipCircle from "./HomeVipCircle";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import HomeItems from "./HomeItems";
import { AppContext } from "../../App";
import Loading from "../Shared/Loading";
import ReactGA from "react-ga4";

// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: "/", title: "Home Visit" });

const Home = () => {
  const { lat, lng } = useContext(AppContext);

  const itemsUrl = `${process.env.REACT_APP_ENDPOINT}/home/loc/${lat}/${lng}`;
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  const { data: homeItems, isLoading } = useQuery(
    [`homeItems`, itemsUrl],
    () => axios.get(itemsUrl, config).then((data) => data?.data)
    // {
    //   enabled: coordinates?.lat !== undefined && coordinates?.lng !== undefined,
    // }
  );
  if (isLoading) {
    return <Loading />;
  }
  const todaysDeals = homeItems?.bestDeals;
  const newlyAddedItems = homeItems?.newlyAdded;
  const popularItems = homeItems?.popular;
  const nearbyItems = homeItems?.nearby;
  const todaysDealsMessage = "Currently there are no items with deals";
  const newlyAddedMessage = "Currently there are no items added newly";
  const popularMessage = "Currently there are no popular items";
  const nearbyMessage = "Currently there are no items near to you";
  return (
    <div className="App min-h-screen space-y-5 xs:space-y-10">
      <HomeCategories />
      <HomeBanner />
      <HomeVipCircle />
      <HomeItems
        name={"Todays Deal"}
        items={todaysDeals}
        message={todaysDealsMessage}
      />
      <HomeItems
        name={"Newly Added"}
        items={newlyAddedItems}
        message={newlyAddedMessage}
      />
      <HomeItems
        name={"Popular Items"}
        items={popularItems}
        message={popularMessage}
      />
      <HomeItems
        name={"Nearby Items"}
        items={nearbyItems}
        message={nearbyMessage}
      />
    </div>
  );
};

export default Home;
