import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const DeleteChatModal = ({
  deleteChat,
  setDeleteChat,
  allMessageRefetch,
  chatListRefetch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = deleteChat;

  const handleDelete = (id) => {
    const url = `${process.env.REACT_APP_ENDPOINT}/chats/${id}`;
    const config = {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    };

    axios.delete(url, config).then((data) => {
      if (data.status === 200) {
        toast.success(`Chat is Deleted Successfully`, {
          theme: "colored",
        });
        allMessageRefetch();
        chatListRefetch();
        setDeleteChat(null);
        navigate(-1);
      } else {
        toast.error(`Can't Delete this Chat`, { theme: "colored" });
      }
    });
  };
  return (
    <div>
      <input type="checkbox" id="delete-chat-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box dark:bg-secondary">
          <h3 className="font-bold text-lg text-black dark:text-white">{`${t(
            "Are you sure You Want to Delete this Chat?"
          )}`}</h3>
          <div className="modal-action">
            <button
              onClick={() => handleDelete(id)}
              className="btn btn-sm btn-outline btn-error"
            >
              {t("Confirm")}
            </button>
            <label
              onClick={() => setDeleteChat(null)}
              htmlFor="delete-chat-modal"
              className="btn btn-sm"
            >
              {t("Cancel")}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteChatModal;
