import React from "react";
import { useTranslation } from "react-i18next";

const ThemeCenter = ({ toggleTheme }) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center h-full md:h-auto mt-4 ml-4">
      <div className="flex gap-2">
        <p className="text-xs md:text-base">{t("Light")}</p>
        <input
          onChange={toggleTheme}
          type="checkbox"
          className="toggle toggle-sm md:toggle-md toggle-primary w-9"
          checked={localStorage.getItem("theme") === "light" ? false : true}
        />
        <p className="text-xs md:text-base">{t("Dark")}</p>
      </div>
    </div>
  );
};

export default ThemeCenter;
