import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import Autocomplete from "react-google-autocomplete";
import { toast } from "react-toastify";
import Loading from "../Shared/Loading";
import { useTranslation } from "react-i18next";
import years from "../../Assets/jsons/years.json";
import counts from "../../Assets/jsons/counts.json";
import contracts from "../../Assets/jsons/contracts.json";
import addAttributes from "../Items/SellItems/addAttributes";
import ReactGA from "react-ga4";

const AddStatus = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { category, subcategory } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [itemImageURLs, setItemImageURLs] = useState([]);
  const [imgMessage, setImgMessage] = useState(false);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [address, setAddress] = useState("");
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleNavigate = () => {
    navigate(
      subcategory
        ? `/cat/${category?.collection?.name
            .toLowerCase()
            .split(" ")
            .join("_")}/sub/${subcategory?.name
            .toLowerCase()
            .split(" ")
            .join("_")}`
        : `/cat/${category?.collection?.name
            .toLowerCase()
            .split(" ")
            .join("_")}`,
      { state: { category, subcategory } }
    );
  };

  const uploadImages = async (data) => {
    if (data.target.files[0].type !== "image/jpeg") {
      toast.error("Only JPEG or JPG Image files formats should be supported", {
        theme: "colored",
      });
    } else {
      let formdata = new FormData();
      formdata.append("file", data.target.files[0]);

      await axios
        .post(`${process.env.REACT_APP_ENDPOINT}/upload`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          setItemImageURLs([...itemImageURLs, data.data.files[0].url]);
        });
    }
  };

  const handleImages = (selectedImageURL) => {
    const filteredURLs = itemImageURLs.filter(
      (imageURL) => imageURL !== selectedImageURL
    );
    setItemImageURLs([...filteredURLs]);
  };

  const onSubmit = async (data) => {
    if (itemImageURLs?.length <= 0) {
      setImgMessage(true);
    } else {
      setImgMessage(false);
      setLoading(true);

      // const itemImagePathname = `vip-ware/items/${
      //   subcategory ? subcategory?.name : category?.collection?.name
      // }/${data?.itemName}`;
      // const itemImageURLs = await uploadMultipleImages(
      //   itemImgFiles,
      //   itemImagePathname
      // );
      const itemInfo = {
        itemName: data?.itemName,
        categoryID: subcategory ? subcategory?._id : category?.collection?._id,
        categoryName: subcategory
          ? subcategory?.name
          : category?.collection?.name,
        description: data?.description,
        imageGallery: itemImageURLs,
        price: parseInt(data?.price),
        discPrice: parseInt(data?.price),
        lat: lat,
        lng: lng,
        address: {
          formatted_address: address,
          street1: data?.street1,
          street2: data?.street2,
        },
        makeStatus: true,
        // subcategoryName: categoryDetails?.subcategory?.name,
        // brand: "Range Rover",
        // location: [lat, lng],
      };
      const itemInfoWithAttributes = await addAttributes(
        itemInfo,
        category?.collection?.name,
        data
      );
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_ENDPOINT}/item`,
          itemInfoWithAttributes,
          config
        )
        .then((data) => {
          if (data.status === 200) {
            toast.success(`${data.data.itemName} is added Successfully`, {
              theme: "colored",
            });
            handleNavigate();
            ReactGA.event({
              category: "Status",
              action: "Click",
              label: `New status for ${data.data.itemName} is added`,
            });
          } else {
            toast.error(`Can't Add Status`, { theme: "colored" });
          }
        })
        .finally(() => setLoading(false));
    }
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="App min-h-screen max-w-6xl mx-auto text-black dark:text-[#E2E2E2]">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} / {t("Profile")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {t("Add Status")}
        </span>
      </p>
      <div className="bg-white dark:bg-secondary rounded-[20px] p-6 sm:p-12">
        <p className="font-semibold mb-3">
          {t("Status")} {t("Details")}
        </p>
        <hr />
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-2 sm:space-y-5 mt-3"
        >
          <div className="">
            <label className="label">
              <span className="label-text dark:text-white font-semibold">
                {t("Upload photos upto 12")} *
              </span>
            </label>
            <div className="flex flex-wrap gap-3">
              {itemImageURLs.length > 0 &&
                itemImageURLs?.map((itemImgURL, index) => (
                  <div key={index} className="relative">
                    <img
                      src={itemImgURL}
                      alt=" "
                      className="w-[100px] h-[75px] rounded-[10px] object-cover"
                    />
                    <RxCrossCircled
                      onClick={() => handleImages(itemImgURL)}
                      className="absolute -top-2 -right-2 cursor-pointer text-red-600 font-bold w-6 h-6"
                    />
                  </div>
                ))}
              {itemImageURLs.length < 12 && (
                <label className="cursor-pointer bg-[#F6F6F6] dark:bg-[#A6A6A6] w-[100px] h-[75px] rounded-[10px] flex flex-col justify-center items-center gap-1">
                  <MdOutlineAddPhotoAlternate className="text-black h-7 w-7" />
                  <p className="font-[roboto] text-xs text-black">
                    {t("Add Photo")}
                  </p>
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => {
                      uploadImages(e);
                    }}
                    required={itemImageURLs?.length <= 0 ? true : false}
                  />
                </label>
              )}
            </div>
            {imgMessage && (
              <span className="label-text-alt text-red-600 text-sm">
                {t("Select minimum 1 Image")}
              </span>
            )}
          </div>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-5">
            <div className="flex items-center gap-[6px] px-2 rounded-lg bg-[#F6F6F6] dark:bg-[#2E2D2D] h-[50px] w-[150px] relative">
              <img
                className="mask mask-circle object-cover w-[34px] h-[34px]"
                src={category?.collection?.logo}
                alt=" "
              />
              <p className="text-sm">{category?.collection?.name}</p>
              {/* <label
                htmlFor="select-category-modal"
                className="cursor-pointer bg-[#161616] h-6 w-6 absolute rounded flex justify-center items-center bottom-[35px] left-[130px]"
              >
                <Edit />
              </label> */}
            </div>
            {subcategory && (
              <div className="flex items-center gap-[6px] px-2 rounded-lg bg-[#F6F6F6] dark:bg-[#2E2D2D] h-[50px] w-[150px] relative">
                <img
                  className="mask mask-circle object-cover w-[34px] h-[34px]"
                  src={subcategory?.logo}
                  alt=" "
                />
                <p className="text-sm">{subcategory?.name}</p>
                {/* <label
                  htmlFor="select-category-modal"
                  className="cursor-pointer bg-[#161616] h-6 w-6 absolute rounded flex justify-center items-center bottom-[35px] left-[130px]"
                >
                  <Edit />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      // setImgFile(e.target.files[0]);
                    }}
                  />
                </label> */}
              </div>
            )}
          </div>
          <div className="form-control max-w-sm">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Price")} *
              </span>
            </label>
            <input
              type="number"
              min={0}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder={"000"}
              {...register("price", {
                required: {
                  value: true,
                  message: "Price is required",
                },
              })}
            />
            <label className="label">
              {errors.price?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.price.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control max-w-lg">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Name of the Product")} *
              </span>
            </label>
            <input
              type="text"
              placeholder={t("Product Name")}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("itemName", {
                required: {
                  value: true,
                  message: "Item Name is required",
                },
              })}
            />
            <label className="label">
              {errors.itemName?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.itemName.message}
                </span>
              )}
            </label>
          </div>
          {/* Vehicles */}
          {category?.collection?.name === "Vehicles" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Make")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Make")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("make_type", {
                    required: {
                      value: true,
                      message: "Make is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.make_type?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.make_type.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Model")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Model")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("model_number", {
                    required: {
                      value: true,
                      message: "Model is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.model_number?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.model_number.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Year")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("year", {
                    required: {
                      value: true,
                      message: "Year is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  {years?.map((year, index) => (
                    <option key={index}>{year}</option>
                  ))}
                </select>
                <label className="label">
                  {errors.year?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.year.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Mileage")} *
                  </span>
                </label>
                <input
                  type="number"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("Mileage")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("mileage", {
                    required: {
                      value: true,
                      message: "Mileage is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.mileage?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.mileage.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Condition")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("condition_type", {
                    required: {
                      value: true,
                      message: "Condition is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  <option>New</option>
                  <option>Used</option>
                  <option>Refurbished</option>
                </select>
                <label className="label">
                  {errors.condition_type?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.condition_type.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Color")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Color")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("color", {
                    required: {
                      value: true,
                      message: "Color is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.color?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.color.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Body Type")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("body_type", {
                    required: {
                      value: true,
                      message: "Body Type is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  <option>Sedan</option>
                  <option>SUV</option>
                  <option>Truck</option>
                  <option>Coupe</option>
                  <option>Other</option>
                </select>
                <label className="label">
                  {errors.body_type?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.body_type.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Fuel Type")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("fuel_type", {
                    required: {
                      value: true,
                      message: "Fuel Type is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  <option>Petrol</option>
                  <option>Dieseal</option>
                  <option>Gasoline</option>
                  <option>Electric</option>
                </select>
                <label className="label">
                  {errors.fuel_type?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.fuel_type.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Transmission")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("transmission_type", {
                    required: {
                      value: true,
                      message: "Transmission is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  <option>Automatic</option>
                  <option>Manual</option>
                </select>
                <label className="label">
                  {errors.transmission_type?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.transmission_type.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Seating Capacity")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("seating_capacity", {
                    required: {
                      value: true,
                      message: "Seating Capacity is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  {counts?.map((count, index) => (
                    <option key={index}>{count}</option>
                  ))}
                </select>
                <label className="label">
                  {errors.seating_capacity?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.seating_capacity.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Real Estate */}
          {category?.collection?.name === "Real Estate" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Property Type")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("property_type", {
                    required: {
                      value: true,
                      message: "Property Type is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  <option>House</option>
                  <option>Apartment</option>
                  <option>Condo</option>
                  <option>Land</option>
                  <option>Commercial Building</option>
                </select>
                <label className="label">
                  {errors.property_type?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.property_type.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Bedrooms")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("bedrooms", {
                    required: {
                      value: true,
                      message: "Bedrooms is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  {counts?.map((count, index) => (
                    <option key={index}>{count}</option>
                  ))}
                </select>
                <label className="label">
                  {errors.bedrooms?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.bedrooms.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Bathrooms")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("bathrooms", {
                    required: {
                      value: true,
                      message: "Bathrooms is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  {counts?.map((count, index) => (
                    <option key={index}>{count}</option>
                  ))}
                </select>
                <label className="label">
                  {errors.bathrooms?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.bathrooms.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Square Footage")} *
                  </span>
                </label>
                <input
                  type="number"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("Square Footage")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("square_footage", {
                    required: {
                      value: true,
                      message: "Square Footage is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.square_footage?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.square_footage.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Year Built")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("year_built", {
                    required: {
                      value: true,
                      message: "Year Built is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  {years?.map((year, index) => (
                    <option key={index}>{year}</option>
                  ))}
                </select>
                <label className="label">
                  {errors.year_built?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.year_built.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Condition")} *
                  </span>
                </label>
                <select
                  className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("condition", {
                    required: {
                      value: true,
                      message: "Condition is required",
                    },
                  })}
                >
                  <option disabled defaultValue={""}>
                    Select One
                  </option>
                  <option>New</option>
                  <option>Renovated</option>
                  <option>Fixer-upper</option>
                </select>
                <label className="label">
                  {errors.condition?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.condition.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Metal */}
          {category?.collection?.name === "Metals" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Metal Type")} *
                    </span>
                  </label>
                  <select
                    className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("metal_type", {
                      required: {
                        value: true,
                        message: "Metal Type is required",
                      },
                    })}
                  >
                    <option disabled defaultValue={""}>
                      Select One
                    </option>
                    <option>Gold</option>
                    <option>Silver</option>
                    <option>Copper</option>
                    <option>Aluminum</option>
                    <option>Steel</option>
                  </select>
                  <label className="label">
                    {errors.metal_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.metal_type.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Form")} *
                    </span>
                  </label>
                  <select
                    className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("form_type", {
                      required: {
                        value: true,
                        message: "Form is required",
                      },
                    })}
                  >
                    <option disabled defaultValue={""}>
                      Select One
                    </option>
                    <option>Bars</option>
                    <option>Coins</option>
                    <option>Sheets</option>
                    <option>Wires</option>
                  </select>
                  <label className="label">
                    {errors.form_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.form_type.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Weight in Grams")} *
                  </span>
                </label>
                <input
                  type="number"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  placeholder={"000"}
                  {...register("weight_in_grams", {
                    required: {
                      value: true,
                      message: "Weight is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.weight_in_grams?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.weight_in_grams.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Contract  */}
          {category?.collection?.name === "Contracts" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Contract Type")} *
                    </span>
                  </label>
                  <select
                    className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("contract_type", {
                      required: {
                        value: true,
                        message: "Contract Type is required",
                      },
                    })}
                  >
                    <option disabled defaultValue={""}>
                      Select One
                    </option>
                    {contracts.map((contract, index) => (
                      <option key={index}>{contract}</option>
                    ))}
                  </select>
                  <label className="label">
                    {errors.contract_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.contract_type.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Availability")} *
                    </span>
                  </label>
                  <select
                    className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("availablity", {
                      required: {
                        value: true,
                        message: "Availability is required",
                      },
                    })}
                  >
                    <option disabled defaultValue={""}>
                      Select One
                    </option>
                    <option>Immediate Purchase</option>
                    <option>Custom Order</option>
                  </select>
                  <label className="label">
                    {errors.availablity?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.availablity.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          )}
          {/* Hotel Booking */}
          {category?.collection?.name === "Hotel Booking" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Hotel Name")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"Hotel Name"}
                    {...register("hotel_name", {
                      required: {
                        value: true,
                        message: "Hotel Name is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.hotel_name?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.hotel_name.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Location")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"Location"}
                    {...register("_location", {
                      required: {
                        value: true,
                        message: "Location is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors._location?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors._location.message}
                      </span>
                    )}
                  </label>
                </div>

                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Check In Date")} *
                    </span>
                  </label>
                  <input
                    type="datetime-local"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("checkin_dates", {
                      required: {
                        value: true,
                        message: "Check In Date is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.checkin_dates?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.checkin_dates.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Check Out Date")} *
                    </span>
                  </label>
                  <input
                    type="datetime-local"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("checkout_dates", {
                      required: {
                        value: true,
                        message: "Check Out Date is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.checkout_dates?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.checkout_dates.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Number of Guests")} *
                    </span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    onWheel={(e) => e.target.blur()}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"00"}
                    {...register("total_guests", {
                      required: {
                        value: true,
                        message: "Number of Guests is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.total_guests?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.total_guests.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Room Type")} *
                    </span>
                  </label>
                  <select
                    className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("room_type", {
                      required: {
                        value: true,
                        message: "Room Type is required",
                      },
                    })}
                  >
                    <option disabled defaultValue={""}>
                      Select One
                    </option>
                    <option>Standard</option>
                    <option>Deluxe</option>
                    <option>Suite</option>
                  </select>
                  <label className="label">
                    {errors.room_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.room_type.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          )}
          {/* Patents  */}
          {category?.collection?.name === "Patents" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Inventor Information")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"Inventor Information"}
                    {...register("inventor_information", {
                      required: {
                        value: true,
                        message: "Inventor Information is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.inventor_information?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.inventor_information.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Patent Number")} *
                    </span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    onWheel={(e) => e.target.blur()}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"Patent Number"}
                    {...register("patent_number", {
                      required: {
                        value: true,
                        message: "Patent Number is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.patent_number?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.patent_number.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Publication Date")} *
                  </span>
                </label>
                <input
                  type="datetime-local"
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("publication_date", {
                    required: {
                      value: true,
                      message: "Publication Date is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.publication_date?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.publication_date.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Luxury Goods and Antique */}
          {(category?.collection?.name === "Luxury Goods" ||
            category?.collection?.name === "Antique") && (
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  {t("Materials")} *
                </span>
              </label>
              <input
                type="text"
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                placeholder={"Materials"}
                {...register("materials", {
                  required: {
                    value: true,
                    message: "Materials is required",
                  },
                })}
              />
              <label className="label">
                {errors.materials?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.materials.message}
                  </span>
                )}
              </label>
            </div>
          )}
          {/* Helicopter, Jets and Airplane */}
          {(category?.collection?.name === "Helicopters" ||
            category?.collection?.name === "Jets" ||
            category?.collection?.name === "Airplanes") && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {category?.collection?.name === "Helicopters"
                        ? t("Helicopter")
                        : t("Aircraft")}{" "}
                      {t("Model")}*
                    </span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"Model"}
                    {...register(
                      category?.collection?.name === "Helicopters"
                        ? "helicopter_model"
                        : "aircraft_model",
                      {
                        required: {
                          value: true,
                          message: `${
                            category?.collection?.name === "Helicopters"
                              ? "Helicpter Modal"
                              : "Aircraft Modal"
                          } is required`,
                        },
                      }
                    )}
                  />
                  <label className="label">
                    {category?.collection?.name === "Helicopters"
                      ? errors.helicopter_model?.type === "required" && (
                          <span className="label-text-alt text-red-600 text-sm">
                            {errors.helicopter_model.message}
                          </span>
                        )
                      : errors.aircraft_model?.type === "required" && (
                          <span className="label-text-alt text-red-600 text-sm">
                            {errors.aircraft_model.message}
                          </span>
                        )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Engine")}*
                    </span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"Engine"}
                    {...register("engine_type", {
                      required: {
                        value: true,
                        message: `Engine is required`,
                      },
                    })}
                  />
                  <label className="label">
                    {errors.engine_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.engine_type.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              {(category?.collection?.name === "Helicopters" ||
                category?.collection?.name === "Airplanes") && (
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Seating Capacity")} *
                    </span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    onWheel={(e) => e.target.blur()}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"00"}
                    {...register("seating_capacity", {
                      required: {
                        value: true,
                        message: "Seating Capacity is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.seating_capacity?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.seating_capacity.message}
                      </span>
                    )}
                  </label>
                </div>
              )}
            </div>
          )}
          {/* Tank */}
          {category?.collection?.name === "Tanks" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Make")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Make")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("make_type", {
                      required: {
                        value: true,
                        message: "Make is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.make_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.make_type.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Model")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Model")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("model_type", {
                      required: {
                        value: true,
                        message: "Model is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.model_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.model_type.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          )}
          {/* Pets */}
          {category?.collection?.name === "Pets" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Type of Pet")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Type of Pet")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("pet_type", {
                      required: {
                        value: true,
                        message: "Type of Pet is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.pet_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.pet_type.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control W-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Age")} *
                    </span>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    min={0}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"00"}
                    {...register("age", {
                      required: {
                        value: true,
                        message: "Age is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.age?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.age.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Gender")} *
                    </span>
                  </label>
                  <select
                    className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("gender", {
                      required: {
                        value: true,
                        message: "Gender is required",
                      },
                    })}
                  >
                    <option disabled defaultValue={""}>
                      Select One
                    </option>
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                  <label className="label">
                    {errors.gender?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.gender.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Training Levels")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Training Levels")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("training_levels", {
                      required: {
                        value: true,
                        message: "Training Level is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.training_levels?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.training_levels.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          )}
          {/* Special Foods, Health */}
          {(category?.collection?.name === "Special Foods" ||
            category?.collection?.name === "Health") && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                {category?.collection?.name === "Health" && (
                  <div className="form-control W-full">
                    <label className="label">
                      <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                        {t("Benefits")} *
                      </span>
                    </label>
                    <input
                      type="text"
                      className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                      placeholder={"Benefits"}
                      {...register("benefits", {
                        required: {
                          value: true,
                          message: "Benefits is required",
                        },
                      })}
                    />
                    <label className="label">
                      {errors.benefits?.type === "required" && (
                        <span className="label-text-alt text-red-600 text-sm">
                          {errors.benefits.message}
                        </span>
                      )}
                    </label>
                  </div>
                )}
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Ingredients")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Ingredients")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("ingreadients", {
                      required: {
                        value: true,
                        message: "Ingredients is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.ingreadients?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.ingreadients.message}
                      </span>
                    )}
                  </label>
                </div>
                {category?.collection?.name === "Special Foods" && (
                  <div className="form-control W-full">
                    <label className="label">
                      <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                        {t("Nutritional Information")} *
                      </span>
                    </label>
                    <input
                      type="text"
                      className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                      placeholder={"Nutritional Information"}
                      {...register("nutritional_information", {
                        required: {
                          value: true,
                          message: "Nutritional Information is required",
                        },
                      })}
                    />
                    <label className="label">
                      {errors.nutritional_information?.type === "required" && (
                        <span className="label-text-alt text-red-600 text-sm">
                          {errors.nutritional_information.message}
                        </span>
                      )}
                    </label>
                  </div>
                )}
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Usage Instructions")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Usage Instructions")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("usage_instructions", {
                    required: {
                      value: true,
                      message: "Usage Instructions is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.usage_instructions?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.usage_instructions.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Professional Services */}
          {category?.collection?.name === "Professional Services" && (
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  {t("Expertise and Qualifications")} *
                </span>
              </label>
              <input
                type="text"
                placeholder={t("Expertise and Qualifications")}
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("expertise_and_qualifications", {
                  required: {
                    value: true,
                    message: "Expertise and Qualifications is required",
                  },
                })}
              />
              <label className="label">
                {errors.expertise_and_qualifications?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.expertise_and_qualifications.message}
                  </span>
                )}
              </label>
            </div>
          )}
          {/* Sports Items */}
          {category?.collection?.name === "Sports Items" && (
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  {t("Sport Type")} *
                </span>
              </label>
              <input
                type="text"
                placeholder={t("Sport Type")}
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("sport_type", {
                  required: {
                    value: true,
                    message: "Sport Type is required",
                  },
                })}
              />
              <label className="label">
                {errors.sport_type?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.sport_type.message}
                  </span>
                )}
              </label>
            </div>
          )}
          {/* Business Items */}
          {category?.collection?.name === "Business Items" && (
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  {t("Business Type")} *
                </span>
              </label>
              <select
                className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("business_type", {
                  required: {
                    value: true,
                    message: "Business Type is required",
                  },
                })}
              >
                <option disabled defaultValue={""}>
                  Select One
                </option>
                <option>Online</option>
                <option>Offline</option>
              </select>
              <label className="label">
                {errors.business_type?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.business_type.message}
                  </span>
                )}
              </label>
            </div>
          )}
          {/* Music */}
          {category?.collection?.name === "Music" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Product Category")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Product Category")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("product_category", {
                      required: {
                        value: true,
                        message: "Product Category is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.product_category?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.product_category.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Instrument Type")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Instrument Type")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("instrument_type", {
                      required: {
                        value: true,
                        message: "Instrument Type is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.instrument_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.instrument_type.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Material and Construction")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Material and Construction")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("material_and_construction", {
                    required: {
                      value: true,
                      message: "Material and Construction is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.material_and_construction?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.material_and_construction.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Concert Tickets */}
          {category?.collection?.name === "Concert Tickets" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Event Date and Time")} *
                    </span>
                  </label>
                  <input
                    type="datetime-local"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("event_date_and_time", {
                      required: {
                        value: true,
                        message: "Event Date and Time is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.event_date_and_time?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.event_date_and_time.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Venue Information")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"Venue Information"}
                    {...register("venue_information", {
                      required: {
                        value: true,
                        message: "Venue Information is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.venue_information?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.venue_information.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Ticket Type")} *
                    </span>
                  </label>
                  <select
                    className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("seating_or_standing", {
                      required: {
                        value: true,
                        message: "Ticket Type is required",
                      },
                    })}
                  >
                    <option disabled defaultValue={""}>
                      Select One
                    </option>
                    <option>Seating</option>
                    <option>Standing</option>
                  </select>
                  <label className="label">
                    {errors.seating_or_standing?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.seating_or_standing.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Age Restrictions")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    placeholder={"Age Restrictions"}
                    {...register("age_restrictions", {
                      required: {
                        value: true,
                        message: "Age Restrictions is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.age_restrictions?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.age_restrictions.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Official Website")} *
                  </span>
                </label>
                <input
                  type="text"
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  placeholder={"Website"}
                  {...register("official_website", {
                    required: {
                      value: true,
                      message: "Official Website is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.official_website?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.official_website.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Garden */}
          {category?.collection?.name === "Garden" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Product Category")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Product Category")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("product_category", {
                      required: {
                        value: true,
                        message: "Product Category is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.product_category?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.product_category.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Plant Type")} *
                    </span>
                  </label>
                  <select
                    className="select bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("plant_type", {
                      required: {
                        value: true,
                        message: "Plant Type is required",
                      },
                    })}
                  >
                    <option disabled defaultValue={""}>
                      Select One
                    </option>
                    <option>Flowers</option>
                    <option>Trees</option>
                    <option>Shrubs</option>
                    <option>Herbs</option>
                    <option>Vegetables</option>
                  </select>
                  <label className="label">
                    {errors.plant_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.plant_type.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Features and Specifications")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Features and Specifications")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("features_and_specifications", {
                    required: {
                      value: true,
                      message: "Features and Specifications is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.features_and_specifications?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.features_and_specifications.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Electronic Items */}
          {category?.collection?.name === "Electronic Items" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Product Category")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Product Category")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("product_category", {
                    required: {
                      value: true,
                      message: "Product Category is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.product_category?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.product_category.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Brand and Model")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Brand and Model")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("brand_and_model", {
                    required: {
                      value: true,
                      message: "Brand and Model is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.brand_and_model?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.brand_and_model.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Features and Specifications")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Features and Specifications")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("features_and_specifications", {
                    required: {
                      value: true,
                      message: "Features and Specifications is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.features_and_specifications?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.features_and_specifications.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Material and Build")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Material and Build")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("material_and_build", {
                    required: {
                      value: true,
                      message: "Material and Build is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.material_and_build?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.material_and_build.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Exchange Items */}
          {category?.collection?.name === "Exchange Items" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Category and Type")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Category and Type")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("category_and_type", {
                    required: {
                      value: true,
                      message: "Category and Type is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.category_and_type?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.category_and_type.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Exchange Value")} *
                  </span>
                </label>
                <input
                  type="number"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("Exchange Value")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("exchange_value", {
                    required: {
                      value: true,
                      message: "Exchange Value is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.exchange_value?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.exchange_value.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* VIP Items */}
          {category?.collection?.name === "VIP Items" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("VIP Package")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("VIP Package")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("vip_package", {
                      required: {
                        value: true,
                        message: "VIP Package is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.vip_package?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.vip_package.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Benefits")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Benefits")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("benefits", {
                      required: {
                        value: true,
                        message: "Benefits is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.benefits?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.benefits.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Luxury Materials")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Luxury Materials")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("luxury_materials", {
                      required: {
                        value: true,
                        message: "Luxury Materials is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.luxury_materials?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.luxury_materials.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("VIP Experience")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("VIP Experience")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("vip_experience", {
                      required: {
                        value: true,
                        message: "VIP Experience is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.vip_experience?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.vip_experience.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Membership Duration")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Membership Duration")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("membership_duration", {
                    required: {
                      value: true,
                      message: "Membership Duration is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.membership_duration?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.membership_duration.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Special Technology */}
          {category?.collection?.name === "Special Technology" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Functionality")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Functionality")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("functionality", {
                      required: {
                        value: true,
                        message: "Functionality is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.functionality?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.functionality.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Benefits")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Benefits")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("benefits", {
                      required: {
                        value: true,
                        message: "benefits is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.benefits?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.benefits.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          )}
          {/* Construction Machines */}
          {category?.collection?.name === "Construction Machines" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Product Category")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Product Category")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("product_category", {
                      required: {
                        value: true,
                        message: "Product Category is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.product_category?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.product_category.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Equipment Type")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Equipment Type")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("equipment_type", {
                      required: {
                        value: true,
                        message: "Equipment Type is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.equipment_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.equipment_type.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Capacity and Power")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Capacity and Power")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("capacity_and_power", {
                      required: {
                        value: true,
                        message: "Capacity and Power is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.capacity_and_power?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.capacity_and_power.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Functionality")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Functionality")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("functionality", {
                      required: {
                        value: true,
                        message: "Functionality is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.functionality?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.functionality.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Advanced Features")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Advanced Features")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("advanced_features", {
                      required: {
                        value: true,
                        message: "Advanced Features is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.advanced_features?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.advanced_features.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Material and Build Quality")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Material and Build Quality")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("material_and_build_quality", {
                      required: {
                        value: true,
                        message: "Material and Build Quality is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.material_and_build_quality?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.material_and_build_quality.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          )}
          {/* Building Materials */}
          {category?.collection?.name === "Building Materials" && (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Product Category")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Product Category")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("product_category", {
                      required: {
                        value: true,
                        message: "Product Category is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.product_category?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.product_category.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Material Type")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Material Type")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("material_type", {
                      required: {
                        value: true,
                        message: "Material Type is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.material_type?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.material_type.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Usage and Application")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Usage and Application")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("usage_and_application", {
                      required: {
                        value: true,
                        message: "Usage and Application is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.usage_and_application?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.usage_and_application.message}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                      {t("Features and Properties")} *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Features and Properties")}
                    className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                    {...register("features_and_properties", {
                      required: {
                        value: true,
                        message: "Features and Properties is required",
                      },
                    })}
                  />
                  <label className="label">
                    {errors.features_and_properties?.type === "required" && (
                      <span className="label-text-alt text-red-600 text-sm">
                        {errors.features_and_properties.message}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                    {t("Safety Information")} *
                  </span>
                </label>
                <input
                  type="text"
                  placeholder={t("Safety Information")}
                  className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                  {...register("safety_information", {
                    required: {
                      value: true,
                      message: "Safety Information is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.safety_information?.type === "required" && (
                    <span className="label-text-alt text-red-600 text-sm">
                      {errors.safety_information.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
          )}
          {/* Bitcoin  */}
          {category?.collection?.name === "Bitcoin" && (
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  {t("Regulatory Information")} *
                </span>
              </label>
              <input
                type="text"
                placeholder={t("Regulatory Information")}
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("regulatory_information", {
                  required: {
                    value: true,
                    message: "Regulatory Information is required",
                  },
                })}
              />
              <label className="label">
                {errors.regulatory_information?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.regulatory_information.message}
                  </span>
                )}
              </label>
            </div>
          )}
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Description")} *
              </span>
              <span className="label-text-alt">{250}</span>
            </label>
            <textarea
              type="text"
              placeholder={t("Description")}
              className="textarea textarea-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none h-24"
              {...register("description", {
                required: {
                  value: true,
                  message: "Description is required",
                },
              })}
            />
            <label className="label">
              {errors.description?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.description.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full my-6">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Address")} *
              </span>
            </label>
            <Autocomplete
              apiKey={googleMapsApiKey}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder={`${t("Your")} ${t("Address")}`}
              onPlaceSelected={(place) => {
                const lat = place?.geometry?.location?.lat();
                const lng = place?.geometry?.location?.lng();
                setLat(lat);
                setLng(lng);
                setAddress(place.formatted_address);
              }}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  {t("Street 1")} *
                </span>
              </label>
              <input
                type="text"
                placeholder={t("Street 1")}
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("street1", {
                  required: {
                    value: true,
                    message: "Street 1 is required",
                  },
                })}
              />
              <label className="label">
                {errors.street1?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.street1.message}
                  </span>
                )}
              </label>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                  {t("Street 2")} *
                </span>
              </label>
              <input
                type="text"
                placeholder="Street 2"
                className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
                {...register("street2", {
                  required: {
                    value: true,
                    message: "Street 2 is required",
                  },
                })}
              />
              <label className="label">
                {errors.street2?.type === "required" && (
                  <span className="label-text-alt text-red-600 text-sm">
                    {errors.street2.message}
                  </span>
                )}
              </label>
            </div>
          </div>
          <hr className="my-8" />
          <div className="grid grid-cols-2 gap-3">
            <input
              type="button"
              onClick={() => navigate("/cat/select")}
              className="btn w-full bg-transparent border-black dark:border-white text-black dark:text-white hover:bg-transparent normal-case"
              value={t("Cancel")}
            />
            <input
              type="submit"
              className="btn w-full bg-black dark:bg-white text-white dark:text-black normal-case"
              value={t("Post Now")}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStatus;
