import axios from "axios";
import { toast } from "react-toastify";

export const uploadMultipleImages = async (imageFiles) => {
  let imageURLs = [];

  if (imageFiles?.length <= 0) return;
  for (let imageFile of imageFiles) {
    if (imageFile?.type !== "image/jpeg") {
      toast.error("Only JPEG or JPG Image files formats should be supported", {
        theme: "colored",
      });
      return;
    } else {
      let formdata = new FormData();
      formdata.append("file", imageFile);

      const imageURL = await axios
        .post(`${process.env.REACT_APP_ENDPOINT}/upload`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => data.data.files[0].url);
      imageURLs.push(imageURL);
    }
  }

  return imageURLs;
};

// export const uploadMultipleImages = async (imageFiles, pathname) => {
//   const newPathname = pathname.split("/").join("%2F");
//   let imageURLs = [];
//   if (imageFiles.length <= 0) return;
//   for (let imageFile of imageFiles) {
//     const imageRef = ref(
//       storage,
//       `${pathname}/${imageFile.name}_${Date.now()}`
//     );
//     const storageData = await uploadBytes(imageRef, imageFile);
//     const url = `https://firebasestorage.googleapis.com/v0/b/calendars-42ac0.appspot.com/o/${newPathname}%2F${storageData?.metadata?.name}?alt=media`;

//     imageURLs.push(url);
//   }
//   return imageURLs;
// };

export default uploadMultipleImages;
