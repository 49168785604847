import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import RemoveFollowerModal from "../../Modals/RemoveFollowerModal";
// import UnfollowModal from "../../Modals/UnfollowModal";

const ProfileFollowers = ({ followers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [showUnfollowModal, setShowUnfollowModal] = useState(false);
  // const [removeFollower, setRemoveFollower] = useState(null);
  return (
    <div>
      {followers?.length > 0 ? (
        <div className="grid grid-cols-3 xs:grid-cols-4 md:grid-cols-5 gap-3 sm:gap-5">
          {followers?.map((follower, index) => (
            <div
              key={index}
              className="min-h-[90px] sm:min-h-[172px] w-full sm:w-[130px] rounded-xl bg-[#F8F8F8] dark:bg-secondary shadow-md flex flex-col justify-evenly items-center mx-auto"
            >
              <img
                src={
                  follower.image ||
                  "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                }
                alt="follower"
                className="h-[45px] w-[45px] sm:h-[90px] sm:w-[90px] mx-auto object-cover rounded-full cursor-pointer"
                onClick={() => navigate(`/profile/${follower?._id}`)}
              />
              <p className="text-xs font-[inter] px-[2px] xs:p-0">
                {follower.name}
              </p>
              {/* {userP?._id === localStorage.getItem("user-id") && (
                <button
                  onClick={() => {
                    setShowUnfollowModal(true);
                    setRemoveFollower(follower);
                  }}
                  className="bg-[#EAEAEA] dark:bg-[#3A3A3A] dark:text-white h-[13px] w-[50px] sm:h-[26px] sm:w-[102px] rounded text-[6px] sm:text-xs font-[inter]"
                >
                  Remove
                </button>
              )}
              {follower?._id === localStorage.getItem("user-id") && (
                <button
                  onClick={() => {
                    setShowUnfollowModal(true);
                    setRemoveFollower(follower);
                  }}
                  className="bg-[#EAEAEA] dark:bg-[#3A3A3A] dark:text-white h-[13px] w-[50px] sm:h-[26px] sm:w-[102px] rounded text-[6px] sm:text-xs font-[inter]"
                >
                  Unfollow
                </button>
              )} */}
            </div>
          ))}
        </div>
      ) : (
        <div className="h-96 flex justify-center items-center">
          <p className="font-[inter] text-xs xs:text-sm sm:text-xl">
            {t("You don't have any followers right now")}
          </p>
        </div>
      )}
      {/* <RemoveFollowerModal
        showUnfollowModal={showUnfollowModal}
        setShowUnfollowModal={setShowUnfollowModal}
        removeFollower={removeFollower}
        setRemoveFollower={setRemoveFollower}
        setLoading={setLoading}
      /> */}
      {/* <UnfollowModal
        showUnfollowModal={showUnfollowModal}
        setShowUnfollowModal={setShowUnfollowModal}
        user={user}
        removeFollower={removeFollower}
        setRemoveFollower={setRemoveFollower}
      /> */}
    </div>
  );
};

export default ProfileFollowers;
