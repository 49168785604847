import React from "react";
import axios from "axios";
import { AiOutlinePause } from "react-icons/ai";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const AllCategories = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const url = `${process.env.REACT_APP_ENDPOINT}/collection/main`;
  const { data: categories } = useQuery([`categories`], () =>
    axios.get(url).then((data) => {
      return data.data;
    })
  );

  const handleNavigate = (category) => {
    category?.subcategoryCount > 0
      ? navigate(
          `/cat/${category?.collection?.name
            .toLowerCase()
            .split(" ")
            .join("-")}/sub`,
          { state: { category } }
        )
      : navigate(
          `/cat/${category?.collection?.name
            .toLowerCase()
            .split(" ")
            .join("-")}`,
          { state: { category } }
        );
    localStorage.setItem("category", JSON.stringify(category.collection));
    ReactGA.event({
      category: "Category",
      action: "Click",
      label: `Category: ${category?.collection?.name}, Id: ${category?.collection?._id} is clicked`,
    });
  };

  return (
    <div className="space-y-4 xs:space-y-7 text-[#444444] dark:text-white">
      <div className="flex items-center gap-2 lg:gap-4 font-[inter] text-xl">
        <AiOutlinePause className="rotate-90 w-6 h-6" />
        <p className="text-sm lg:text-xl font-semibold">
          {t("All")} {t("Categories")}
        </p>
        <span className="text-[#CCD1DC] dark:text-[#BABABA] text-sm lg:text-xl">
          |
        </span>
        <Link
          to="/cat/all"
          className="flex items-center gap-1 lg:gap-3 text-xs lg:text-base dark:text-[#BABABA]"
        >
          {t("See All")}
          <HiOutlineArrowRight />
        </Link>
      </div>

      {/* Mobile View */}
      <div className="grid sm:hidden grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-3 xs:gap-5">
        {categories?.slice(0, 9)?.map((category, index) => (
          <button
            key={index}
            onClick={() => handleNavigate(category)}
            className={`relative rounded-xl shadow-xl bg-white dark:bg-secondary py-2 border-2 ${
              category?.collection?.planType === "gold"
                ? "border-[#F8C73C]"
                : category?.collection?.planType === "platinum"
                ? "border-[#4485C2]"
                : "border-none"
            }`}
          >
            {category?.collection?.planType !== "free" && (
              <p
                className={`absolute top-[-2px] left-0 right-0 mx-auto h-[18px] px-1 rounded rounded-t-none text-sm font-semibold text-center text-white flex justify-center items-center ${
                  category?.collection?.planType === "gold"
                    ? "gold-plan w-7"
                    : "platinum-plan w-11"
                }`}
              >
                <span className="text-black text-[8px] md:text-xs">
                  {category?.collection?.planType === "gold"
                    ? "GOLD"
                    : "PLATINUM"}
                </span>
              </p>
            )}
            <img
              src={category?.collection?.logo}
              alt="category"
              className="w-[50px] h-[50px] mx-auto"
            />
            <p className="text-center text-[10px] font-[poppins] font-medium mt-1">
              {category?.collection?.name}
            </p>
          </button>
        ))}
      </div>
      {/* Larger View */}
      <div className="hidden sm:grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-3 xs:gap-5">
        {categories?.slice(0, 8)?.map((category, index) => (
          <button
            key={index}
            onClick={() => handleNavigate(category)}
            className={`relative rounded-xl md:rounded-2xl shadow-xl bg-white dark:bg-secondary space-y-2 py-3 border-2 ${
              category?.collection?.planType === "gold"
                ? "border-[#F8C73C]"
                : category?.collection?.planType === "platinum"
                ? "border-[#4485C2]"
                : "border-none"
            }`}
          >
            {category?.collection?.planType !== "free" && (
              <p
                className={`absolute top-[-2px] left-0 right-0 mx-auto h-[18px] lg:h-[25px] px-1 rounded rounded-t-none text-sm font-semibold text-center text-white flex justify-center items-center  ${
                  category?.collection?.planType === "gold"
                    ? "gold-plan w-7 md:w-10"
                    : "platinum-plan w-11 md:w-[70px]"
                }`}
              >
                <span className="text-black text-[8px] md:text-xs">
                  {category?.collection?.planType === "gold"
                    ? "GOLD"
                    : "PLATINUM"}
                </span>
              </p>
            )}

            <img
              src={category?.collection?.logo}
              alt="category"
              className="rounded-xl w-[35px] sm:w-[50px] lg:w-[70px] mx-auto"
            />

            <div className="text-center text-[10px] sm:text-[12px] font-[poppins] font-medium">
              {category?.collection?.name}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AllCategories;
