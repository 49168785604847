import React from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const ItemCard = ({ items }) => {
  const navigate = useNavigate();

  const handleNavigate = (item) => {
    localStorage.getItem("user-id")
      ? navigate(`/item/${item?._id}`)
      : item?.planType === "free"
      ? navigate(`/item/${item?._id}`)
      : navigate(`/login`);
    ReactGA.event({
      category: "Item",
      action: "Click",
      label: `Item: ${item?.itemName}, Id: ${item?._id} is clicked`,
    });
  };
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3 xs:gap-5">
      {items?.map((item, index) => (
        <div
          key={index}
          onClick={() => handleNavigate(item)}
          className="card flex flex-col justify-between items-center py-3 xs:py-6 space-y-2 bg-white dark:bg-secondary shadow-md rounded-xl sm:rounded-[20px] h-auto w-full sm:h-56 sm:w-48 mx-auto cursor-pointer"
        >
          <img
            src={item?.imageGallery?.[0]}
            alt="items"
            className="h-[100px] w-[100px] sm:h-[123px] sm:w-[123px] object-cover rounded-lg"
          />

          <div
            data-tip={item?.itemName}
            className="tooltip tooltip-secondary text-center text-[10px] xs:text-sm font-[poppins] px-[2px]"
          >
            <p className="font-semibold">
              {item?.itemName.length > 20
                ? item?.itemName.slice(0, 20) + "..."
                : item?.itemName}
            </p>
            <p className="font-[inter] font-medium flex justify-center items-center gap-[2px] xs:gap-2">
              <span className="text-[10px] xs:text-sm">€{item?.discPrice}</span>
              <span className="text-[#9D9D9D] text-[8px] xs:text-xs line-through">
                €{item?.price}
              </span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemCard;
