import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../Assets/img/logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer p-8 md:px-16 bg-secondary text-white text-xs sm:text-base grid grid-cols-3 sm:grid-cols-4">
      <div className="my-auto">
        <a href="/">
          <img src={Logo} alt="logo" className="w-20 xs:w-auto" />
        </a>
      </div>

      <div>
        <span className="footer-title">Company</span>
        <Link to="/about" className="link link-hover">
          {t("About Us")}
        </Link>
        <Link to="/contact" className="link link-hover">
          {t("Contact Us")}
        </Link>
      </div>
      <div>
        <span className="footer-title">Legal</span>
        <Link to="/terms" className="link link-hover">
          {t("Terms Of Use")}
        </Link>

        <Link to="/privacy" className="link link-hover">
          {t("Privacy Policy")}
        </Link>
        <Link to="/cookie" className="link link-hover">
          {t("Cookie Policy")}
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
