import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SocialLogin from "./SocialLogin";
import { toast } from "react-toastify";
import auth from "../../firebase.init";
import {
  useCreateUserWithEmailAndPassword,
  useUpdateProfile,
} from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import axios from "axios";
import { RiEyeFill, RiEyeCloseFill } from "react-icons/ri";
import Loading from "../Shared/Loading";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const Signup = () => {
  const { t } = useTranslation();
  const [agree, setAgree] = useState(false);
  const [seePassowrd, setSeePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signUpError, setSignUpError] = useState("");
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  //Creating User with Email And Password
  const [createUserWithEmailAndPassword] = useCreateUserWithEmailAndPassword(
    auth,
    { sendEmailVerification: true }
  );

  // Updating Profile
  const [updateProfile, updating] = useUpdateProfile(auth);

  // let signupError;
  // if (error) {
  //   if (error?.message === "Firebase: Error (auth/email-already-in-use).") {
  //     signupError = (
  //       <p className="text-[red] my-2 text-base font-[inter] font-semibold">
  //         User already exists, please Sign in.
  //       </p>
  //     );
  //   } else {
  //     signupError = (
  //       <p className="text-[red] my-2 text-base font-[inter] font-semibold">
  //         User is not registered, please Sign up to register
  //       </p>
  //     );
  //   }
  // }
  // if (error || updateError) {
  //   signupError = (
  //     <p className="text-[red] my-2 text-base font-[inter] font-semibold">
  //       {error?.message}
  //     </p>
  //   );
  // }
  const onSubmit = async (data) => {
    setLoading(true);
    const email = data.email;
    const name = data.name;
    const password = data.password;
    await createUserWithEmailAndPassword(email, password);
    await updateProfile({ displayName: name });

    const userInfo = { email, password };
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    // if (!error) {
    await axios
      .post(`${process.env.REACT_APP_ENDPOINT}/user/signup`, userInfo, config)
      .then((data) => {
        if (data.status === 200) {
          ReactGA.event({
            category: "Signup",
            action: "Click",
            label: "Signup BUtton Clicked",
          });
          navigate("/add-details", { state: "Create" });
          localStorage.setItem("user-id", data?.data?.id);
          localStorage.setItem("token", data?.data?.token);
          localStorage.setItem("user-planType", data?.data?.planType);
          navigate("/add-details");
          toast.success(`Successfully Signed in as ${email}`, {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          setSignUpError(error.response?.data?.error);
        }
      })
      .finally(() => setLoading(false));
  };
  // };

  // if (user) {
  //   navigate("/add-details");
  //   localStorage.setItem("firebase-user-id", user?.user?.uid);
  // }

  if (loading || updating) {
    return <Loading />;
  }
  return (
    <div className="grid grid-cols-1 xs:grid-cols-2 bg-white">
      <div className="order-2 text-white font-[inter] italic text-[5.27vw] hidden xs:flex justify-center items-center h-[calc(100vh-67px)] w-full bg-black p-5 md:p-10">
        VIPWare
      </div>
      <div className="order-1 bg-white h-full text-black text-xl font-[roboto] flex justify-center items-center px-3 py-5 md:px-10 md:py-6">
        <div className="w-full xs:w-auto max-w-lg space-y-4">
          <p>{t("Sign up with")}</p>
          <SocialLogin />
          <div className="divider">{t("or")}</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-5">
              <div>
                <input
                  type="text"
                  placeholder={t("Name")}
                  className="input input-bordered w-full max-w-lg bg-[#F6F6F6]"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                  })}
                />
                <label className="label">
                  {errors.name?.type === "required" && (
                    <span className="label-text-alt text-[red]">
                      {errors.name.message}
                    </span>
                  )}
                </label>
              </div>
              <div>
                <input
                  type="text"
                  placeholder={t("Email")}
                  className="input input-bordered w-full max-w-lg bg-[#F6F6F6]"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                      message: "Provide A Valid Email",
                    },
                  })}
                />
                <label className="label">
                  {errors.email?.type === "required" && (
                    <span className="label-text-alt text-[red]">
                      {errors.email.message}
                    </span>
                  )}
                  {errors.email?.type === "pattern" && (
                    <span className="label-text-alt text-[red]">
                      {errors.email.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="relative">
                <input
                  type={seePassowrd ? "text" : "password"}
                  placeholder={t("Password")}
                  className="input input-bordered w-full max-w-lg bg-[#F6F6F6]"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password is required",
                    },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                      message:
                        "Must Contain 8 Characters including 1 Uppercase & 1 Lowercase Letter",
                    },
                  })}
                />
                <button
                  type="button"
                  onClick={() => setSeePassword(!seePassowrd)}
                  className="absolute right-5 top-[14px]"
                >
                  {seePassowrd ? (
                    <RiEyeCloseFill className="text-gray-500" />
                  ) : (
                    <RiEyeFill className="text-gray-500" />
                  )}
                </button>
                <label className="label">
                  {errors.password?.type === "required" && (
                    <span className="label-text-alt text-[red]">
                      {errors.password.message}
                    </span>
                  )}
                  {errors.password?.type === "pattern" && (
                    <span className="label-text-alt text-[red]">
                      {errors.password.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            {/* <div className="form-control">
              <label className="cursor-pointer label justify-start gap-3">
                <input
                  onClick={() => setAgree(!agree)}
                  type="checkbox"
                  className="checkbox w-4 h-4 rounded-sm checkbox-accent"
                />
                <span
                  className={`${
                    agree ? "opacity-100" : "opacity-50"
                  } label-text text-sm`}
                >
                  {t("Remember me")}
                </span>
              </label>
            </div> */}
            {signUpError && (
              <p className="text-[red] my-2 text-base font-[inter] font-semibold">
                {signUpError}
              </p>
            )}
            <input
              type="submit"
              className="btn btn-primary w-full text-white text-base md:text-xl normal-case"
              value={t("Sign up")}
            />
          </form>
          <div className="flex gap-2 justify-center text-sm md:text-lg">
            <p>{t("Already have an account?")}</p>
            <Link
              className="decoration-transparent text-accent font-semibold"
              to="/login"
            >
              {t("Log in")}
            </Link>
          </div>
        </div>
      </div>

      {/* <div className="m-auto hidden lg:block">
        <img src={Logo} alt="logo" className="h-12" />
      </div> */}
    </div>
  );
};

export default Signup;
