import React, { useEffect, useState } from "react";
import { TbShieldLockFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import SubscriptionModal from "../Modals/SubscriptionModal";
import { useTranslation } from "react-i18next";
const AccessItemDiv = ({ setAccessItemDiv }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  useEffect(() => window.scrollTo(0, 0));

  return (
    <div className="App min-h-screen xl:min-h-0 xl:h-[calc(100vh-96px)]">
      <div className="bg-white dark:bg-[#121212] border border-gray-200 dark:border-[#121212] max-w-[780px] rounded-xl p-3 sm:p-[30px] mx-auto space-y-10">
        <div className="bg-secondary dark:bg-white h-20 w-20 md:h-40 md:w-40 rounded-2xl flex justify-center items-center mx-auto">
          <TbShieldLockFilled className="h-12 w-12 md:h-20 md:w-20 text-white dark:text-secondary" />
        </div>
        <p className="font-[inter] text-sm md:text-2xl font-semibold text-center xl:px-48">
          {t("You don't have access to this premium content")}
        </p>
        <div className="flex justify-center gap-3 sm:gap-5">
          <button
            onClick={() => {
              navigate(-1);
              // setAccessItemDiv(false);
            }}
            className="btn btn-ghost px-2 xl:px-4 xl:font-bold text-xs w-32 md:w-44 border border-secondary dark:border-white"
          >
            {t("Back to Home")}
          </button>
          <button
            className="btn btn-warning px-2 xl:px-4 xl:font-bold text-xs w-32 md:w-44"
            onClick={() =>
              localStorage.getItem("user-id")
                ? setShowSubscriptionModal(true)
                : navigate("/login")
            }
          >
            {t("Subscribe")}
          </button>
        </div>
      </div>
      <SubscriptionModal
        showSubscriptionModal={showSubscriptionModal}
        setShowSubscriptionModal={setShowSubscriptionModal}
      />
    </div>
  );
};

export default AccessItemDiv;
