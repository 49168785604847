import axios from "axios";
import { toast } from "react-toastify";

export const uploadImage = async (image) => {
  if (image?.type !== "image/jpeg") {
    toast.error("Only JPEG or JPG Image files formats should be supported", {
      theme: "colored",
    });
  } else {
    let formdata = new FormData();
    formdata.append("file", image);

    const imageURL = await axios
      .post(`${process.env.REACT_APP_ENDPOINT}/upload`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => data.data.files[0].url);

    return imageURL;
  }
};
