import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const ProfilePosts = ({ posts }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      {posts?.length > 0 ? (
        <div className="grid grid-cols-3 md:grid-cols-4 gap-2 sm:gap-5">
          {posts?.map((post, index) => (
            <button
              key={index}
              onClick={() => {
                navigate(`/item/${post?._id}`);
                ReactGA.event({
                  category: "Item",
                  action: "Click",
                  label: `Item: ${post?.itemName}, Id: ${post?._id} is clicked`,
                });
              }}
              className="h-[100px] w-[100px] sm:h-[165px] sm:w-[165px] rounded-lg sm:rounded-xl bg-[#F8F8F8] dark:bg-secondary border border-gray-200 dark:border-[#363636] shadow-md mx-auto"
            >
              <img
                src={post?.imageGallery[0]}
                alt="post"
                className="h-[70px] w-[70px] xs:h-[80px] xs:w-[80px] sm:h-[120px] sm:w-[120px] mx-auto object-cover rounded"
              />
            </button>
          ))}
        </div>
      ) : (
        <div className="h-96 flex justify-center items-center">
          <p className="font-[inter] text-xs xs:text-sm sm:text-xl">
            {t("You don't have any posts right now")}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProfilePosts;
