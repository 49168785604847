import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { useTranslation } from "react-i18next";

const DeleteStatusModal = ({
  statusDetails,
  setStatusDetails,
  setUserIdOfStatus,
  setDeletedStatus,
  setLoading,
}) => {
  const { t } = useTranslation();
  const handleDeleteStatus = () => {
    const url = `${process.env.REACT_APP_ENDPOINT}/status/${statusDetails?.user?._id}/${statusDetails?._id}`;
    const config = {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    };

    axios
      .delete(url, config)
      .then((data) => {
        setLoading(true);
        if (data.status === 200) {
          toast.success(`Status is Deleted Successfully`, {
            theme: "colored",
          });
          setStatusDetails(null);
          setUserIdOfStatus(null);
          setDeletedStatus(true);
          // navigate(-1);
        } else {
          toast.error(`Can't Delete this Status`, { theme: "colored" });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {statusDetails ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm"
            style={{ zIndex: 10 }}
          >
            <div className="relative rounded-lg bg-white dark:bg-secondary w-[300px] xs:w-[400px] bottom-16 p-8 space-y-6 shadow-md">
              <div className="flex justify-between">
                <p className="text-black dark:text-white font-[inter] font-semibold">
                  {t("Delete")} {t("Status")}
                </p>

                <button
                  className="text-white bg-slate-800 dark:bg-white h-6 w-6 rounded-md flex justify-center items-center"
                  onClick={() => setStatusDetails(null)}
                >
                  <RxCross1 className="text-white dark:text-black" />
                </button>
              </div>
              <p className="text-black dark:text-white font-[inter]">
                {t("Do you want to delete this Status?")}
              </p>
              <div className="flex items-center justify-end">
                <button
                  className="btn btn-sm btn-outline btn-error font-bold uppercase text-sm rounded shadow hover:shadow-lg"
                  type="button"
                  onClick={handleDeleteStatus}
                >
                  {t("Confirm")}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DeleteStatusModal;
