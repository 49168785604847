import React, { useEffect } from "react";
// import homeBanner from "../../Assets/img/banner/home-banner.png";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
import ReactGA from "react-ga4";

const HomeBanner = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  const bannerURL = `${process.env.REACT_APP_ENDPOINT}/carousel/all`;
  const { data: banners } = useQuery(["banners"], () =>
    axios.get(bannerURL).then((data) => {
      const bannerArray = [];
      data.data.map((banner) =>
        banner.images.map((image) =>
          bannerArray.push({
            image: image,
            redirect_link: banner.redirect_link,
          })
        )
      );
      return bannerArray;
    })
  );

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="w-full bg-secondary rounded-[20px]">
      <div className="slider-container">
        <Slider {...settings}>
          {banners &&
            banners?.map((banner, index) => (
              <div key={index}>
                <img
                  src={banner?.image}
                  alt="Banner"
                  className="mx-auto cursor-pointer rounded-[20px]"
                  onClick={() => {
                    ReactGA.event({
                      category: "Banner",
                      action: "Click",
                      label: `Banner id: ${banner?._id} is clicked`,
                    });
                    window.location.href = banner?.redirect_link;
                  }}
                />
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default HomeBanner;
