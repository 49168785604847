import React, { useEffect, useState } from "react";
import "./ProfileDetails.css";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { MdEdit } from "react-icons/md";
import ProfilePosts from "./MyProfile.js/ProfilePosts";
import ProfileFollowers from "./MyProfile.js/ProfileFollowers";
import ProfileFollowing from "./MyProfile.js/ProfileFollowing";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Shared/Loading";
import LinkShareModal from "../Modals/LinkShareModal";
import RemoveFollowerModal from "../Modals/RemoveFollowerModal";
import SubscriptionModal from "../Modals/SubscriptionModal";
import { IoShareSocialOutline } from "react-icons/io5";
import { TbCircleDashed } from "react-icons/tb";
import { BiPlus } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const ProfileDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [buttonName, setButtonName] = useState("posts");
  const [loading, setLoading] = useState(false);
  const [followed, setFollowed] = useState(false);
  const userId = localStorage.getItem("user-id");
  const [showShareModal, setShowShareModal] = useState(false);
  const [showUnfollowModal, setShowUnfollowModal] = useState(false);
  const [removeFollower, setRemoveFollower] = useState(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [billingURL, setBillingURL] = useState(null);
  const [dashboardLink, setDashboardLink] = useState(null);

  showShareModal ||
  showUnfollowModal ||
  showSubscriptionModal ||
  removeFollower !== null
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const profileDetailsUrl = `${process.env.REACT_APP_ENDPOINT}/user/profile/${id}`;
  const {
    data: profileDetails,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(
    [`profileDetails`, id],
    () =>
      axios.get(profileDetailsUrl).then((data) => {
        if (id === userId) {
          localStorage.setItem("user-planType", data?.data.user?.planType);
          if (data?.data?.user?.stripeAccID) {
            const stripeId = data?.data?.user?.stripeAccID;
            const getStripeDashBoardURL = `${process.env.REACT_APP_ENDPOINT}/user/stripe-login-link/${stripeId}`;
            stripeId &&
              axios
                .get(getStripeDashBoardURL)
                .then((data) => setDashboardLink(data.data.url));
          }
          // if (data?.data.user?.image) {
          //   localStorage.setItem("user-image", data?.data.user?.image);
          // }
        }
        return data.data;
      }),
    { enabled: id !== null ? true : false }
  );

  const followingListUrl = `${process.env.REACT_APP_ENDPOINT}/follower/following/${userId}`;

  axios.get(followingListUrl).then((data) => {
    if (data.data?.find((following) => following?._id === id)) {
      setFollowed(true);
    } else {
      setFollowed(false);
    }
  });

  const user = profileDetails?.user;
  const posts = profileDetails?.posts;
  const followers = profileDetails?.followers;
  const following = profileDetails?.following;

  // const handleFollow = () => {
  //   const url = `${process.env.REACT_APP_ENDPOINT}/follower/${userId}`;

  //   const follower = { follower: id };
  //   setLoading(true);
  //   axios
  //     .post(url, follower)
  //     .then((data) => {
  //       if (data.status === 200) {
  //         toast.success(`You are now following ${user?.name}`, {
  //           theme: "colored",
  //         });
  //       }
  //     })
  //     .finally(() => setLoading(false));
  // };
  // const handleUnfollow = () => {
  //   const removeFollowingUrl = `${process.env.REACT_APP_ENDPOINT}/follower/${userId}/remove`;
  //   const config = {
  //     headers: {
  //       "Content-type": "application/json",
  //     },
  //   };
  //   const follower = { follower: id };
  //   setLoading(true);
  //   axios
  //     .delete(removeFollowingUrl, follower, config)
  //     .then((data) => {
  //       if (data.status === 200) {
  //         toast.error(`You have unfollowed ${user?.name}`, {
  //           theme: "colored",
  //         });
  //       }
  //     })
  //     .catch((er) => console.log(er))
  //     .finally(() => setLoading(false));
  // };

  const handleToggleFollow = () => {
    const url = `${process.env.REACT_APP_ENDPOINT}/follower/${userId}/toggle`;
    const follower = { follower: id };
    setLoading(true);
    axios
      .post(url, follower)
      .then((data) => {
        if (data.status === 200) {
          if (data.data.msg === "added") {
            toast.success(`You are now following ${user?.name}`, {
              theme: "colored",
            });
          } else {
            toast.success(`You have unfollowed ${user?.name}`, {
              theme: "colored",
            });
          }
          refetch();
        }
      })
      .finally(() => setLoading(false));
  };

  // Billing url code
  useEffect(() => {
    if (localStorage.getItem("user-planType") !== "free") {
      axios
        .get(`${process.env.REACT_APP_ENDPOINT}/sub/billing-portal/${userId}`)
        .then((data) => {
          if (data?.status === 200) {
            setBillingURL(data?.data);
          }
        });
    }
  }, [userId]);

  if (isLoading || !isSuccess || loading) {
    return <Loading />;
  }

  return (
    <div className="App min-h-screen max-w-5xl mx-auto">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[rgb(226,226,226)]">
          {id === userId ? `${t("My")} ${t("Profile")}` : user?.name}
        </span>
      </p>
      <div className="bg-white dark:bg-[#121212] border border-gray-200 dark:border-[#121212] max-w-[780px] min-h-[400px] sm:min-h-[620px] rounded-xl p-3 sm:p-[30px] sm:pt-14 relative">
        <div className="flex flex-row gap-2 xs:gap-6 items-center">
          <div className="avatar">
            <div
              onClick={() => {
                userId &&
                  profileDetails?.status?.length > 0 &&
                  navigate(`/status`, { state: id });
                ReactGA.event({
                  category: "Status",
                  action: "Click",
                  label: `Status clicked`,
                });
              }}
              className={`w-[70px] xs:w-20 sm:w-28 rounded-full ${
                userId &&
                profileDetails?.status?.length > 0 &&
                "ring-4 ring-[#F6AB0E] ring-offset-[white] dark:ring-offset-secondary ring-offset-2 cursor-pointer"
              }`}
            >
              <img
                src={
                  user?.image
                    ? user?.image
                    : "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                }
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col font-[inter] space-y-1 xs:space-y-2">
            <div className="flex gap-2 xs:gap-5 items-center xs:mt-2">
              <p className="text-base xs:text-base sm:text-2xl font-semibold">
                {user?.name}
              </p>
              <IoShareSocialOutline
                onClick={() => setShowShareModal(true)}
                className="h-3 w-3 xs:h-5 xs:w-5 cursor-pointer"
              />
            </div>
            {id === localStorage.getItem("user-id") ? (
              <div className="flex-col space-y-1 xs:space-y-2">
                <div className="flex gap-2 items-center">
                  <div
                    className={`h-5 min-w-[40px] rounded-[4px] px-1 flex items-center justify-center ${
                      user?.planType === "gold"
                        ? "gold-plan"
                        : user?.planType === "platinum"
                        ? "platinum-plan"
                        : "free-plan"
                    }`}
                  >
                    <span className="text-xs xs:text-sm sm:text-[15px] font-semibold text-center text-white">
                      {user?.planType === "gold"
                        ? "Gold"
                        : user?.planType === "platinum"
                        ? "Platinum"
                        : "Free"}
                    </span>
                  </div>
                  <div>
                    {user?.planType === "free" ? (
                      <span
                        className="text-[#9D9D9D] hover:text-[#454545] dark:hover:text-[#c5c4c4] text-xs xs:text-sm sm:text-base underline cursor-pointer"
                        onClick={() => setShowSubscriptionModal(true)}
                      >
                        {t("Upgrade Now")}
                      </span>
                    ) : (
                      <Link
                        to={billingURL && billingURL}
                        className="text-[#9D9D9D] hover:text-[#454545] dark:hover:text-[#c5c4c4] text-xs xs:text-sm sm:text-base underline cursor-pointer"
                      >
                        {t("Upgrade Plan")}
                      </Link>
                    )}
                  </div>
                </div>
                {dashboardLink && (
                  <div>
                    <Link
                      to={dashboardLink}
                      className="text-[#9D9D9D] hover:text-[#454545] dark:hover:text-[#c5c4c4] text-xs xs:text-sm sm:text-base underline cursor-pointer"
                    >
                      {t("Go to Seller Dashboard")}
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              // Mobile View
              <div className="sm:hidden">
                {followed ? (
                  <button
                    onClick={
                      localStorage.getItem("user-id")
                        ? () => {
                            setShowUnfollowModal(true);
                            setRemoveFollower(user);
                          }
                        : () => navigate("/login")
                    }
                    className="min-h-[20px] w-[120px] border border-[#EAEAEA] dark:border-[#505050] text-black dark:text-white font-[inter] text-[10px] rounded px-1"
                  >
                    {t("Remove from Circle")}
                  </button>
                ) : (
                  <button
                    onClick={
                      localStorage.getItem("user-id")
                        ? handleToggleFollow
                        : () => navigate("/login")
                    }
                    className="min-h-[20px] w-[110px] border border-[#EAEAEA] dark:border-[#505050] text-black dark:text-white font-[inter] text-[10px] rounded px-1 flex items-center justify-center gap-1"
                  >
                    <BiPlus className="h-3 w-3" /> {t("Add to my Circle")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        {/* larger view */}
        <div className="hidden sm:block">
          {id === localStorage.getItem("user-id") ? (
            <div className="absolute top-3 right-3 sm:top-6 sm:right-7 flex flex-col-reverse xs:flex-row gap-2 sm:gap-6">
              <div
                onClick={() =>
                  navigate("/cat/select", { state: "/add-status" })
                }
                className="text-[#444444] dark:text-white bg-[#F6F6F6] dark:bg-[#2A2A2A] h-6 w-24 sm:h-8 sm:w-[200px] rounded-lg flex justify-center items-center gap-1 sm:gap-2 cursor-pointer"
              >
                <TbCircleDashed className="h-4 w-4 sm:h-5 sm:w-5" />
                <span className="font-[inter] text-[8px] sm:text-base">
                  {t("Add Status")}
                </span>
              </div>
              <label
                onClick={() => navigate("/add-details", { state: "Edit" })}
                className="cursor-pointer text-[#444444] dark:text-white bg-[#F6F6F6] dark:bg-[#2A2A2A] h-6 w-6 sm:h-8 sm:w-8 rounded-lg flex justify-center items-center ml-auto"
              >
                <MdEdit className="h-3 w-3 sm:h-5 sm:w-5" />
              </label>
            </div>
          ) : followed ? (
            <button
              onClick={
                localStorage.getItem("user-id")
                  ? () => {
                      setShowUnfollowModal(true);
                      setRemoveFollower(user);
                    }
                  : () => navigate("/login")
              }
              className="absolute top-3 right-3 sm:top-10 sm:right-7 min-h-[20px] xs:min-h-6 sm:min-h-8 min-w-[11vw] bg-[#EAEAEA] dark:bg-[#505050] text-[#767676] dark:text-white font-[inter] text-[8px] sm:text-base rounded px-2 sm:px-3"
            >
              {t("Remove from Circle")}
            </button>
          ) : (
            <button
              onClick={
                localStorage.getItem("user-id")
                  ? handleToggleFollow
                  : () => navigate("/login")
              }
              className="absolute top-3 right-3 sm:top-10 sm:right-7 min-h-[20px] xs:min-h-6 sm:min-h-8 w-[154px] bg-[#EAEAEA] dark:bg-[#505050] text-[#767676] dark:text-white font-[inter] text-[8px] sm:text-base rounded px-2 sm:px-3"
            >
              {t("Add to my Circle")}
            </button>
          )}
        </div>

        {/* Mobile View */}
        {id === localStorage.getItem("user-id") && (
          <div className="sm:hidden grid grid-cols-2 gap-4 my-3 ">
            <button
              onClick={() => navigate("/add-details", { state: "Edit" })}
              className="h-7 text-[12px] text-[#444444] dark:text-white font-semibold border-2 border-[#F6F6F6] dark:border-[#2A2A2A] rounded-md flex justify-center items-center gap-2 cursor-pointer"
            >
              <MdEdit className="w-4 h-4" /> {t("Edit Profile")}
            </button>
            <button
              onClick={() => navigate("/cat/select", { state: "/add-status" })}
              className="h-7 text-[12px] text-[#444444] dark:text-white font-semibold border-2 border-[#F6F6F6] dark:border-[#2A2A2A] rounded-md flex justify-center items-center gap-2 cursor-pointer"
            >
              <TbCircleDashed className="h-4 w-4" /> {t("Add Status")}
            </button>
          </div>
        )}
        <div className="my-5 xs:my-8 grid grid-cols-3 sm:flex sm:gap-2 font-[inter] font-semibold">
          <button
            onClick={() => setButtonName("posts")}
            className={`pb-1 sm:pb-2 px-[2px] sm:px-3 border-b-2 text-[12px] xs:text-xs sm:text-base ${
              buttonName === "posts"
                ? "border-b-black dark:border-b-white"
                : "text-[#767676] dark:text-[#606060] border-b-white dark:border-b-[#121212]"
            }`}
          >
            {`${profileDetails?.postCount} ${
              profileDetails?.postCount <= 1 ? t("POST") : t("POSTS")
            }`}
          </button>
          <button
            onClick={() => setButtonName("followers")}
            className={`pb-1 sm:pb-2 px-[2px] sm:px-3 border-b-2 text-[12px] xs:text-xs sm:text-base ${
              buttonName === "followers"
                ? "border-b-black dark:border-b-white"
                : "text-[#767676] dark:text-[#606060] border-b-white dark:border-b-[#121212]"
            }`}
          >
            {`${profileDetails?.followerCount} ${
              profileDetails?.followerCount <= 1
                ? t("FOLLOWER")
                : t("FOLLOWERS")
            }`}
          </button>
          <button
            onClick={() => setButtonName("following")}
            className={`pb-1 sm:pb-2 px-[2px] sm:px-3 border-b-2 text-[12px] xs:text-xs sm:text-base ${
              buttonName === "following"
                ? "border-b-black dark:border-b-white"
                : "text-[#767676] dark:text-[#606060] border-b-white dark:border-b-[#121212]"
            }`}
          >
            {`${profileDetails?.followingCount} ${t("FOLLOWING")}`}
          </button>
        </div>
        {buttonName === "posts" && <ProfilePosts posts={posts} />}
        {buttonName === "followers" && (
          <ProfileFollowers followers={followers} />
        )}
        {buttonName === "following" && (
          <ProfileFollowing following={following} />
        )}
        <LinkShareModal
          showShareModal={showShareModal}
          setShowShareModal={setShowShareModal}
          name={"this profile"}
        />
        <RemoveFollowerModal
          showUnfollowModal={showUnfollowModal}
          setShowUnfollowModal={setShowUnfollowModal}
          removeFollower={removeFollower}
          setRemoveFollower={setRemoveFollower}
          setLoading={setLoading}
        />
        <SubscriptionModal
          user={user}
          showSubscriptionModal={showSubscriptionModal}
          setShowSubscriptionModal={setShowSubscriptionModal}
        />
        {/* <StatusModal
          userIdOfStatus={showStatusModal}
          setShowStatusModal={setShowStatusModal}
          setDeletedStatus={setDeletedStatus}
          setLoading={setLoading}
        /> */}
        {/* {userIdOfStatus && (
          <Status
            userIdOfStatus={userIdOfStatus}
            setUserIdOfStatus={setUserIdOfStatus}
            setDeletedStatus={setDeletedStatus}
            // setLoading={setLoading}
          />
        )} */}
      </div>
    </div>
  );
};

export default ProfileDetails;
