import React, { useState } from "react";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Loading from "../Shared/Loading";
import Status from "../Status/Status";
import { useTranslation } from "react-i18next";

const VipCircle = () => {
  const { t } = useTranslation();
  const userId = localStorage.getItem("user-id");
  const navigate = useNavigate();
  const [userIdOfStatus, setUserIdOfStatus] = useState(null);
  const [deletedStatus, setDeletedStatus] = useState(false);

  const vipListUrl = `${process.env.REACT_APP_ENDPOINT}/follower/v2/following/${userId}`;
  const { data: vipList, isLoading } = useQuery(
    [`vipList`, deletedStatus],
    () =>
      axios.get(vipListUrl).then((data) => {
        return data.data;
      })
  );

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="App min-h-screen">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {t("VIP Circle")}
        </span>
      </p>
      {vipList?.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5">
          {vipList?.map((vip, index) => (
            <div
              key={index}
              className="min-h-[150px] sm:min-h-[172px] w-full rounded-xl bg-white dark:bg-secondary shadow-md flex flex-col justify-evenly items-center mx-auto"
            >
              <img
                src={
                  vip?.image ||
                  "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                }
                alt="following"
                onClick={() =>
                  vip?.status
                    ? // ? setUserIdOfStatus(vip?._id)
                      navigate(`/status`, { state: vip?._id })
                    : navigate(`/profile/${vip._id}`)
                }
                className={`h-[70px] w-[70px] sm:h-[90px] sm:w-[90px] mx-auto object-cover rounded-full cursor-pointer ${
                  vip?.status === true &&
                  "ring ring-[#F6AB0E] ring-offset-[white] dark:ring-offset-secondary ring-offset-2"
                }`}
              />
              <p className="text-xs sm:text-sm font-[inter] font-medium">
                {vip?.name}
              </p>
              {/* {userId === localStorage.getItem("user-id") ? (
                <button className="bg-[#3A3A3A] h-[26px] w-[102px] rounded text-xs font-[inter]">
                  Following
                </button>
              ) : (
                <button className="bg-[#3A3A3A] h-[26px] w-[102px] rounded text-xs font-[inter]">
                  Follow
                </button>
              )} */}
            </div>
          ))}
          {userIdOfStatus && (
            <Status
              userIdOfStatus={userIdOfStatus}
              setUserIdOfStatus={setUserIdOfStatus}
              setDeletedStatus={setDeletedStatus}
            />
          )}
        </div>
      ) : (
        <div className="h-96 flex justify-center items-center">
          <p className="text-center font-[inter] text-xl">
            {t("You are not following anyone right now")}
          </p>
        </div>
      )}
    </div>
  );
};

export default VipCircle;
