import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ProfileFollowing = ({ following }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      {following?.length > 0 ? (
        <div className="grid grid-cols-3 xs:grid-cols-4 md:grid-cols-5 gap-3 sm:gap-5">
          {following?.map((followingID, index) => (
            <div
              key={index}
              className="min-h-[90px] sm:min-h-[172px] w-full sm:w-[130px] rounded-xl bg-[#F8F8F8] dark:bg-secondary shadow-md flex flex-col justify-evenly items-center mx-auto"
            >
              <img
                src={
                  followingID.image ||
                  "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                }
                alt="following"
                className="h-[45px] w-[45px] sm:h-[90px] sm:w-[90px] mx-auto object-cover rounded-full cursor-pointer"
                onClick={() => navigate(`/profile/${followingID?._id}`)}
              />
              <p className="text-xs font-[inter] px-[2px] xs:p-0">
                {followingID.name}
              </p>
              {/* {user?._id === localStorage.getItem("user-id") ? (
                <button   className="bg-[#3A3A3A] h-[13px] w-[50px] sm:h-[26px] sm:w-[102px] rounded text-[6px] sm:text-xs font-[inter]">
                  Following
                </button>
              ) : (
                <button  className="bg-[#3A3A3A] h-[13px] w-[50px] sm:h-[26px] sm:w-[102px] rounded text-[6px] sm:text-xs font-[inter]">
                  Follow
                </button>
              )} */}
            </div>
          ))}
        </div>
      ) : (
        <div className="h-96 flex justify-center items-center">
          <p className="font-[inter] text-xs xs:text-sm sm:text-xl">
            {t("You are not following anyone right now")}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProfileFollowing;
