import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const HelpAndFeedback = () => {
  const { t } = useTranslation();
  const userId = localStorage.getItem("user-id");

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const feedbackData = {
      user: userId,
      subject: data.subject,
      text: data.text,
    };

    const feedbackURL = `${process.env.REACT_APP_ENDPOINT}/feedback/add`;

    axios.post(feedbackURL, feedbackData).then((data) => {
      if (data.status === 200) {
        toast.success(data.data.message, { theme: "colored" });
        reset();
      } else {
        toast.error("Failed to submit Feedback", { theme: "colored" });
      }
    });
  };

  return (
    <div className="App min-h-screen max-w-5xl mx-auto font-[inter]">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {t("Help & Feedback")}
        </span>
      </p>
      <div className="bg-white dark:bg-[#121212] border border-gray-200 dark:border-[#121212] max-w-[780px] rounded-xl p-3 sm:px-3 sm:py-[30px] mx-auto space-y-6">
        <p className="font-[inter] text-sm md:text-xl font-semibold text-center">
          {t("Hi. How can we help you?")}
        </p>

        <img src="https://i.ibb.co/nrCZn6P/image-7-1.png" alt="banner" />

        <form noValidate onSubmit={handleSubmit(onSubmit)} className="md:px-10">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Subject")} *
              </span>
            </label>
            <input
              type="text"
              placeholder={t("Your Subject Here")}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("subject", {
                required: {
                  value: true,
                  message: "Subject is required",
                },
              })}
            />
            <label className="label">
              {errors.subject?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.subject.message}
                </span>
              )}
            </label>
          </div>

          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Feedback")} *
              </span>
            </label>
            <textarea
              type="text"
              placeholder={t("Enter the feedback here")}
              className="textarea textarea-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none h-44"
              {...register("text", {
                required: {
                  value: true,
                  message: "Feedback is required",
                },
              })}
            />
            <label className="label">
              {errors.text?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.text.message}
                </span>
              )}
            </label>
          </div>
          <div className="w-full flex">
            <button
              type="submit"
              className="btn bg-secondary dark:bg-white text-white dark:text-primary text-xs w-5/12 mx-auto"
            >
              {t("Submit")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HelpAndFeedback;
