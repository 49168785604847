import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import Stories from "react-insta-stories";
import { useLocation } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { ImBin } from "react-icons/im";
import { RiEyeFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Loading from "../Shared/Loading";
import DeleteStatusModal from "../Modals/DeleteStatusModal";
import ViewerListModal from "../Modals/ViewerListModal";
import { useTranslation } from "react-i18next";
// import { toast } from "react-toastify";
// import DeleteStatusModal from "../Modals/DeleteStatusModal";
// import ViewerListModal from "../Modals/ViewerListModal";

const Status = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ownerId = localStorage.getItem("user-id");
  const [loading, setLoading] = useState(false);
  const [deletedStatus, setDeletedStatus] = useState(false);
  const [userIdOfStatus, setUserIdOfStatus] = useState(useLocation().state);
  const [statusDetails, setStatusDetails] = useState(null);
  const [singleStatus, setSingleStatus] = useState(null);
  const [viewerList, setViewerList] = useState(null);
  // const [paused, setPaused] = useState(statusDetails ? true : false);

  let statusURL;
  if (userIdOfStatus === ownerId) {
    statusURL = `${process.env.REACT_APP_ENDPOINT}/status/my-profile/${userIdOfStatus}`;
  } else {
    statusURL = `${process.env.REACT_APP_ENDPOINT}/status/${userIdOfStatus}`;
  }
  const { data: statuses, isLoading } = useQuery(
    [`statuses`],
    () =>
      axios.get(statusURL).then((data) => {
        return data.data;
      }),
    { enabled: userIdOfStatus !== null }
  );

  const handleViewCount = (status) => {
    setSingleStatus(status);
    const viewCountURL = `${process.env.REACT_APP_ENDPOINT}/status/${status?._id}/add-view/${ownerId}`;

    userIdOfStatus !== ownerId &&
      axios.post(viewCountURL).then((data) => {
        return;
      });
  };

  if (isLoading || loading) {
    <Loading />;
  }

  const stories = statuses
    ? statuses?.map((status, index) => ({
        content: ({ action }) => {
          action("play");
          return (
            <div
              key={index}
              onLoad={() => handleViewCount(status)}
              className="w-full"
            >
              <div className="bg-[#F6F6F6] dark:bg-[#272727]">
                <div className="max-w-md mx-auto h-[200px] sm:h-[250px]">
                  <img
                    src={status?.product?.imageGallery?.[0]}
                    alt="Post"
                    className="h-[200px] sm:h-[250px] mx-auto object-cover"
                  />
                </div>
              </div>
              <div className="py-4 space-y-2 p-6 bg-white dark:bg-secondary">
                <div className="flex flex-col gap-1 md:gap-3">
                  <h2 className="text-base xs:text-2xl font-semibold">
                    {status?.product?.itemName}
                  </h2>
                  <h2 className="text-base xs:text-2xl font-semibold">
                    € {status?.product?.price}
                  </h2>
                </div>
                <div className="lg:h-[70px]">
                  <span className="font-[inter] text-[10px] xs:text-xs text-[#8A8A8A]">
                    {t("Description")}
                  </span>
                  <p className="text-xs xs:text-sm">
                    {status?.product?.description}
                  </p>
                </div>
              </div>
            </div>
          );
        },
      }))
    : [""];

  deletedStatus && navigate(`/profile/${statuses?.[0]?.user?._id}`);
  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black">
      <div className="rounded-lg bg-white dark:bg-secondary w-80 xs:w-[380px] sm:w-[600px] shadow-md">
        <div className="relative flex justify-between items-center px-6 py-2">
          <div className="flex items-center gap-3 z-20">
            <div className="avatar">
              <div
                onClick={() => navigate(`/profile/${statuses?.[0]?.user?._id}`)}
                className="w-8 xs:w-12 rounded-full"
              >
                <img
                  src={
                    statuses?.[0]?.user?.image ||
                    "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                  }
                  alt=" "
                  className="cursor-pointer"
                />
              </div>
            </div>
            <div className="text-xs xs:text-sm font-[poppins]">
              <p>{statuses?.[0]?.user?.name}</p>
            </div>
          </div>
          <div className="flex items-center gap-5">
            {userIdOfStatus === localStorage.getItem("user-id") && (
              <button
                onClick={() => setViewerList(singleStatus?.views)}
                className=" flex justify-center items-center gap-1"
              >
                <RiEyeFill />
                <p className="font-[inter]">{singleStatus?.views.length}</p>
              </button>
            )}
            <button
              className=" bg-slate-800 dark:bg-white h-6 w-6 rounded-md flex justify-center items-center ml-auto"
              onClick={() => {
                // setUserIdOfStatus(null);
                navigate(-1);
              }}
            >
              <RxCross1 className="text-white dark:text-black" />
            </button>
          </div>
        </div>
        <Stories
          loader={true}
          stories={stories}
          defaultInterval={5000}
          width={"100%"}
          height={"100%"}
          keyboardNavigation={true}
          progressContainerStyles={{ zIndex: 3, width: "90%", filter: "none" }}
          progressWrapperStyles={{ height: "4px" }}
          progressStyles={{ height: "4px" }}
          preventDefault={true}
          // isPaused={statusDetails !== null && true}
          onAllStoriesEnd={() => navigate(-1)}
        />
        <div className="flex gap-2 items-center justify-end mt-auto px-6 pb-6">
          {userIdOfStatus === localStorage.getItem("user-id") && (
            <button
              onClick={() => {
                setStatusDetails(singleStatus);
              }}
              className="bg-[#F6F6F6] dark:bg-[#232323] h-[30px] w-[30px] xs:h-[50px] xs:w-[50px] flex justify-center items-center rounded-lg xs:rounded-xl"
            >
              <ImBin className="w-4 h-4 xs:w-6 xs:h-6" />
            </button>
          )}
          <button
            onClick={() => navigate(`/item/${singleStatus?.product?._id}`)}
            className="text-white bg-[#121212] h-[30px] w-[100px] xs:h-[50px] xs:w-[177px] flex justify-center items-center rounded-lg xs:rounded-xl text-sm xs:text-lg"
          >
            View More
          </button>
        </div>
      </div>
      <ViewerListModal viewerList={viewerList} setViewerList={setViewerList} />
      <DeleteStatusModal
        statusDetails={statusDetails}
        setStatusDetails={setStatusDetails}
        setUserIdOfStatus={setUserIdOfStatus}
        setDeletedStatus={setDeletedStatus}
        setLoading={setLoading}
      />
    </div>
  );
};

export default Status;
