import React, { useState } from "react";
import "./SubscriptionModal.css";
import { RxCross1 } from "react-icons/rx";
import { HiCheckCircle } from "react-icons/hi";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { HiCheck } from "react-icons/hi";
import PlanSwitch from "./PlanSwitch";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SubscriptionModal = ({
  showSubscriptionModal,
  setShowSubscriptionModal,
}) => {
  const { t } = useTranslation();
  const [goldPlan, setGoldPlan] = useState("gold_y");
  const [platinumPlan, setPlatinumPlan] = useState("plat_y");
  const [planDuration, setPlanDuration] = useState("y");
  const [planType, setPlanType] = useState("gold");
  const userId = localStorage.getItem("user-id");
  const profileDetailsUrl = `${process.env.REACT_APP_ENDPOINT}/user/profile/${userId}`;
  const { data: profileDetails } = useQuery(
    [`profileDetails`, userId],
    () =>
      axios.get(profileDetailsUrl).then((data) => {
        return data.data;
      }),
    { enabled: userId !== null ? true : false }
  );

  return (
    <>
      {showSubscriptionModal ? (
        <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 backdrop-blur-sm font-[inter]">
          {/* Mobile Screen */}
          <div className="sm:hidden relative top-[10px] rounded-lg bg-white dark:bg-secondary w-full py-4 shadow-md space-y-3 flex flex-col items-center px-2">
            <button
              className="bg-slate-800 dark:bg-white h-6 w-6 rounded-md flex justify-center items-center ml-auto mx-6"
              onClick={() => setShowSubscriptionModal(false)}
            >
              <RxCross1 className="text-white dark:text-black" />
            </button>
            <div className="flex flex-col items-center space-y-3">
              <p className="text-black dark:text-white text-[28px] font-semibold">
                {t("Subscription Plans")}
              </p>
              <span className="text-sm text-[#7C7C7C] dark:text-[#c5c4c4]">
                {t("You’re currently on Plan")}{" "}
                {localStorage.getItem("user-planType").toUpperCase()}
              </span>
            </div>
            <div className="flex flex-col sm:flex-row sm:h-[410px] justify-center">
              <PlanSwitch plan={planDuration} setPlan={setPlanDuration} />
            </div>
            <div className="grid grid-cols-11 w-full">
              <div className="col-span-5">
                <div className="h-14" />
                <div className="space-y-10 py-5 text-sm px-2">
                  <p>Free Categories</p>
                  <p>Gold Categories</p>
                  <p>Platinum Catergoies</p>
                </div>
              </div>
              <div
                onClick={() => setPlanType("gold")}
                className={`col-span-3 border-2 cursor-pointer ${
                  planType === "gold"
                    ? "border-[#efa716]"
                    : "border-[#8A99A81A]"
                }`}
              >
                <div
                  className={`flex flex-col justify-center items-center h-14 space-y-2 ${
                    planType === "gold" ? "gold-plan" : "bg-[#1E1F21]"
                  }`}
                >
                  <p className="text-sm font-semibold text-center text-white">
                    GOLD
                  </p>
                  <p className="text-[10px] font-bold text-black dark:text-white">
                    {planDuration === "m" ? "$99" : "$799"}
                    <span className="font-normal">
                      {planDuration === "m" ? "/month" : "/year"}
                    </span>
                  </p>
                </div>
                <div
                  className={`flex flex-col justify-center item-center space-y-7 py-5 ${
                    planType === "gold" && "bg-[#8A99A840]"
                  }`}
                >
                  <HiCheck className="dark:text-white h-7 w-7 mx-auto" />
                  <HiCheck className="dark:text-white h-7 w-7 mx-auto" />
                  <AiOutlineClose className="dark:text-white h-7 w-7 mx-auto" />
                </div>
              </div>
              <div
                onClick={() => setPlanType("plat")}
                className={`col-span-3 border-2 cursor-pointer ${
                  planType === "plat"
                    ? "border-[#296cab]"
                    : "border-[#8A99A81A]"
                }`}
              >
                <div
                  className={`flex flex-col justify-center items-center h-14 space-y-2 ${
                    planType === "plat" ? "platinum-plan" : "bg-[#8A99A81A]"
                  }`}
                >
                  <p className="text-sm font-semibold text-center text-white">
                    PLATINUM
                  </p>
                  <p className="text-[10px] font-bold text-black dark:text-white">
                    {planDuration === "m" ? "$199" : "$999"}
                    <span className="font-normal">
                      {planDuration === "m" ? "/month" : "/year"}
                    </span>
                  </p>
                </div>
                <div
                  className={`flex flex-col justify-center item-center space-y-7 py-5 ${
                    planType === "plat" && "bg-[#8A99A840]"
                  }`}
                >
                  <HiCheck className="dark:text-white h-7 w-7 mx-auto" />
                  <HiCheck className="dark:text-white h-7 w-7 mx-auto" />
                  <HiCheck className="dark:text-white h-7 w-7 mx-auto" />
                </div>
              </div>
            </div>
            <form
              action={`${process.env.REACT_APP_ENDPOINT}/sub/create-checkout-session`}
              method="POST"
            >
              <button
                type="submit"
                className="h-[50px] w-[330px] rounded-lg text-white dark:text-secondary font-bold bg-secondary dark:bg-white"
              >
                {t("Buy Now")}
              </button>
              <input
                type="hidden"
                name="lookup_key"
                value={`${planType}_${planDuration}`}
              />
              <input
                type="hidden"
                name="name"
                value={profileDetails?.user?.name}
              />
              <input
                type="hidden"
                name="email"
                value={profileDetails?.user?.email}
              />
              <input
                type="hidden"
                name="phone"
                value={profileDetails?.user?.phone}
              />
            </form>
          </div>
          {/* Large Screen */}
          <div className="hidden sm:block relative top-[80px] sm:top-0 rounded-lg bg-white dark:bg-secondary min-h-screen sm:min-h-0 sm:h-[520px] w-64 xs:w-[380px] sm:w-[500px] pt-4 shadow-md">
            <div className="flex border-b-2 px-6 pb-3">
              <div className="flex flex-col">
                <p className="text-black dark:text-white font-semibold">
                  {t("Subscription Plans")}
                </p>
                <span className="text-sm text-[#7C7C7C] dark:text-[#c5c4c4]">
                  {t("You’re currently on Plan")}{" "}
                  {localStorage.getItem("user-planType").toUpperCase()}
                </span>
              </div>
              <button
                className="bg-slate-800 dark:bg-white h-6 w-6 rounded-md flex justify-center items-center ml-auto"
                onClick={() => setShowSubscriptionModal(false)}
              >
                <RxCross1 className="text-white dark:text-black" />
              </button>
            </div>
            <div className="flex flex-col sm:flex-row pt-6 sm:h-[410px] justify-center">
              {localStorage.getItem("user-planType") !== "gold" && (
                <>
                  <div className="mx-8  text-black flex flex-col justify-between">
                    <div className="space-y-3">
                      <p className="h-[22px] w-[52px] rounded text-sm font-semibold right-32 text-center text-white gold-plan">
                        GOLD
                      </p>
                      <p className="font-bold text-[32px] text-black dark:text-white">
                        {goldPlan === "gold_m" ? "$99" : "$799"}
                        <span className="font-normal text-xl">
                          {goldPlan === "gold_m" ? "/month" : "/year"}
                        </span>
                      </p>

                      <PlanSwitch plan={goldPlan} setPlan={setGoldPlan} />
                      <ul className="flex flex-col gap-4">
                        <li className="text-sm flex gap-2 items-center">
                          <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                          <span className="text-black dark:text-white">
                            {t("Free Categories")}
                          </span>
                        </li>
                        <li className="text-sm flex gap-2 items-center">
                          <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                          <span className="text-black dark:text-white">
                            {t("Gold Categories")}
                          </span>
                        </li>
                        <li className="text-sm flex gap-2 items-center">
                          <AiFillCloseCircle className="text-[#E03600] h-[13px] w-[13px]" />
                          <span className="text-[#767676]">
                            {t("Platinum Categories")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <form
                      action={`${process.env.REACT_APP_ENDPOINT}/sub/create-checkout-session`}
                      method="POST"
                    >
                      <button
                        type="submit"
                        className="h-[37px] w-[152px] mx-auto rounded-lg text-white font-bold gold-plan mt-2 sm:mt-0"
                      >
                        {t("Buy Now")}
                      </button>
                      <input type="hidden" name="lookup_key" value={goldPlan} />
                      <input
                        type="hidden"
                        name="name"
                        value={profileDetails?.user?.name}
                      />
                      <input
                        type="hidden"
                        name="email"
                        value={profileDetails?.user?.email}
                      />
                      <input
                        type="hidden"
                        name="phone"
                        value={profileDetails?.user?.phone}
                      />
                    </form>
                  </div>
                  <div className="divider-vertical sm:divider sm:divider-horizontal bg-[#E9E9E9] h-[2px] sm:w-[2px]" />
                </>
              )}
              <div className="mx-8 text-black flex flex-col justify-between">
                <div className="space-y-3">
                  <p className="h-[22px] w-[85px] rounded text-sm font-semibold right-32 text-center text-white platinum-plan">
                    PLATINUM
                  </p>
                  <p className="font-bold text-[32px] text-black dark:text-white">
                    {platinumPlan === "plat_m" ? "$199" : "$999"}
                    <span className="font-normal text-xl">
                      {platinumPlan === "plat_m" ? "/month" : "/year"}
                    </span>
                  </p>
                  <PlanSwitch plan={platinumPlan} setPlan={setPlatinumPlan} />
                  <ul className="flex flex-col gap-4">
                    <li className="text-sm flex gap-2 items-center">
                      <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                      <span className="text-black dark:text-white">
                        {t("Free Categories")}
                      </span>
                    </li>
                    <li className="text-sm flex gap-2 items-center">
                      <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                      <span className="text-black dark:text-white">
                        {t("Gold Categories")}
                      </span>
                    </li>
                    <li className="text-sm flex gap-2 items-center">
                      <HiCheckCircle className="text-[#119726] h-[13px] w-[13px]" />
                      <span className="text-black dark:text-white">
                        {t("Platinum Categories")}
                      </span>
                    </li>
                  </ul>
                </div>
                <form
                  action={`${process.env.REACT_APP_ENDPOINT}/sub/create-checkout-session`}
                  method="POST"
                >
                  <button
                    type="submit"
                    className="h-[37px] w-[152px] mx-auto rounded-lg text-white font-bold platinum-plan mt-2 sm:mt-0"
                  >
                    {t("Buy Now")}
                  </button>
                  <input type="hidden" name="lookup_key" value={platinumPlan} />
                  <input
                    type="hidden"
                    name="name"
                    value={profileDetails?.user?.name}
                  />
                  <input
                    type="hidden"
                    name="email"
                    value={profileDetails?.user?.email}
                  />
                  <input
                    type="hidden"
                    name="phone"
                    value={profileDetails?.user?.phone}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SubscriptionModal;
