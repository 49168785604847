import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import Loading from "../Shared/Loading";

const OrderList = () => {
  const { t } = useTranslation();
  const userId = localStorage.getItem("user-id");
  const ordersURL = `${process.env.REACT_APP_ENDPOINT}/order/all/${userId}`;

  const { data: orders, isLoading } = useQuery(
    [`orders`, ordersURL],
    () => axios.get(ordersURL).then((data) => data.data.data),
    { enabled: userId ? true : false }
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App min-h-screen max-w-5xl mx-auto">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">My Orders</span>
      </p>
      <div className="card shadow-xl bg-white dark:bg-secondary mt-6">
        <div className="overflow-x-auto">
          <table className="table table-xs w-full">
            {/* head */}
            <thead className="bg-black text-black">
              <tr>
                <th>No</th>
                <th>Image</th>
                <th>Name</th>
                <th>Price</th>
                <th>Seller</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="">
              {orders?.map((order, index) => (
                <tr key={index}>
                  <th className="bg-secondary">{index + 1}</th>
                  <td className="bg-secondary">
                    <img
                      src={order?.item?.imageGallery[0]}
                      alt=""
                      className="size-16"
                    ></img>
                  </td>
                  <td className="bg-secondary">
                    {order?.item?.itemName?.slice(0, 30)}
                  </td>
                  <td className="bg-secondary">€ {order?.item?.price}</td>
                  <td className="bg-secondary">{order?.seller?.name}</td>
                  <td className="bg-secondary">
                    {order?.status === "completed" ? (
                      <div className="p-1 w-20 bg-success text-[10px] text-center rounded">
                        COMPLETED
                      </div>
                    ) : (
                      <div className="p-1 w-20 bg-error text-[10px] text-center rounded">
                        PENDING
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
