import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Shared/Loading";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const AllCategories = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const categoriesURL = `${process.env.REACT_APP_ENDPOINT}/collection/main`;
  const { data: categories, isLoading } = useQuery([`categories`], () =>
    axios.get(categoriesURL).then((data) => {
      return data.data;
    })
  );

  if (isLoading) {
    return <Loading />;
  }
  const handleNavigate = (category) => {
    category?.subcategoryCount > 0
      ? navigate(
          `/cat/${category?.collection?.name
            .toLowerCase()
            .split(" ")
            .join("-")}/sub`,
          { state: { category } }
        )
      : navigate(
          `/cat/${category?.collection?.name
            .toLowerCase()
            .split(" ")
            .join("-")}`,
          { state: { category } }
        );
    localStorage.setItem("category", JSON.stringify(category.collection));
    ReactGA.event({
      category: "Category",
      action: "Click",
      label: `Category: ${category?.collection?.name}, Id: ${category?.collection?._id} is clicked`,
    });
  };
  return (
    <div className="App min-h-screen max-w-5xl mx-auto">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {t("All")} {t("Categories")}
        </span>
      </p>
      <div className="grid grid-cols-3 gap-5">
        {categories?.map((category, index) => (
          <button
            key={index}
            onClick={() => handleNavigate(category)}
            className={`relative bg-white dark:bg-secondary shadow-md rounded-[10px] border-2 h-[100px] w-full sm:w-44 md:w-60 md:px-5 mx-auto ${
              category?.collection?.planType === "gold"
                ? "border-[#F8C73C]"
                : category?.collection?.planType === "platinum"
                ? "border-[#4485C2]"
                : "border-none"
            }`}
          >
            {category?.collection?.planType !== "free" && (
              <p
                className={`absolute top-[-2px] h-[18px] lg:h-[25px] right-0 left-0 m-auto px-1 rounded rounded-t-none text-sm font-semibold text-center text-white flex justify-center items-center ${
                  category?.collection?.planType === "gold"
                    ? "gold-plan w-7 md:w-10"
                    : "platinum-plan w-11 md:w-[70px]"
                }`}
              >
                <span className="text-black text-[8px] md:text-xs">
                  {category?.collection?.planType === "gold"
                    ? "GOLD"
                    : "PLATINUM"}
                </span>
              </p>
            )}
            <div className="flex flex-col md:flex-row md:justify-center items-center gap-2 md:gap-2 w-full">
              <img
                src={category?.collection?.logo}
                className="w-[50px] md:w-4/12"
                alt=" "
              />
              <div className="md:w-8/12">
                <h1 className="xs:text-left text-[10px] md:text-base font-[poppins] font-medium mx-[2px] xs:mx-0">
                  {category?.collection?.name}
                </h1>
              </div>
            </div>
            {/* className={`bg-secondary rounded-[10px] border-secondary border-[1px]" flex justify-between items-center h-[100px] w-96 p-7`}>
          //   <div className="flex items-center gap-4">
          //     <img src={category?.logo} className="max-w-sm" alt=" " />
          //     <h1 className="">{category?.name}</h1>
          //   </div>
            {categoryName === category?.name && (
              <CheckIcon className="h-8 w-8" />
            )} */}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AllCategories;
