import React from "react";
import { useTranslation } from "react-i18next";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
const ViewerListModal = ({ viewerList, setViewerList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {viewerList ? (
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black"
          style={{ marginTop: 0 }}
        >
          <div className="relative rounded-lg bg-white dark:bg-secondary w-80 xs:w-[380px] sm:w-[600px] h-[calc(100vh-30px)] shadow-md px-6 py-9">
            <div className="flex items-center gap-4">
              <button onClick={() => setViewerList(null)}>
                <HiArrowLeft className="w-7 h-7 text-black dark:text-white" />
              </button>
              <p className="text-xl font-[inter]">
                {viewerList?.length} {t("Story View")}
              </p>
            </div>
            <hr className="my-6" />
            <div className="scrollbar-hide flex flex-col gap-4">
              {viewerList?.map((viewer, index) => (
                <div key={index} className="flex items-center gap-3">
                  <div className="avatar">
                    <div
                      onClick={() => navigate(`/profile/${viewer._id}`)}
                      className="w-8 xs:w-12 rounded-full"
                    >
                      <img
                        src={
                          viewer?.image ||
                          "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                        }
                        alt=" "
                        // onClick={() =>
                        //   navigate(`/profile/${itemDetails?.seller?._id}`, {
                        //     state: itemDetails?.seller,
                        //   })
                        // }
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className="text-xs xs:text-sm font-[poppins]">
                    <p>{viewer.name}</p>
                    {/* <span className="font-[inter] text-[10px] xs:text-xs text-[#8A8A8A]">
                    Member{" "}
                    {viewer.createdAt
                      ? moment(viewer.createdAt).format("YYYY")
                      : "2023"}
                  </span> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ViewerListModal;
