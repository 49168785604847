import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Shared/Loading";
import { useTranslation } from "react-i18next";

const ManageSubscription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("session_id");
  const createPortalURL = `${process.env.REACT_APP_ENDPOINT}/sub/create-portal-session`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  };
  const body = { session_id: sessionId };
  const { data: sessionURL, isLoading } = useQuery(
    ["sessionURL"],
    () =>
      axios.post(createPortalURL, body, config).then((data) => {
        return data.data;
      }),
    { enabled: sessionId ? true : false }
  );

  useEffect(() => {
    if (sessionURL) {
      navigate("/profile/" + sessionURL);
    }
  }, [sessionURL, navigate]);

  useEffect(() => {
    if (!sessionId) {
      navigate(`/profile/${localStorage.getItem("user-id")}`);
    }
  }, [sessionId, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  // If sessionURL is null or undefined, the redirection will not occure
  return (
    <div className="App min-h-[calc(100vh-96px)] flex flex-col justify-center items-center font-[inter]">
      <p className="text-sm ease-in duration-300">{t("Redirecting")}</p>
    </div>
  );
};

export default ManageSubscription;
