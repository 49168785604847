// import { useQuery } from "@tanstack/react-query";
// import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Loading from "../Shared/Loading";
import { useTranslation } from "react-i18next";

const ChatList = ({ chatId, setOpenedChat, chatList }) => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState("");
  const navigate = useNavigate();

  const userId = localStorage.getItem("user-id");
  // const chatListUrl = `${process.env.REACT_APP_ENDPOINT}/chats/${userId}/user`;

  // const { data: chatList } = useQuery([`chatList`, chatListUrl], () =>
  //   axios.get(chatListUrl).then((data) => {
  //     return data.data;
  //   })
  // );

  useEffect(() => {
    const openedChat = chatList?.find((chat) => chat?.id === chatId);

    setOpenedChat(openedChat);
  }, [chatId, chatList, setOpenedChat]);

  // if (isLoading) {
  //   return <Loading />;
  // }
  return (
    <div className="col-span-4 bg-[#F6F6F6] dark:bg-[#272727] h-[560px] 2xl:h-[600px] overflow-y-scroll scrollbar-hide border dark:border-[#272727] border-r-0">
      {chatId === "undefined" ? (
        <div className="h-full px-[30px] flex items-center justify-center text-sm">
          <p>{t("Chat List is Empty")}</p>
        </div>
      ) : (
        chatList?.map((chat, index) => (
          <div
            onClick={() => {
              setSelectedId(chat?.id);
              setOpenedChat(chat);
              navigate(`/inbox/${chat?.id}`);
            }}
            key={index}
            className={`h-12 xs:h-24 px-2 sm:px-[10px] flex gap-3 items-center justify-start cursor-pointer hover:bg-white hover:dark:bg-[#2F2F2F] ${
              (selectedId === chat?.id || chatId === chat?.id) &&
              "bg-white dark:bg-[#2F2F2F]"
            }`}
          >
            <img
              src={
                (chat?.users?.[0]?._id === userId
                  ? chat?.users?.[1]?.image
                  : chat?.users?.[0]?.image) ||
                "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
              }
              alt="user"
              className="h-5 w-5 sm:h-10 sm:w-10 object-cover rounded-full border border-white"
            />
            <div className="flex flex-col text-[10px] sm:text-xs">
              <p className="text-pretty">
                {chat?.users?.[0]?._id === userId
                  ? chat?.users?.[1]?.name?.length > 20
                    ? chat?.users?.[1]?.name?.slice(0, 20) + "..."
                    : chat?.users?.[1]?.name
                  : chat?.users?.[0]?.name?.length > 20
                  ? chat?.users?.[0]?.name?.slice(0, 20) + "..."
                  : chat?.users?.[0]?.name}
              </p>
              <span
                onClick={(event) =>
                  navigate(`/item/${chat?.post?._id}`, event.stopPropagation())
                }
                className="font-[inter] text-[10px] sm:text-[12px] text-[#9D9D9D] text-pretty cursor-pointer hover:underline"
              >
                {chat?.post?.name?.slice(0, 30)}
              </span>
              <span
                className={`font-[inter] text-[8px] sm:text-[10px] text-pretty ${
                  chat.unreadCount > 0
                    ? "dark:text-white text-black"
                    : "text-[#9D9D9D]"
                }`}
              >
                {chat?.lastMessage}
              </span>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ChatList;
