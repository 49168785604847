import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { useTranslation } from "react-i18next";
const userId = localStorage.getItem("user-id");
const RemoveFollowerModal = ({
  showUnfollowModal,
  setShowUnfollowModal,
  removeFollower,
  setRemoveFollower,
  setLoading,
}) => {
  const { t } = useTranslation();
  const handleRemove = (id) => {
    const url = `${process.env.REACT_APP_ENDPOINT}/follower/${userId}/toggle `;
    const follower = { follower: id };
    setLoading(true);

    axios
      .post(url, follower)
      .then((data) => {
        if (data.status === 200) {
          if (data.data.msg === "removed") {
            toast.success(`You have unfollowed ${removeFollower?.name}`, {
              theme: "colored",
            });
            setShowUnfollowModal(false);
            setRemoveFollower(null);
          } else {
            toast.error(`Can't Unfollow ${removeFollower?.name}`, {
              theme: "colored",
            });
          }
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      {showUnfollowModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm ">
            <div className="relative rounded-lg bg-white dark:bg-secondary w-[300px] xs:w-[400px] bottom-16 p-8 space-y-6 shadow-md">
              <div className="flex justify-between">
                <p className="text-black dark:text-white font-[inter] font-semibold">
                  Remove Follower
                </p>

                <button
                  className="text-white bg-slate-800 dark:bg-white h-6 w-6 rounded-md flex justify-center items-center"
                  onClick={() => {
                    setShowUnfollowModal(false);
                    setRemoveFollower(null);
                  }}
                >
                  <RxCross1 className="text-white dark:text-black" />
                </button>
              </div>
              <p className="text-black dark:text-white font-[inter]">
                {t("Do you want to remove")}{" "}
                <span className="font-semibold">{removeFollower?.name}</span>?
              </p>
              <div className="flex items-center justify-end">
                <button
                  className="btn btn-sm btn-outline btn-error font-bold uppercase text-sm rounded shadow hover:shadow-lg"
                  type="button"
                  onClick={() => handleRemove(removeFollower?._id)}
                >
                  {t("Confirm")}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default RemoveFollowerModal;
