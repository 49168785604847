import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ItemCard from "./ItemCard";
import { useTranslation } from "react-i18next";

const AllItems = () => {
  const { t } = useTranslation();
  const { route } = useParams();
  const { items, message } = useLocation().state;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  return (
    <div className="App min-h-screen">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {t(`${route?.split("-").join(" ")}`)}
        </span>
      </p>
      {items?.length > 0 ? (
        <ItemCard items={items} />
      ) : (
        <p className="text-center font-[inter] text-xl">{t(`${message}`)}</p>
      )}
    </div>
  );
};

export default AllItems;
