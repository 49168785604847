import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect } from "react";
import Loading from "../Shared/Loading";
import ItemCard from "../Items/ItemCard";

import { useTranslation } from "react-i18next";
const FavouriteItems = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  const { t } = useTranslation();
  const coordinates = JSON.parse(localStorage.getItem("coordinates"));
  // const lat = coordinates?.lat || 48.7758459;
  // const lng = coordinates?.lng || 9.1829321;
  const userId = localStorage.getItem("user-id");
  const url = `${process.env.REACT_APP_ENDPOINT}/feed/${userId}/fav?lat=${coordinates?.lat}&lng=${coordinates?.lng}`;

  const { data: favouriteItems, isLoading } = useQuery(
    [`favouriteItems`, url],
    () =>
      axios.get(url).then((data) => {
        return data.data;
      })
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App min-h-screen text-[#444444] dark:text-white">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /
        <span className="text-black dark:text-[#E2E2E2]">
          {" "}
          {t("Favourites")}
        </span>
      </p>
      {favouriteItems?.length > 0 ? (
        <ItemCard items={favouriteItems} />
      ) : (
        <p className="text-center font-[inter] text-xl">
          {t("No Item is Available")}
        </p>
      )}
    </div>
  );
};

export default FavouriteItems;
