import React, { useState } from "react";
import Print from "./Print";
import Language from "./Language";
import ThemeCenter from "./ThemeCenter";
import AccountSetting from "./AccountSetting";
import { useTranslation } from "react-i18next";

const Settings = ({ theme, toggleTheme }) => {
  const { t } = useTranslation();
  const [settingName, setSettingName] = useState("print");
  return (
    <div className="App min-h-screen max-w-5xl mx-auto font-[inter]">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /
        <span className="text-black dark:text-[#E2E2E2]"> {t("Settings")}</span>
      </p>
      <div className="bg-white dark:bg-[#121212] border border-gray-200 dark:border-[#121212] max-w-[780px] rounded-xl mx-auto flex flex-col md:grid grid-cols-12 h-[260px] sm:h-[360px]">
        <div className=" col-span-3 flex md:flex-col bg-[#F6F6F6] dark:bg-[#272727] rounded-t-xl md:rounded-r-none md:rounded-l-xl">
          <button
            onClick={() => setSettingName("print")}
            className={`h-12 w-full px-2 xs:px-[30px] hover:bg-white hover:dark:bg-[#2F2F2F] text-center md:text-start text-xs sm:text-base rounded-tl-xl ${
              settingName === "print" && "bg-white dark:bg-[#2F2F2F]"
            }`}
          >
            {t("Print")}
          </button>
          <button
            onClick={() => setSettingName("language")}
            className={`h-12 w-full px-2 xs:px-[30px] hover:bg-white hover:dark:bg-[#2F2F2F] text-center md:text-start text-xs sm:text-base ${
              settingName === "language" && "bg-white dark:bg-[#2F2F2F]"
            }`}
          >
            {t("Language")}
          </button>
          <button
            onClick={() => setSettingName("theme")}
            className={`h-12 w-full px-2 xs:px-[30px] hover:bg-white hover:dark:bg-[#2F2F2F] text-center md:text-start text-xs sm:text-base ${
              settingName === "theme" && "bg-white dark:bg-[#2F2F2F]"
            }`}
          >
            {t("Theme Color")}
          </button>
          <button
            onClick={() => setSettingName("account")}
            className={`h-12 w-full px-2 xs:px-[30px] hover:bg-white hover:dark:bg-[#2F2F2F] text-center md:text-start text-xs sm:text-base rounded-tr-xl md:rounded-none ${
              settingName === "account" && "bg-white dark:bg-[#2F2F2F]"
            }`}
          >
            {t("Account Settings")}
          </button>
        </div>
        <div className="col-span-9 dark:bg-[#121212] border dark:border-[#272727] md:border-l-0 h-full rounded-b-xl md:rounded-bl-none md:rounded-r-xl">
          {settingName === "print" ? (
            <Print />
          ) : settingName === "language" ? (
            <Language />
          ) : settingName === "theme" ? (
            <ThemeCenter theme={theme} toggleTheme={toggleTheme} />
          ) : (
            settingName === "account" && <AccountSetting />
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
