import i18n from "i18next";

// import Backend from "i18next-http-backend";

import { initReactI18next } from "react-i18next";

import tEn from "./locales/en.json";
import tDe from "./locales/de.json";
// import tHi from "./locales/hi/translation.json";
// import tMr from "./locales/mr/translation.json";
// import tOr from "./locales/or/translation.json";
// import tTa from "./locales/ta/translation.json";
// import tBn from "./locales/bn/translation.json";
// import tMl from "./locales/ml/translation.json";
// import tTe from "./locales/te/translation.json";
// import tKn from "./locales/kn/translation.json";

const defaultLanguage = localStorage.getItem("lang");

i18n
  //   .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: tEn,
      },
      de: {
        translation: tDe,
      },
      //   hi: {
      //     translation: tHi,
      //   },
      //   mr: {
      //     translation: tMr,
      //   },
      //   kn: {
      //     translation: tKn,
      //   },
      //   or: {
      //     translation: tOr,
      //   },
      //   ta: {
      //     translation: tTa,
      //   },
      //   bn: {
      //     translation: tBn,
      //   },
      //   ml: {
      //     translation: tMl,
      //   },
      //   te: {
      //     translation: tTe,
      //   },
      //   ms: {
      //     translation: tEn,
      //   },
    },
    lng: defaultLanguage ? defaultLanguage : "de", //default language

    fallbackLng: defaultLanguage ? defaultLanguage : "de", //when specified language translations not present

    //then fallbacklang translations loaded.

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
