import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Loading from "../Shared/Loading";
import { toast } from "react-toastify";
const ContactUs = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  const userId = localStorage.getItem("user-id");
  const [loading, setLoading] = useState(false);
  const userDetailsUrl = `${process.env.REACT_APP_ENDPOINT}/user/profile/${userId}`;
  const { data: userDetails, isLoading } = useQuery(
    [`profileDetails`, userId],
    () =>
      axios.get(userDetailsUrl).then((data) => {
        return data.data.user;
      }),
    { enabled: userId !== null ? true : false }
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const details = {
      name: data.name,
      email: userDetails.email,
      phone: data.phone,
      subject: data.subject,
      description: data.description,
    };
    const emailSendURL = `${process.env.REACT_APP_ENDPOINT}/contact/send`;
    axios
      .post(emailSendURL, details)
      .then((data) => {
        if (data?.status === 200) {
          toast.success(t(data.data.message), {
            theme: "colored",
          });
          reset();
        }
      })
      .finally(() => setLoading(false));
  };
  if (isLoading || loading) {
    return <Loading />;
  }
  return (
    <div className="App max-w-6xl 2xl:max-w-7xl mx-auto font-[inter]">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {t("Contact Us")}
        </span>
      </p>
      <div className="bg-white dark:bg-[#121212] border border-gray-200 dark:border-[#121212] max-w-5xl 2xl:max-w-6xl rounded-xl p-3 sm:px-5 sm:py-[30px] mx-auto space-y-4 sm:space-y-6 2xl:space-y-8 text-justify">
        <h1 className="text-base sm:text-2xl 2xl:text-3xl font-semibold text-center">
          Contact to vip ware pro.com
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-12 gap-4"
        >
          <div className="form-control w-full col-span-12 sm:col-span-12">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Name")} *
              </span>
            </label>
            <input
              placeholder={
                userDetails?.name
                  ? userDetails?.name
                  : `${t("Your")} ${t("Name")}`
              }
              defaultValue={userDetails?.name && userDetails?.name}
              {...register("name", {})}
              type="text"
              readOnly
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
            />
            <label className="label">
              {errors.name?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.name.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full col-span-12 sm:col-span-6">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Email")} *
              </span>
            </label>
            <input
              type="text"
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder={userDetails?.email || `${t("Your")} ${t("Email")}`}
            />
            <label className="label"></label>
          </div>
          <div className="form-control w-full col-span-12 sm:col-span-6">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Phone Number")} *
              </span>
            </label>
            <input
              type="number"
              min={0}
              onKeyDown={(evt) =>
                (evt.key === "e" ||
                  evt.key === "E" ||
                  evt.key === "-" ||
                  evt.key === ".") &&
                evt.preventDefault()
              }
              placeholder={`${t("Your")} ${t("Phone Number")}`}
              defaultValue={userDetails?.phone}
              {...register("phone", {
                required: {
                  value: true,
                  message: "Phone Number is required",
                },
              })}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
            />
            <label className="label">
              {errors.phone?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.phone.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full col-span-12 sm:col-span-12">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Subject")} *
              </span>
            </label>
            <input
              type="text"
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder={"Your Subject"}
              {...register("subject", {
                required: {
                  value: true,
                  message: "Subject is required",
                },
              })}
            />
            <label className="label">
              {errors.subject?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.subject.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full col-span-12 sm:col-span-12">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Description")} *
              </span>
            </label>
            <textarea
              type="text"
              placeholder={t("Description")}
              {...register("description", {
                required: {
                  value: true,
                  message: "Description is required",
                },
              })}
              className="textarea textarea-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none h-24"
            />
            <label className="label">
              {errors.description?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.description.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-8/12 flex mx-auto col-span-12 sm:col-span-12">
            <input
              type="submit"
              className="btn w-full bg-black dark:bg-white text-white dark:text-black normal-case"
              value={t("Submit")}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
