import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const ExtraAttributes = ({ itemDetails }) => {
  const { t } = useTranslation();
  return (
    <div>
      {itemDetails?.category?.name === "Vehicles" && (
        <div className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-5 gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Make")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.make_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Model")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.model_number}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Year")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.year}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Mileage")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.mileage}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Condition")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.condition_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Color")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.color}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Body Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.body_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Fuel Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.fuel_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Transmission Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.transmission_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Seating Capacity")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.seating_capacity}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Real Estate" && (
        <div className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-6 gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Property Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.property_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Bedrooms")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.bedrooms}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Bathrooms")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.bathrooms}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Square Footage")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.square_footage}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Year Built")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.year_built}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Condition")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.condition}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Metals" && (
        <div className="flex gap-3 md:gap-5 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Metal Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.metal_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Form Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.form_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Weight in Grams")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.weight_in_grams}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Contracts" && (
        <div className="flex gap-3 sm:gap-5 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Contract Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.contract_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Availablity")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.availablity}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Hotel Booking" && (
        <div className="flex flex-col gap-3 xs:my-3">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Hotel Name")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.hotel_name}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Location")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?._location}
            </p>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 justify-start font-[poppins]">
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Room Type")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.room_type}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Total Guests")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.total_guests}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Checkin Date")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {moment(itemDetails?.checkin_dates)
                  .subtract(6, "hours")
                  .format("DD/MM/YYYY, h:mm A")}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Checkout Date")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {moment(itemDetails?.checkout_dates)
                  .subtract(6, "hours")
                  .format("DD/MM/YYYY, h:mm A")}
              </p>
            </div>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Patents" && (
        <div className="flex flex-col gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Inventor Information")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.inventor_information}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Patent Number")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.patent_number}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Publication Date")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {moment(itemDetails?.publication_date)
                .subtract(6, "hours")
                .format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
      )}
      {(itemDetails?.category?.name === "Luxury Goods" ||
        itemDetails?.category?.name === "Antique") && (
        <div className="flex flex-col gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Materials")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.materials}
            </p>
          </div>
        </div>
      )}
      {(itemDetails?.category?.name === "Helicopters" ||
        itemDetails?.category?.name === "Jets" ||
        itemDetails?.category?.name === "Airplanes") && (
        <div className="flex flex-col xs:flex-row gap-3 sm:gap-5 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {itemDetails?.category?.name === "Helicopters"
                ? t("Helicopter")
                : t("Aircraft")}{" "}
              {t("Model")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.category?.name === "Helicopters"
                ? itemDetails?.helicopter_model
                : itemDetails?.aircraft_model}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Engine Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.engine_type}
            </p>
          </div>
          {(itemDetails?.category?.name === "Helicopters" ||
            itemDetails?.category?.name === "Airplanes") && (
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Seating Capacity")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.seating_capacity}
              </p>
            </div>
          )}
        </div>
      )}
      {itemDetails?.category?.name === "Tanks" && (
        <div className="flex gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Make Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.make_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Model Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.model_type}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Pets" && (
        <div className="grid grid-cols-4 gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Pet Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.pet_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Age")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.age}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Gender")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.gender}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Training Levels")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.training_levels}
            </p>
          </div>
        </div>
      )}
      {(itemDetails?.category?.name === "Special Foods" ||
        itemDetails?.category?.name === "Health") && (
        <div className="flex flex-col gap-3 xs:my-3 justify-start font-[poppins]">
          {itemDetails?.category?.name === "Health" && (
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Benefits")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.benefits}
              </p>
            </div>
          )}
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Ingreadients")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.ingreadients}
            </p>
          </div>
          {itemDetails?.category?.name === "Special Foods" && (
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Nutritional Information")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.nutritional_information}
              </p>
            </div>
          )}
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Usage Instructions")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.usage_instructions}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Professional Services" && (
        <div className="flex gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Expertise and Qualifications")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.expertise_and_qualifications}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Sports Items" && (
        <div className="flex gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Sport Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.sport_type}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Business Items" && (
        <div className="flex gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Business Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.business_type}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Music" && (
        <div className="flex flex-col gap-5 font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Product Category")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.product_category}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Instrument Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.instrument_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Material and Construction")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.material_and_construction}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Concert Tickets" && (
        <div className="flex flex-col gap-3 xs:my-3">
          <div className="grid grid-cols-1 xs:grid-cols-3 gap-3 justify-start font-[poppins]">
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Event Date and Time")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {moment(itemDetails?.event_date_and_time)
                  .subtract(6, "hours")
                  .format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Seating or Standing")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.seating_or_standing}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Age Restrictions")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.age_restrictions}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Venue Information")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.venue_information}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Official Website")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.official_website}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Garden" && (
        <div className="flex flex-col gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Product Category")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.product_category}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Plant Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.plant_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Features and Specifications")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.features_and_specifications}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Electronic Items" && (
        <div className="flex flex-col gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Product Category")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.product_category}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Brand and Model")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.brand_and_model}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
                {t("Material and Build")}
              </span>
              <p className="text-xs xs:text-base break-words">
                {itemDetails?.material_and_build}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Features and Specifications")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.features_and_specifications}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Exchange Items" && (
        <div className="grid grid-cols-2 gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Category and type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.category_and_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Exchange Value")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.exchange_value}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "VIP Items" && (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Vip Package")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.vip_package}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Benefits")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.benefits}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Luxury Materials")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.luxury_materials}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Vip Experience")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.vip_experience}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Membership Duration")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.membership_duration}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Special Technology" && (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Functionality")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.functionality}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Benefits")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.benefits}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Construction Machines" && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Product Category")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.product_category}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Equipment Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.equipment_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Capacity and Power")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.capacity_and_power}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Functionality")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.functionality}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Advanced Features")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.advanced_features}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Material and Build Quality")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.material_and_build_quality}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Building Materials" && (
        <div className="flex flex-col gap-3 xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Product Category")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.product_category}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Material Type")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.material_type}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Usage and Application")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.usage_and_application}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Features and Properties")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.features_and_properties}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Safety Information")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.safety_information}
            </p>
          </div>
        </div>
      )}
      {itemDetails?.category?.name === "Bitcoin" && (
        <div className="xs:my-3 justify-start font-[poppins]">
          <div className="flex flex-col gap-1">
            <span className="text-[10px] xs:text-xs text-[#8A8A8A]">
              {t("Regulatory Information")}
            </span>
            <p className="text-xs xs:text-base break-words">
              {itemDetails?.regulatory_information}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtraAttributes;
