const addAttributes = (itemInfo, category_name, data) => {
  if (category_name === "Vehicles") {
    itemInfo.make_type = data?.make_type;
    itemInfo.model_number = parseInt(data.model_number);
    itemInfo.year = parseInt(data.year);
    itemInfo.mileage = parseInt(data.mileage);
    itemInfo.condition_type = data.condition_type;
    itemInfo.color = data.color;
    itemInfo.body_type = data.body_type;
    itemInfo.fuel_type = data.fuel_type;
    itemInfo.transmission_type = data.transmission_type;
    itemInfo.seating_capacity = parseInt(data.seating_capacity);
    return itemInfo;
  }
  if (category_name === "Real Estate") {
    itemInfo.property_type = data.property_type;
    itemInfo.bedrooms = parseInt(data.bedrooms);
    itemInfo.bathrooms = parseInt(data.bathrooms);
    itemInfo.square_footage = parseInt(data.square_footage);
    itemInfo.year_built = parseInt(data.year_built);
    itemInfo.condition = data.condition;
    return itemInfo;
  }
  if (category_name === "Metals") {
    itemInfo.metal_type = data.metal_type;
    itemInfo.form_type = data.form_type;
    itemInfo.weight_in_grams = parseInt(data.weight_in_grams);
    return itemInfo;
  }
  if (category_name === "Contracts") {
    itemInfo.contract_type = data.contract_type;
    itemInfo.availablity = data.availablity;
    return itemInfo;
  }
  if (category_name === "Hotel Booking") {
    itemInfo._location = data._location;
    itemInfo.hotel_name = data.hotel_name;
    itemInfo.checkin_dates = data.checkin_dates;
    itemInfo.checkout_dates = data.checkout_dates;
    itemInfo.total_guests = parseInt(data.total_guests);
    itemInfo.room_type = data.room_type;
    return itemInfo;
  }
  if (category_name === "Patents") {
    itemInfo.inventor_information = data.inventor_information;
    itemInfo.patent_number = parseInt(data.patent_number);
    itemInfo.publication_date = data.publication_date;
    return itemInfo;
  }
  if (category_name === "Luxury Goods" || category_name === "Antique") {
    itemInfo.materials = data.materials;
    return itemInfo;
  }
  if (category_name === "Helicopters") {
    itemInfo.helicopter_model = data.helicopter_model;
    itemInfo.engine_type = data.engine_type;
    itemInfo.seating_capacity = parseInt(data.seating_capacity);
    return itemInfo;
  }
  if (category_name === "Jets") {
    itemInfo.aircraft_model = data.aircraft_model;
    itemInfo.engine_type = data.engine_type;
    return itemInfo;
  }
  if (category_name === "Airplanes") {
    itemInfo.aircraft_model = data.aircraft_model;
    itemInfo.seating_capacity = parseInt(data.seating_capacity);
    itemInfo.engine_type = data.engine_type;
    return itemInfo;
  }
  if (category_name === "Tanks") {
    itemInfo.make_type = data.make_type;
    itemInfo.model_type = data.model_type;
    return itemInfo;
  }
  if (category_name === "Pets") {
    itemInfo.pet_type = data.pet_type;
    itemInfo.age = parseInt(data.age);
    itemInfo.gender = data.gender;
    itemInfo.training_levels = data.training_levels;
    return itemInfo;
  }
  if (category_name === "Special Foods") {
    itemInfo.ingreadients = data.ingreadients;
    itemInfo.nutritional_information = data.nutritional_information;
    itemInfo.usage_instructions = data.usage_instructions;
    return itemInfo;
  }
  if (category_name === "Health") {
    itemInfo.benefits = data.benefits;
    itemInfo.ingreadients = data.ingreadients;
    itemInfo.usage_instructions = data.usage_instructions;
    return itemInfo;
  }
  if (category_name === "Professional Services") {
    itemInfo.expertise_and_qualifications = data.expertise_and_qualifications;
    return itemInfo;
  }
  if (category_name === "Sports Items") {
    itemInfo.sport_type = data.sport_type;
    return itemInfo;
  }
  if (category_name === "Business Items") {
    itemInfo.business_type = data.business_type;
    return itemInfo;
  }
  if (category_name === "Music") {
    itemInfo.product_category = data.product_category;
    itemInfo.instrument_type = data.instrument_type;
    itemInfo.material_and_construction = data.material_and_construction;
    return itemInfo;
  }
  if (category_name === "Concert Tickets") {
    itemInfo.event_date_and_time = data.event_date_and_time;
    itemInfo.venue_information = data.venue_information;
    itemInfo.seating_or_standing = data.seating_or_standing;
    itemInfo.age_restrictions = data.age_restrictions;
    itemInfo.official_website = data.official_website;
    return itemInfo;
  }
  if (category_name === "Garden") {
    itemInfo.product_category = data.product_category;
    itemInfo.plant_type = data.plant_type;
    itemInfo.features_and_specifications = data.features_and_specifications;
    return itemInfo;
  }
  if (category_name === "Electronic Items") {
    itemInfo.product_category = data.product_category;
    itemInfo.brand_and_model = data.brand_and_model;
    itemInfo.features_and_specifications = data.features_and_specifications;
    itemInfo.material_and_build = data.material_and_build;
    return itemInfo;
  }
  if (category_name === "Exchange Items") {
    itemInfo.category_and_type = data.category_and_type;
    itemInfo.exchange_value = parseInt(data.exchange_value);
    return itemInfo;
  }
  if (category_name === "VIP Items") {
    itemInfo.vip_package = data.vip_package;
    itemInfo.benefits = data.benefits;
    itemInfo.luxury_materials = data.luxury_materials;
    itemInfo.vip_experience = data.vip_experience;
    itemInfo.membership_duration = data.membership_duration;
    return itemInfo;
  }
  if (category_name === "Special Technology") {
    itemInfo.functionality = data.functionality;
    itemInfo.benefits = data.benefits;
    return itemInfo;
  }
  if (category_name === "Construction Machines") {
    itemInfo.product_category = data.product_category;
    itemInfo.equipment_type = data.equipment_type;
    itemInfo.capacity_and_power = data.capacity_and_power;
    itemInfo.functionality = data.functionality;
    itemInfo.advanced_features = data.advanced_features;
    itemInfo.material_and_build_quality = data.material_and_build_quality;
    return itemInfo;
  }
  if (category_name === "Building Materials") {
    itemInfo.product_category = data.product_category;
    itemInfo.material_type = data.material_type;
    itemInfo.usage_and_application = data.usage_and_application;
    itemInfo.features_and_properties = data.features_and_properties;
    itemInfo.safety_information = data.safety_information;
    return itemInfo;
  }
  if (category_name === "Bitcoin") {
    itemInfo.regulatory_information = data.regulatory_information;
    return itemInfo;
  }
};
export default addAttributes;
