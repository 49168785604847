import React from "react";
import { HashLoader } from "react-spinners";
const override = {
  display: "block",
  margin: "auto",
  height: 2,
  width: 80,
};
const Loading = () => {
  return (
    <div className="sweet-loading h-[calc(100vh-96px)] flex justify-center items-center inset-0 z-50 backdrop-blur-sm">
      <div className="sm:hidden">
        <HashLoader
          color={
            localStorage.getItem("theme") === "light" ? "#000000" : "#FFFFFF"
          }
          size={80}
          aria-label="Loading Spinner"
          data-testid="loader"
          cssOverride={override}
        />
      </div>
      <div className="hidden sm:block">
        <HashLoader
          color={
            localStorage.getItem("theme") === "light" ? "#000000" : "#FFFFFF"
          }
          // className="fixed z-50 overflow-auto top-0 bottom-0 left-0 right-0"
          // className="block m-auto"
          // loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default Loading;
