import React, { useEffect, useState } from "react";
import { ReactComponent as GoogleIcon } from "../../Assets/Icons/login/google.svg";
// import { BsFacebook } from "react-icons/bs";
import {
  // useSignInWithFacebook,
  useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import auth from "../../firebase.init";
import Loading from "../Shared/Loading";
import axios from "axios";
import { toast } from "react-toastify";

const SocialLogin = () => {
  //Google Sign In
  const [signInWithGoogle, googleUser] = useSignInWithGoogle(auth);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (googleUser) {
      setLoading(true);
      const userInfo = {
        id: googleUser?.user?.uid,
        name: googleUser?.user?.displayName,
        email: googleUser?.user?.email,
        profile_pic: googleUser?.user?.photoURL,
      };
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_ENDPOINT}/user/signin/google`,
          userInfo,
          config
        )
        .then((data) => {
          if (data.status === 200) {
            localStorage.setItem("token", data?.data?.token);
            localStorage.setItem("user-id", data?.data?.user?._id);
            localStorage.setItem("user-planType", data?.data?.user?.planType);
            const photoURL = data?.data?.user?.image
              ? data?.data?.user?.image
              : googleUser?.user?.photoURL;
            localStorage.setItem("user-image", photoURL);
            navigate("/");
            toast.success(
              `Successfully Logged in as ${googleUser?.user?.email}`,
              {
                theme: "colored",
                toastId: "login",
              }
            );
          }
        })
        .finally(() => setLoading(false));
    }
  }, [googleUser, from, navigate]);

  // const handleGoogleSignIn = async () => {
  //   setLoading(true);

  //   // const userInfo = {
  //   //   id: googleUser?.user?.uid,
  //   //   name: googleUser?.user?.displayName,
  //   //   email: googleUser?.user?.email,
  //   //   profile_pic: googleUser?.user?.photoURL,
  //   // };
  //   // const config = {
  //   //   headers: {
  //   //     "Content-type": "application/json",
  //   //   },
  //   // };
  //   // await axios
  //   //   .post(
  //   //     `${process.env.REACT_APP_ENDPOINT}/user/signin/google`,
  //   //     userInfo,
  //   //     config
  //   //   )
  //   //   .then((data) => {
  //   //     if (data.status === 200) {
  //   //       localStorage.setItem("user-id", data?.data?.id);
  //   //       localStorage.setItem("token", data?.data?.token);
  //   //       localStorage.setItem("user-planType", data?.data?.planType);
  //   //       localStorage.setItem("user-image", googleUser?.user?.photoURL);
  //   //       navigate("/");
  //   //       toast.success(
  //   //         `Successfully Logged in as ${googleUser?.user?.email}`,
  //   //         {
  //   //           theme: "colored",
  //   //           toastId: "login",
  //   //         }
  //   //       );
  //   //     }
  //   //   })
  //   //   .finally(() => setLoading(false));
  // };
  if (loading) {
    return <Loading />;
  }
  return (
    <div
      className="w-auto md:w-[400px]"
      // className="grid grid-cols-2 gap-5"
    >
      <button
        onClick={() => signInWithGoogle()}
        className="bg-[#F6F6F6] flex items-center justify-center gap-2 px-12 py-3 rounded-[50px] font-medium w-full mx-auto"
      >
        <GoogleIcon />
        <span>Google</span>
      </button>
      {/* <button className="bg-[#F6F6F6] flex items-center justify-center gap-2 px-12 py-3 rounded-[50px] font-medium">
        <BsFacebook className="w-6 h-6 text-blue-700" />
        <span>Facebook</span>
      </button> */}
    </div>
  );
};

export default SocialLogin;
