import React from "react";
import { useTranslation } from "react-i18next";

const PlanSwitch = ({ plan, setPlan }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="sm:hidden flex justify-center items-center w-[127px] h-[33px] rounded-3xl p-[3px] bg-[#E2E2E2] mx-auto xs:mx-0">
        <p
          onClick={() => setPlan("y")}
          className={`flex justify-center items-center h-[27px] w-[64px] cursor-pointer ease-in-out transition-transform duration-200 ${
            plan === "y"
              ? "text-xs bg-[#141414] text-[white] rounded-3xl"
              : "text-sm text-black font-semibold bg-transparent"
          }`}
        >
          {t("Yearly")}
        </p>
        <p
          onClick={() => setPlan("m")}
          className={`flex justify-center items-center h-[27px] w-[64px] cursor-pointer ease-in-out transition-transform duration-600 ${
            plan === "m"
              ? "text-xs bg-[#141414] text-[white] rounded-3xl"
              : "text-sm text-black font-semibold bg-transparent"
          }`}
        >
          {t("Monthly")}
        </p>
      </div>
      <div className="hidden sm:flex justify-center items-center w-[127px] h-[33px] rounded-3xl p-[3px] bg-[#E2E2E2] mx-auto xs:mx-0">
        <p
          onClick={() => setPlan(`${plan.split("_")?.[0]}_y`)}
          className={`flex justify-center items-center h-[27px] w-[64px] cursor-pointer ease-in-out transition-transform duration-200 ${
            plan === `${plan.split("_")?.[0]}_y`
              ? "text-xs bg-[#141414] text-[white] rounded-3xl"
              : "text-sm text-black font-semibold bg-transparent"
          }`}
        >
          {t("Yearly")}
        </p>
        <p
          onClick={() => setPlan(`${plan.split("_")?.[0]}_m`)}
          className={`flex justify-center items-center h-[27px] w-[64px] cursor-pointer ease-in-out transition-transform duration-600 ${
            plan === `${plan.split("_")?.[0]}_m`
              ? "text-xs bg-[#141414] text-[white] rounded-3xl"
              : "text-sm text-black font-semibold bg-transparent"
          }`}
        >
          {t("Monthly")}
        </p>
      </div>
    </div>
  );
};

export default PlanSwitch;
