import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const OrderDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { orderId } = useParams();
  // const orderDetailsURL = `${process.env.REACT_APP_ENDPOINT}/orders/${orderId}`;
  // const { data: orderDetail, isLoading } = useQuery(
  //   [`orderDetail`, orderId],
  //   () =>
  //     axios.get(orderDetailsURL).then((data) => {
  //       return data.data;
  //     }),
  //   { enabled: orderId !== null ? true : false }
  // );
  return (
    <div className="App min-h-[calc(100vh-96px)] flex flex-col justify-center items-center font-[inter] gap-5">
      <p className="text-2xl ease-in duration-300">{t("Order Completed")}</p>
      <button onClick={() => navigate("/")} className="btn">
        {t("Go to Home Page")}
      </button>
    </div>
  );
};

export default OrderDetails;
