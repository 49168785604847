import React from "react";

const AccountSetting = () => {
  return (
    <div className="flex justify-center items-center h-full w-full">
      Account Setting
    </div>
  );
};

export default AccountSetting;
