import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
const ReportModal = ({ reportChat, setReportChat }) => {
  const { t } = useTranslation();
  const { id, post, users } = reportChat;

  const handleReport = () => {
    const url = `${process.env.REACT_APP_ENDPOINT}/report/add`;
    const seller = users[0];
    const buyer = users[1];
    const body = { chat_id: id, post_id: post, seller, buyer };
    const config = {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    };

    axios.post(url, body, config).then((data) => {
      if (
        data.status === 200 &&
        data.data.message === "Chat is already Reported"
      ) {
        toast.info(data.data.message, {
          theme: "colored",
        });
        setReportChat(null);
      } else if (data.status === 200) {
        toast.success(`Chat is Reported Successfully`, {
          theme: "colored",
        });
        setReportChat(null);
      } else {
        toast.error(`Can't Delete this Chat`, { theme: "colored" });
      }
    });
  };
  return (
    <div>
      <input type="checkbox" id="report-chat-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box dark:bg-secondary">
          <h3 className="font-bold text-lg text-black dark:text-white">{`${t(
            "Are you sure You Want to Report this Chat?"
          )}`}</h3>
          <div className="modal-action">
            <button
              onClick={handleReport}
              className="btn btn-sm btn-outline btn-error"
            >
              {t("Confirm")}
            </button>
            <label
              onClick={() => setReportChat(null)}
              htmlFor="report-chat-modal"
              className="btn btn-sm"
            >
              {t("Cancel")}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
