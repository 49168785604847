import React, { useState } from "react";
import axios from "axios";
import { AiOutlinePause } from "react-icons/ai";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
// import StatusModal from "../Modals/StatusModal";
import Status from "../Status/Status";
import { useTranslation } from "react-i18next";

const HomeVipCircle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("user-id");

  const url = `${process.env.REACT_APP_ENDPOINT}/follower/v2/following/${userId}`;
  const [userIdOfStatus, setUserIdOfStatus] = useState(null);
  const [deletedStatus, setDeletedStatus] = useState(false);

  const { data: vipCircle } = useQuery(
    [`vipCircle`, deletedStatus],
    () =>
      axios.get(url).then((data) => {
        return data.data;
      }),
    { enabled: userId ? true : false }
  );

  return (
    <div className="space-y-4 xs:space-y-7 text-[#444444] dark:text-white">
      <div className="flex items-center gap-2 lg:gap-4 font-[inter] text-xl">
        <AiOutlinePause className="rotate-90 w-6 h-6" />
        <p className="text-sm lg:text-xl font-semibold">
          {t("All")} {t("VIP Circle")}
        </p>
        <span
          className="text-[#CCD1DC]
 dark:text-[#BABABA] text-sm lg:text-xl"
        >
          |
        </span>
        <Link
          to="/vip-circle"
          className="flex items-center gap-1 lg:gap-3 text-xs lg:text-base dark:text-[#BABABA]"
        >
          {t("See All")}
          <HiOutlineArrowRight />
        </Link>
      </div>
      {userId && vipCircle?.length > 0 ? (
        <>
          {/* Mobile View */}
          <div className={`xs:hidden flex flex-wrap gap-2 min-h-[130px] p-2`}>
            {vipCircle?.slice(0, 4)?.map((vip, index) => (
              <div
                key={index}
                className="flex flex-col justify-center space-y-2 cursor-default bg-white dark:bg-secondary w-[90px] h-[110px] rounded-md py-2"
              >
                <div
                  className={`rounded-full mx-auto ${
                    vip?.status &&
                    "ring ring-[#F6AB0E] ring-offset-[white] dark:ring-offset-secondary ring-offset-2"
                  }`}
                >
                  <img
                    src={
                      vip?.image ||
                      "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                    }
                    alt={"vip"}
                    onClick={() =>
                      vip?.status
                        ? navigate(`/status`, { state: vip?._id })
                        : navigate(`/profile/${vip._id}`)
                    }
                    className="h-[54px] w-[54px] xs:h-[70px] xs:w-[70px] object-cover rounded-full cursor-pointer"
                  />
                </div>
                <div className="flex flex-col text-center font-[poppins]">
                  <p
                    onClick={() => navigate(`/profile/${vip._id}`)}
                    className="text-[8px] cursor-pointer"
                  >
                    {vip?.name?.split(" ")[0]}
                  </p>
                  <span className="font-[inter] text-[6px] text-[#9D9D9D]">
                    {t("Member")} {moment(vip?.createdAt).format("YYYY")}
                  </span>
                </div>
              </div>
            ))}
          </div>
          {/* Larger View */}
          <div
            className={`hidden xs:flex flex-wrap gap-6 sm:bg-white dark:bg-secondary sm:shadow-md rounded-[20px] min-h-[200px] p-3 xl:px-9 xl:py-0 ${
              vipCircle?.length > 7 && "justify-evenly"
            }`}
          >
            {vipCircle?.slice(0, 8)?.map((vip, index) => (
              <div
                key={index}
                className="flex flex-col justify-center space-y-2 cursor-default"
              >
                <div
                  className={`rounded-full mx-auto ${
                    vip?.status &&
                    "ring ring-[#F6AB0E] ring-offset-[white] dark:ring-offset-secondary ring-offset-2"
                  }`}
                >
                  <img
                    src={
                      vip?.image ||
                      "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                    }
                    alt={"vip"}
                    onClick={() =>
                      vip?.status
                        ? // ? setUserIdOfStatus(vip?._id)
                          navigate(`/status`, { state: vip?._id })
                        : navigate(`/profile/${vip._id}`)
                    }
                    className="h-[70px] w-[70px] lg:h-[100px] lg:w-[100px] object-cover rounded-full cursor-pointer"
                  />
                </div>
                <div className="text-center font-[poppins]">
                  <p
                    onClick={() => navigate(`/profile/${vip._id}`)}
                    className="text-xs lg:text-sm cursor-pointer"
                  >
                    {vip?.name?.split(" ")[0]}
                  </p>
                  <span className="font-[inter] text-[10px] lg:text-[12px] text-[#9D9D9D]">
                    {t("Member")} {moment(vip?.createdAt).format("YYYY")}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="min-h-[160px] xs:min-h-[200px] bg-white dark:bg-secondary shadow-md rounded-[20px] p-5 flex justify-center items-center">
          <p className="text-base xs:text-lg text-center">
            {t("You are not following anyone right now")}
          </p>
        </div>
      )}
      {userIdOfStatus && (
        <Status
          userIdOfStatus={userIdOfStatus}
          setUserIdOfStatus={setUserIdOfStatus}
          setDeletedStatus={setDeletedStatus}
        />
      )}
    </div>
  );
};

export default HomeVipCircle;
