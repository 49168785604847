import { createContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "./i18n";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Home from "./Pages/Home/Home";
import Login from "./Pages/CustomerLogin/Login";
import Navbar from "./Pages/Shared/Navbar";
import Signup from "./Pages/CustomerLogin/Signup";
import VipCircle from "./Pages/VIPCircle/VipCircle";
import AllCategories from "./Pages/AllCategories/AllCategories";
import CategoryItems from "./Pages/Items/CategoryItems";
import SingleItemDetails from "./Pages/Items/SingleItemDetails";
import SelectCategory from "./Pages/Items/SellItems/SelectCategory";
import AddItem from "./Pages/Items/SellItems/AddItem";
import { ToastContainer } from "react-toastify";
import EditItem from "./Pages/Items/EditItem/EditItem";
import AllItems from "./Pages/Items/AllItems";
import AddProfileDetails from "./Pages/Profile/AddProfileDetails";
import SubCategories from "./Pages/AllCategories/SubCategories";
import SelectSubcategory from "./Pages/Items/SellItems/SelectSubcategory";
import SubcategoryItems from "./Pages/Items/SubcategoryItems";
import Inbox from "./Pages/Inbox/Inbox";
import FavouriteItems from "./Pages/Items/FavouriteItems";
import ProfileDetails from "./Pages/Profile/ProfileDetails";
import AddStatus from "./Pages/Status/AddStatus";
import Status from "./Pages/Status/Status";
import ManageSubscription from "./Pages/Subscription/ManageSubscription";
import OrderDetails from "./Pages/Orders/OrderDetails";
import Settings from "./Pages/Settings/Settings";
import HelpAndFeedback from "./Pages/Help&Feedback/HelpAndFeedback";
import LocationModal from "./Pages/Modals/LocationModal";
import Footer from "./Pages/Footer/Footer";
import AboutUs from "./Pages/Footer/AboutUs";
import ContactUs from "./Pages/Footer/ContactUs";
import PrivacyPolicy from "./Pages/Footer/PrivacyPolicy";
import CookiePolicy from "./Pages/Footer/CookiePolicy";
import Terms from "./Pages/Footer/Terms";
import ReactGA from "react-ga4";
import OrderList from "./Pages/Orders/OrderList";

export const AppContext = createContext("");
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

function App() {
  const pathname = useLocation().pathname;
  const userId = localStorage.getItem("user-id");
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const [searchQuery, setSearchQuery] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [lat, setLat] = useState(48.7758459);
  const [lng, setLng] = useState(9.1829321);
  const [location, setLocation] = useState(0);
  const coordinates = JSON.parse(localStorage.getItem("coordinates"));
  const [showLocationModal, setShowLocationModal] = useState(false);

  useEffect(() => {
    if (openMenu) {
      setOpenMenu(false);
    }
  }, [pathname, openMenu]);

  useEffect(() => {
    if (!localStorage.getItem("coordinates")) {
      localStorage.setItem(
        "coordinates",
        JSON.stringify({ lat: 48.7758459, lng: 9.1829321 })
      );
    }
  });
  if (
    window.location.reload &&
    window.location.pathname === "/" &&
    !localStorage.getItem("coordinates")
  ) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        handleLocationLocalStorage(lat, lng);
      });
    }
  }

  const handleLocationLocalStorage = (lat, lng) => {
    setLat(lat);
    setLng(lng);
    if (!coordinates || coordinates?.lat !== lat || coordinates?.lng !== lng) {
      localStorage.setItem("coordinates", JSON.stringify({ lat, lng }));
    }
  };
  useEffect(() => {
    const latValue = lat || coordinates?.lat;
    const lngValue = lng || coordinates?.lng;
    const url = `${process.env.REACT_APP_ENDPOINT}/location/get-place/${latValue}/${lngValue}`;
    axios.get(url).then((data) => {
      if (data?.data !== undefined) {
        setLocation(data?.data);
        sessionStorage.setItem("location", JSON.stringify(data?.data));
      }
    });
  }, [coordinates?.lat, coordinates?.lng, lat, lng]);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  };

  useEffect(() => {
    // setTheme(localStorage.getItem("theme"));
    document.body.className = theme;
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "de");
    }
  }, [theme]);

  const appContext = {
    toggleTheme,
    openMenu,
    setOpenMenu,
    searchQuery,
    setSearchQuery,
    lat,
    setLat,
    lng,
    setLng,
    location,
    showLocationModal,
    setShowLocationModal,
  };

  return (
    <AppContext.Provider value={appContext}>
      <div
        onClick={() => {
          setSearchQuery("");
        }}
      >
        <Navbar />

        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
            {!userId && <Route path="/login" element={<Login />} />}
            {!userId && <Route path="/signup" element={<Signup />} />}
            <Route path="/vip-circle" element={<VipCircle />} />
            <Route path="/add-details" element={<AddProfileDetails />} />
            <Route path="/profile/:id" element={<ProfileDetails />} />
            <Route path="/status" element={<Status />} />
            <Route path="/items/:route" element={<AllItems />} />

            <Route path="/cat/select" element={<SelectCategory />} />
            <Route
              path="/cat/:category/sub/select"
              element={<SelectSubcategory />}
            />
            <Route path="/cat/all" element={<AllCategories />} />
            <Route path="/cat/:categoryname" element={<CategoryItems />} />
            <Route path="/cat/:category/sub" element={<SubCategories />} />
            <Route
              path="/cat/:category/sub/:subcategory"
              element={<SubcategoryItems />}
            />

            <Route path="/favourites" element={<FavouriteItems />} />
            <Route path="/item/:itemId" element={<SingleItemDetails />} />
            <Route path="/add-item" element={<AddItem />} />
            <Route path="/add-status" element={<AddStatus />} />

            <Route path="/edit-item/:id" element={<EditItem />} />
            <Route path="/inbox/:chatId" element={<Inbox />} />
            <Route
              path="/manage-subscription"
              element={<ManageSubscription />}
            />
            <Route path="/orders/:orderId" element={<OrderDetails />} />
            <Route
              path="/settings"
              element={<Settings toggleTheme={toggleTheme} />}
            ></Route>
            <Route path="/help" element={<HelpAndFeedback />} />
            <Route path="/orders" element={<OrderList />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/cookie" element={<CookiePolicy />} />
          </Routes>
          <Footer />

          <ToastContainer />
        </div>
        <LocationModal
          showModal={showLocationModal}
          setShowModal={setShowLocationModal}
          handleLocationLocalStorage={handleLocationLocalStorage}
        />
      </div>
    </AppContext.Provider>
  );
}

export default App;
