import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineDelete } from "react-icons/ai";
import { BiSend } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineReport } from "react-icons/md";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DeleteChatModal from "../Modals/DeleteChatModal";
import ReportModal from "../Modals/ReportModal";

const ChatBox = ({ chatId, openedChat, chatListRefetch }) => {
  const { t } = useTranslation();
  const userId = localStorage.getItem("user-id");
  const navigate = useNavigate();
  const [allMessages, setAllMessages] = useState(null);
  const [deleteChat, setDeleteChat] = useState(null);
  const [reportChat, setReportChat] = useState(null);
  const chatBoxRef = useRef(null);
  const messageURL = `${process.env.REACT_APP_ENDPOINT}/chats/${chatId}/messages`;
  // const getMessagestUrl = `${process.env.REACT_APP_ENDPOINT}/chats/${chatId}/messages`;
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  const HandleMessageSend = async (data) => {
    let sender;
    let receiver;
    if (openedChat?.users?.[0]?._id === userId) {
      sender = openedChat?.users?.[0]?._id;
      receiver = openedChat?.users?.[1]?._id;
    } else {
      sender = openedChat?.users?.[1]?._id;
      receiver = openedChat?.users?.[0]?._id;
    }
    const body = {
      message: data?.message,
      sender,
      receiver,
    };

    await axios.post(messageURL, body).then((data) => {
      chatListRefetch();
      setAllMessages(data.data?.messages);
    });

    reset();
  };

  const { data, refetch: allMessageRefetch } = useQuery(
    [`allMessages`, messageURL],
    () =>
      axios.get(messageURL).then((data) => {
        setAllMessages(data.data);
        return data?.data;
      })
  );
  // Function to format date as per Facebook Messenger style
  const formatChatDate = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const givenDateISO = moment.utc(date).startOf("day").toISOString();
    const todayISO = moment.utc(today).startOf("day").toISOString();
    const yesterdayISO = moment.utc(yesterday).startOf("day").toISOString();

    if (givenDateISO === todayISO) {
      return "Today";
    } else if (givenDateISO === yesterdayISO) {
      return "Yesterday";
    } else {
      return moment(givenDateISO).format("ll"); // Display full date if not today or yesterday
    }
  };

  const getStartOfDate = (date) => {
    const convertedDate = moment.utc(date).startOf("day").toISOString();
    return convertedDate;
  };

  // useEffect(() => {
  //   chatBoxRef?.current.scrollIntoView({ behavior: "smooth" });
  // }, [allMessages]);

  return (
    <div className="col-span-8 bg-white dark:bg-[#121212] border dark:border-[#272727] flex flex-col justify-between h-[560px] 2xl:h-[600px] border-l-0">
      {chatId === "undefined" ? (
        <div className="h-full px-[30px] flex items-center justify-center text-sm">
          <p>{t("Chat Box is Empty")}</p>
        </div>
      ) : (
        <>
          <div className="p-3 xs:p-6 flex justify-between items-center border-b dark:border-[#272727]">
            <div className="flex gap-[10px] items-center">
              <img
                onClick={() =>
                  navigate(`/profile/${openedChat?.users?.[1]?._id}`)
                }
                src={
                  (openedChat?.users?.[0]?._id === userId
                    ? openedChat?.users?.[1]?.image
                    : openedChat?.users?.[0]?.image) ||
                  "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
                }
                alt=""
                className="h-5 w-5 xs:h-10 xs:w-10 2xl:h-12 2xl:w-12 object-cover rounded-full cursor-pointer"
              />
              <div className="flex flex-col">
                <p className="text-xs xs:text-sm 2xl:text-base">
                  {openedChat?.users?.[0]?._id === userId
                    ? openedChat?.users?.[1]?.name
                    : openedChat?.users?.[0]?.name}
                </p>
                <span
                  onClick={() => navigate(`/item/${openedChat?.post?._id}`)}
                  className="text-[10px] sm:text-xs text-[#9D9D9D] text-pretty cursor-pointer hover:underline"
                >
                  {openedChat?.post?.name}
                </span>
              </div>
            </div>
            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="btn btn-ghost capitalize">
                <div className="flex items-center justify-center gap-2">
                  <BsThreeDotsVertical className="h-3 w-3 xs:h-6 xs:w-6 text-[#949494]" />
                </div>
              </label>
              <ul
                tabIndex={0}
                className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-white dark:bg-secondary rounded-box w-40"
              >
                <li>
                  <label
                    htmlFor="report-chat-modal"
                    onClick={() => {
                      setReportChat(openedChat);
                    }}
                  >
                    <MdOutlineReport /> {t("Report")}
                  </label>
                </li>
                <li>
                  <label
                    htmlFor="delete-chat-modal"
                    onClick={() => {
                      setDeleteChat(openedChat);
                    }}
                  >
                    <AiOutlineDelete /> {t("Delete")}
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="space-y-3 p-[10px] overflow-y-scroll scrollbar-hide text-sm 2xl:text-base">
            {allMessages &&
              allMessages?.map((messageData, index) => (
                <React.Fragment key={index}>
                  {(!allMessages[index - 1] ||
                    getStartOfDate(messageData.time) !==
                      getStartOfDate(allMessages[index - 1].time)) && (
                    <div className={`text-center text-xs xs:text-sm`}>
                      {formatChatDate(messageData.time)}
                    </div>
                  )}
                  <div
                    className={`flex ${
                      messageData?.sender === userId ||
                      messageData?.sender?._id === userId
                        ? "justify-end"
                        : "justify-start"
                    } `}
                  >
                    <div
                      className={`flex flex-col w-full ${
                        messageData?.sender === userId ||
                        messageData?.sender?._id === userId
                          ? "items-end"
                          : "items-start"
                      } `}
                    >
                      <span className="text-[11px]">
                        {moment(messageData.time).format("h:mm a")}
                      </span>

                      <div
                        className={`w-2/5 items-end px-3 py-2 rounded-xl ${
                          messageData?.sender === userId ||
                          messageData?.sender?._id === userId
                            ? "bg-[#F6F6F6] dark:bg-[#272727] text-black dark:text-white"
                            : "bg-[#272727] dark:bg-white text-white dark:text-black"
                        }`}
                      >
                        <p className="text-[10px] xs:text-sm 2xl:text-base">
                          {messageData?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            <div ref={chatBoxRef} />
          </div>
          <form
            className="p-[10px] dark:border-[#272727] border-t"
            onSubmit={handleSubmit(HandleMessageSend)}
          >
            <div className="bg-[#F3F5F9] dark:bg-[#F3F5F94D] flex gap-2 items-center justify-between rounded-lg px-2 h-[50px]">
              <input
                type="text"
                {...register("message")}
                placeholder={t("Type your message")}
                className="w-full h-full max-w-lg bg-transparent text-black dark:text-[#F6F6F6] placeholder:text-[#95979D] placeholder:dark:text-[#F6F6F6] focus:outline-none pl-2 text-[10px] xs:text-base"
              />
              <button
                type="submit"
                className="bg-black dark:bg-white h-6 w-6 xs:h-[34px] xs:w-[34px] rounded-full flex justify-center items-center"
              >
                <BiSend className="text-white dark:text-black h-4 w-4 xs:h-5 xs:w-5" />
              </button>
            </div>
          </form>
        </>
      )}
      {deleteChat && (
        <DeleteChatModal
          deleteChat={deleteChat}
          setDeleteChat={setDeleteChat}
          allMessageRefetch={allMessageRefetch}
          chatListRefetch={chatListRefetch}
        />
      )}
      {reportChat && (
        <ReportModal reportChat={reportChat} setReportChat={setReportChat} />
      )}
    </div>
  );
};

export default ChatBox;
