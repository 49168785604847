import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";
import { MdEdit, MdOutlineAddPhotoAlternate } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import auth from "../../firebase.init";
import { uploadMultipleImages } from "../../Utils/uploadMultipleImages";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Shared/Loading";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { uploadImage } from "../../Utils/uploadImage";

const AddProfileDetails = () => {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const pathname = useLocation().state;
  const [profileImgFile, setProfileImgFile] = useState(null);
  const [profileImgTempURL, setProfileImgTempURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [existingGovtIDURLs, setExistingGovtIDURLs] = useState([]);
  const [newGovtIDFiles, setNewGovtIDFiles] = useState([]);
  const [newGovtIDTempURLs, setNewGovtIDTempURLs] = useState([]);

  const userId = localStorage.getItem("user-id");
  const profileDetailsUrl = `${process.env.REACT_APP_ENDPOINT}/user/profile/${userId}`;
  const { data: profileDetails, isLoading } = useQuery(
    [`profileDetails`, userId],
    () =>
      axios.get(profileDetailsUrl).then((data) => {
        setExistingGovtIDURLs([...data?.data?.user?.governmentID]);
        setIsSeller(
          data?.data?.user.isSeller ? data?.data?.user?.isSeller : false
        );
        return data.data;
      }),
    { enabled: userId !== null ? true : false }
  );

  useEffect(() => {
    if (!profileImgFile) return;
    const imgTempURL = URL.createObjectURL(profileImgFile);
    setProfileImgTempURL(imgTempURL);
    return () => {
      return URL.revokeObjectURL(imgTempURL);
    };
  }, [profileImgFile]);

  useEffect(() => {
    if (newGovtIDFiles?.length <= 0) return;
    const newGovtIDTempURLs = newGovtIDFiles?.map((file) =>
      URL.createObjectURL(file)
    );

    setNewGovtIDTempURLs([...newGovtIDTempURLs]);
    return () => {
      return URL.revokeObjectURL(newGovtIDTempURLs);
    };
  }, [newGovtIDFiles]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleImages = (selectedImageURL, URLArray, setURLs) => {
    const filteredURLs = URLArray.filter(
      (imageURL) => imageURL !== selectedImageURL
    );
    setURLs([...filteredURLs]);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const profileImageURL = profileImgFile
      ? await uploadImage(profileImgFile)
      : profileDetails?.user?.image;
    profileImgFile && localStorage.setItem("user-image", profileImageURL);

    const newGovtIDURLs = await uploadMultipleImages(newGovtIDFiles);

    const userInfo = {
      name: data?.name || user?.displayName,
      image: profileImageURL,
      age: parseInt(data?.age),
      phone: parseInt(data?.phone),
      isSeller: isSeller,
      address: {
        street1: data.street1,
        street2: data.street2,
        province: data?.province,
        city: data?.city,
      },
      governmentID: newGovtIDURLs
        ? [...existingGovtIDURLs, ...newGovtIDURLs]
        : [...existingGovtIDURLs],
    };
    console.log(userInfo);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    };
    await axios
      .post(
        `${process.env.REACT_APP_ENDPOINT}/user/add-details`,
        userInfo,
        config
      )
      .then((data) => {
        if (data.status === 200) {
          ReactGA.event({
            category: "User",
            action: "Click",
            label: `User Profile details added`,
          });
          toast.success(`${data.data.message}`, {
            theme: "colored",
          });
          if (pathname === "Edit") {
            navigate(-1);
          } else {
            if (data.data.url) {
              window.location.href = data.data.url;
            } else {
              navigate("/");
            }
          }
        }
      })
      .finally(() => setLoading(false));
  };

  if (isLoading || loading) {
    return <Loading />;
  }
  console.log(isSeller);
  return (
    <div className="App min-h-screen max-w-6xl mx-auto">
      <p className="font-[inter] text-[#9D9D9D] dark:text-[#606060] text-sm md:text-xl mb-5 font-semibold">
        {t("Home")} /{" "}
        <span className="text-black dark:text-[#E2E2E2]">
          {t(`${pathname ? pathname : "Save"}`)} {t("Profile")}
        </span>
      </p>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white dark:bg-[#1B1B1B] rounded-[20px] p-6 sm:p-12"
      >
        <div className="mb-6 relative">
          <img
            className="mask mask-circle h-[80px] w-[80px] sm:h-[100px] sm:w-[100px] object-cover"
            src={
              profileImgTempURL
                ? profileImgTempURL
                : profileDetails?.user?.image
                ? profileDetails?.user?.image
                : "https://i.ibb.co/pvmWXsv/male-placeholder-image.jpg"
            }
            alt=" "
          />
          <label className="cursor-pointer text-[#444444] dark:text-white bg-[#E2E6EF] dark:bg-[#2A2A2A] h-4 w-4 sm:h-6 sm:w-6 absolute rounded flex justify-center items-center top-[60px] left-[60px] sm:top-20 sm:left-20">
            <MdEdit className="h-3 w-3 sm:h-4 sm:w-4" />
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                setProfileImgFile(e.target.files[0]);
              }}
            />
          </label>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Name")} *
              </span>
            </label>
            <input
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder={
                profileDetails?.user?.name
                  ? profileDetails?.user?.name
                  : user?.displayName || `${t("Your")} ${t("Name")}`
              }
              defaultValue={
                profileDetails?.user?.name
                  ? profileDetails?.user?.name
                  : user?.displayName
              }
              {...register("name", {})}
            />
            <label className="label">
              {errors.name?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.name.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Age")} *
              </span>
            </label>
            <input
              type="number"
              min={0}
              onKeyDown={(evt) =>
                (evt.key === "e" ||
                  evt.key === "E" ||
                  evt.key === "+" ||
                  evt.key === "-" ||
                  evt.key === ".") &&
                evt.preventDefault()
              }
              placeholder={
                profileDetails?.user?.age || `${t("Your")} ${t("Age")}`
              }
              defaultValue={profileDetails?.user?.age}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("age", {
                required: {
                  value: true,
                  message: "Age is required",
                },
              })}
            />
            <label className="label">
              {errors.age?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.age.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Phone Number")} *
              </span>
            </label>
            <input
              type="number"
              min={0}
              onKeyDown={(evt) =>
                (evt.key === "e" ||
                  evt.key === "E" ||
                  evt.key === "-" ||
                  evt.key === ".") &&
                evt.preventDefault()
              }
              placeholder={`${t("Your")} ${t("Phone Number")}`}
              defaultValue={profileDetails?.user?.phone}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("phone", {
                required: {
                  value: true,
                  message: "Phone Number is required",
                },
              })}
            />
            <label className="label">
              {errors.phone?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.phone.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Email")} *
              </span>
            </label>
            <input
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              placeholder={user?.email || `${t("Your")} ${t("Email")}`}
              readOnly
            />
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Street")} 1 *
              </span>
            </label>
            <input
              type="text"
              placeholder={`${t("Street")} 1`}
              defaultValue={profileDetails?.user?.address?.street1}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("street1", {
                required: {
                  value: true,
                  message: "Street 1 is required",
                },
              })}
            />
            <label className="label">
              {errors.street1?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.street1.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Street")} 2 *
              </span>
            </label>
            <input
              type="text"
              placeholder={`${t("Street")} 2`}
              defaultValue={profileDetails?.user?.address?.street2}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("street2", {
                required: {
                  value: true,
                  message: "Street 2 is required",
                },
              })}
            />
            <label className="label">
              {errors.street2?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.street2.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("Address")} *
              </span>
            </label>
            <input
              type="text"
              placeholder={t("Address")}
              defaultValue={profileDetails?.user?.address?.province}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("province", {
                required: {
                  value: true,
                  message: "Address is required",
                },
              })}
            />
            <label className="label">
              {errors.address?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.address.message}
                </span>
              )}
            </label>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-black dark:text-[#E2E2E2] font-semibold">
                {t("City")} *
              </span>
            </label>
            <input
              type="text"
              placeholder={t("City")}
              defaultValue={profileDetails?.user?.address?.city}
              className="input input-bordered bg-[#F6F6F6] dark:bg-[#2E2D2D] text-black dark:text-white border-0 rounded-none focus:outline-none"
              {...register("city", {
                required: {
                  value: true,
                  message: "City is required",
                },
              })}
            />
            <label className="label">
              {errors.city?.type === "required" && (
                <span className="label-text-alt text-red-600 text-sm">
                  {errors.city.message}
                </span>
              )}
            </label>
          </div>
        </div>
        <div className="mt-4">
          <label className="label">
            <span className="label-text dark:text-white font-semibold">
              {t("Upload Government ID")}
            </span>
          </label>
          <div className="flex flex-wrap gap-3">
            {existingGovtIDURLs &&
              existingGovtIDURLs?.map((img, index) => (
                <div key={index} className="relative">
                  <img
                    src={img}
                    alt=" "
                    className="w-[100px] h-[75px] rounded-[10px] object-cover"
                  />
                  <RxCrossCircled
                    onClick={() =>
                      handleImages(
                        img,
                        existingGovtIDURLs,
                        setExistingGovtIDURLs
                      )
                    }
                    className="absolute -top-2 -right-2 cursor-pointer text-red-600 font-bold w-6 h-6"
                  />
                </div>
              ))}
            {newGovtIDTempURLs &&
              newGovtIDTempURLs?.map((tempURL, index) => (
                <div key={index} className="relative">
                  <img
                    key={index}
                    src={tempURL}
                    alt=" "
                    className="w-[100px] h-[75px] rounded-[10px] object-cover"
                  />
                  <RxCrossCircled
                    onClick={() =>
                      handleImages(
                        tempURL,
                        newGovtIDTempURLs,
                        setNewGovtIDTempURLs
                      )
                    }
                    className="absolute -top-2 -right-2 cursor-pointer text-red-600 font-bold w-6 h-6"
                  />
                </div>
              ))}

            {existingGovtIDURLs?.length + newGovtIDTempURLs.length < 5 && (
              <label className="cursor-pointer bg-[#F6F6F6] dark:bg-[#A6A6A6] w-[100px] h-[75px] rounded-[10px] flex flex-col justify-center items-center gap-1">
                <MdOutlineAddPhotoAlternate className="text-black h-7 w-7" />
                <p className="font-[roboto] text-xs text-black">
                  {t("Add Photo")}
                </p>
                <input
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setNewGovtIDFiles([...newGovtIDFiles, ...e.target.files]);
                  }}
                  required
                />
              </label>
            )}
          </div>
        </div>
        <div className={`form-control mt-4 ${pathname === "Edit" && "hidden"}`}>
          <label className="cursor-pointer label justify-start gap-3">
            <input
              onClick={() => setIsSeller(!isSeller)}
              type="checkbox"
              checked={isSeller ? true : false}
              // defaultValue={profileDetails?.user?.isSeller || isSeller}
              className={`checkbox w-4 h-4 rounded-sm border  ${
                isSeller ? "bg-white" : "bg-transparent border-gray-400"
              }`}
            />
            <span
              className={`${
                isSeller ? "opacity-100" : "opacity-50"
              } label-text text-sm text-black dark:text-white`}
            >
              {t("Create Account as Seller")}
            </span>
          </label>
        </div>
        <hr className="my-8" />
        <div className="grid grid-cols-2 gap-3">
          <input
            type="button"
            onClick={() => navigate(-1)}
            className="btn w-full bg-transparent border-black dark:border-white text-black dark:text-white hover:bg-transparent normal-case"
            value={t("Cancel")}
          />
          <input
            type="submit"
            className="btn w-full bg-black dark:bg-white text-white dark:text-black normal-case"
            value={t("Save")}
          />
        </div>
      </form>
    </div>
  );
};

export default AddProfileDetails;
